<template>
  <v-app>
    <v-main class="bg-cannava">
      <v-container fluid fill-height>
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <v-img
              src="../assets/img/Logo_Cannava_Solo_800px_White.png"
              contain
            ></v-img>
            <br />
            <v-card width="100%" class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Iniciar sesión</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    prepend-icon="mdi-account"
                    name="username"
                    label="Usuario"
                    type="text"
                    color="primary"
                    v-model="username"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Contraseña"
                    type="password"
                    color="primary"
                    v-model="password"
                    v-on:keyup.enter="makeLogin"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <p
                  v-if="error_message"
                  class="red--text caption font-weight-medium"
                >
                  * El usuario y/o contraseña son incorrectos.
                </p>
                <v-spacer></v-spacer>
                <v-btn dark color="primary" @click="makeLogin">Ingresar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="snackbar" color="red accent-2" tile>
        Ha ocurrido un error desconocido, intente más tarde.
      </v-snackbar>
      <!-- Overlay indicando loading -->
      <v-overlay :value="overlayLoading">
        <v-progress-circular indeterminate size="35"></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>
<script>
import { login } from "../services/LoginServices";

export default {
  name: "LoginView",
  data() {
    return {
      username: "",
      password: "",
      error_message: false,
      snackbar: false,
      overlayLoading: false,
    };
  },
  methods: {
    makeLogin() {
      this.overlayLoading = true;
      login(this.username, this.password)
        .then((response) => {
          window.localStorage.setItem("usuario", JSON.stringify(response));
          this.$router.push("/");
        })
        .catch((e) => {
          if (e.response && e.response.status == 400) {
            this.error_message = true;
          } else {
            this.snackbar = true;
          }
        })
        .finally(() => (this.overlayLoading = false));
    },
  },
  created() {
    const usuario = JSON.parse(window.localStorage.getItem("usuario"));
    window.localStorage.setItem("paginaEncuestas", 1);
    if (usuario) {
      this.$router.push("/");
    }
  },
};
</script>
