<template>
  <div class="mx-1 mt-1 mb-5">
    <v-col cols="12" md="8" lg="7" xl="6" class="pa-0 pa-md-3">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="opcion in opciones" :key="opcion.model">
              <td>
                <p class="my-0">
                  {{ opcion.label }}
                </p>
              </td>
              <td>
                <div class="d-flex align-center">
                  <v-radio-group
                    v-model="formData[opcion.model]"
                    row
                    class="my-0"
                    hide-details="auto"
                    :rules="[
                      ...(opcion.allowNull ? [] : [rules.notNull]),
                      ...(opcion.mustBeFalse ? [rules.requireFalse] : []),
                    ]"
                    :error-messages="dataErrors[opcion.model]"
                    @change="dataErrors[opcion.model] = null"
                  >
                    <v-radio
                      :value="true"
                      label="Si"
                      class="me-1 me-sm-4"
                      :disabled="disabled"
                    ></v-radio>
                    <v-radio
                      :value="false"
                      label="No"
                      class="me-0 me-sm-4"
                      :disabled="disabled"
                    ></v-radio>
                  </v-radio-group>
                  <v-btn
                    icon
                    color="green"
                    v-if="opcion.allowNull && formData[opcion.model] !== null"
                    @click="formData[opcion.model] = null"
                    :disabled="disabled"
                  >
                    <v-icon>mdi-cached</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p class="my-0">Exclusión del estudio de investigación</p>
              </td>
              <td>
                <v-radio-group
                  v-model="exclusion"
                  row
                  class="my-0"
                  hide-details="auto"
                  :rules="[rules.notNull]"
                  @change="borrarExclusion"
                >
                  <v-radio
                    :value="true"
                    label="Si"
                    class="me-1 me-sm-4"
                    :disabled="disabled"
                  ></v-radio>
                  <v-radio
                    :value="false"
                    label="No"
                    class="me-1 me-sm-4"
                    :disabled="disabled"
                  ></v-radio>
                </v-radio-group>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12" md="8" lg="6" class="pa-0 px-md-3" v-if="exclusion">
      <v-col cols="12" sm="6" md="8" lg="6" class="px-4 pt-1 pt-sm-0 text-end">
        <v-autocomplete
          v-model="formData.causa_exclusion"
          label="Causa de exclusión*"
          :items="opciones_exclusion"
          item-text="label"
          item-value="value"
          :rules="[rules.required]"
          outlined
          dense
          hide-details="auto"
          :error-messages="dataErrors.causa_exclusion"
          @change="dataErrors.causa_exclusion = null"
          :disabled="disabled"
        ></v-autocomplete>
      </v-col>
    </v-col>
    <v-col
      cols="12"
      md="8"
      lg="6"
      class="pa-0 px-md-3"
      v-if="formData.causa_exclusion === 1"
    >
      <v-col cols="12" sm="6" md="8" lg="6" class="px-4 pt-1 pt-sm-0 text-end">
        <v-autocomplete
          v-model="formData.causa_muerte"
          label="Causa de muerte*"
          :items="opciones_muerte"
          item-text="label"
          item-value="value"
          :rules="[rules.notNull]"
          outlined
          dense
          hide-details="auto"
          :error-messages="dataErrors.causa_muerte"
          @change="dataErrors.causa_muerte = null"
          :disabled="disabled"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="8" lg="6" class="px-4 pt-1 pt-sm-0 text-end">
        <v-text-field
          v-model="formData.fecha_muerte"
          label="Fecha de muerte*"
          persistent-hint
          :rules="[rules.required]"
          type="date"
          :max="fechaActual"
          outlined
          dense
          hide-details="auto"
          :error-messages="dataErrors.fecha_muerte"
          @input="dataErrors.fecha_muerte = null"
          :disabled="disabled"
        ></v-text-field>
      </v-col>
    </v-col>
    <v-subheader class="mt-5 mt-sm-0">
      *Ítem 1: si no hizo un laboratorio, dejar en blanco: Los demás ítems,
      completar siempre
    </v-subheader>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "FormularioSeguimientoIntervenciones",
  props: {
    data: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    cuestionarioFinal: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      fechaActual: moment().format("YYYY-MM-DD"),
      rules: {
        required: (v) => !!v || "Campo obligatorio",
        notNull: (v) => v !== null || "Campo obligatorio",
        requireFalse: (v) => !v || 'Debe estar marcado "No"',
        requireTrue: (v) => v || 'Debe estar marcado "Si"',
      },
      exclusion: null,
      opciones: [
        {
          label: "*Eventos adversos en laboratorio (hepatograma, otro)",
          model: "eventos_laboratorio",
          allowNull: !this.cuestionarioFinal,
        },
        {
          label: "Eventos adversos graves (internación o riesgo de muerte)",
          model: "eventos_graves",
        },
        {
          label: "Receta de cannabis medicinal",
          model: "receta",
          mustBeFalse: this.cuestionarioFinal,
        },
      ],
      opciones_exclusion: [
        { value: 1, label: "Muerte" },
        { value: 2, label: "Abandono (decisión del paciente)" },
        { value: 3, label: "Decisión médica" },
        { value: 4, label: "Evento Adverso" },
        { value: 5, label: "Perdida de seguimiento" },
        { value: 6, label: "Embarazo" },
        { value: 98, label: "Desconocida" },
        { value: 99, label: "Otra" },
      ],
      opciones_muerte: [
        { value: 0, label: "Muerte no relacionada" },
        { value: 1, label: "Muerte relacionada al CM" },
      ],
    };
  },
  methods: {
    borrarExclusion() {
      if (!this.exclusion) {
        this.formData.causa_exclusion = null;
        this.formData.causa_muerte = null;
        this.formData.fecha_muerte = null;
      }
    },
  },
  created() {
    if (this.formData && this.disabled){
      this.exclusion = this.formData.causa_exclusion !== null;
    }      
  },
  computed: {
    formData: {
      get() {
        return this.data || {};
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    dataErrors: {
      get() {
        return this.errors || {};
      },
      set(value) {
        this.$emit("update:errors", value);
      },
    },
  },
};
</script>