<template>
  <div class="ma-0 mb-5">
    <v-col cols="12" md="8" lg="7" class="pa-0 pa-md-3">
      <div v-for="criterio in criteriosExclusion" :key="criterio.model">
        <div class="d-flex align-center justify-space-between">
          <p class="my-0">{{ criterio.label }}</p>
          <v-checkbox
            v-model="formData[criterio.model]"
            label="NO"
            class="mt-0 my-0"
            hide-details="auto"
            :true-value="false"
            :false-value="true"
            @change="validarCriteriosExclusion"
          ></v-checkbox>
          <!-- <v-radio-group
            v-model="formData[criterio.model]"
            row
            class="my-0"
            hide-details="auto"
            @change="validarCriteriosExclusion"
          >
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group> -->
        </div>
        <v-divider class="mt-1"></v-divider>
      </div>
    </v-col>
  </div>
</template>

<script>
const initialData = {
  cardiopatia_descomp: true,
  hipertension_descomp: true,
  antec_psicosis: true,
  insuficiencia_renal: true,
  insuficiencia_hepatica: true,
  en_otra_investigacion: true,
  afeccion_confusa: true,
  embarazo_lactancia: true,
  consumo_72hs: true,
};

export default {
  name: "FormularioCriteriosExclusion",
  props: {
    data: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    excludeBy: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
  },
  data() {
    return {
      criteriosExclusion: [
        {
          label:
            "Cardiopatía descompensada (arritmia, infarto, angor, insuficiencia cardíaca)",
          model: "cardiopatia_descomp",
        },
        {
          label: "Hipertensión arterial descompensada",
          model: "hipertension_descomp",
        },
        {
          label:
            "Antecedentes personales y/o familiares de psicosis y/o abuso de sustancias",
          model: "antec_psicosis",
        },
        {
          label: "Insuficiencia renal (eGFR ≤30 mL/min/1.73m2)",
          model: "insuficiencia_renal",
        },
        {
          label: "Insuficiencia hepática (Child B o C)",
          model: "insuficiencia_hepatica",
        },
        {
          label: "Participación en otra investigación (en los últimos 3 meses)",
          model: "en_otra_investigacion",
        },
        {
          label: "Afección que confunda la evaluación del dolor neuropático",
          model: "afeccion_confusa",
        },
        { label: "Embarazo o lactancia", model: "embarazo_lactancia" },
        {
          label:
            "Consumo de cannabis, gabapentioides y duloxetina (en las últimas 72hs)",
          model: "consumo_72hs",
        },
      ],
    };
  },
  methods: {
    validarCriteriosExclusion() {
      let isExcluded = false;
      for (const key in this.data) {
        if (this.data[key] !== false) {
          isExcluded = true;
          break;
        }
      }
      this.$emit("update:excludeBy", isExcluded ? "criteriosExclusion" : null);
    },
  },
  computed: {
    formData: {
      get() {
        return this.data || initialData;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
  },
  created() {
    this.validarCriteriosExclusion();
  },
};
</script>