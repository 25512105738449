<template>
  <div class="mx-0 mt-1 mb-7 mb-md-0">
    <p class="my-0 subtitle-2">
      Cuantas horas estima haber dormido durante la noche, en promedio, en la
      última semana:
    </p>
    <v-col cols="12" md="5" lg="4" class="pa-0 pa-md-3">
      <v-text-field
        v-model="formData.horas_suenio"
        type="number"
        placeholder="Cantidad de horas"
        :rules="[rules.required, rules.enteroPositivo]"
        outlined
        dense
        hide-details="auto"
        :error-messages="dataErrors.horas_suenio"
        @input="dataErrors.horas_suenio = null"
        :disabled="disabled"
      />
    </v-col>
    <div class="text-subtitle-2 mb-2 mt-5">
      Nota somnolencia durante sus actividades del día:
    </div>
    <v-radio-group
      v-model="formData.somnolencia"
      class="my-0 pb-3 pb-md-3"
      hide-details="auto"
      :rules="[rules.notNull]"
    >
      <v-radio label="Si" :value="true" :disabled="disabled"></v-radio>
      <v-radio label="No" :value="false" :disabled="disabled"></v-radio>
    </v-radio-group>
    <p class="mb-0 mt-2 subtitle-2">
      Describa su calidad de sueño en la última semana, con un puntaje de 0 a
      10, donde 0 es la peor calidad de sueño y 10 la mejor calidad de sueño:
    </p>
    <v-col cols="12" md="5" lg="4" class="pa-0 pa-md-3">
      <v-text-field
        v-model="formData.calidad_suenio"
        type="number"
        placeholder="calidad del sueño"
        :rules="[rules.required, rules.enteroPositivo, rules.menorIgual10]"
        outlined
        dense
        hide-details="auto"
        :error-messages="dataErrors.calidad_suenio"
        @input="dataErrors.calidad_suenio = null"
        :disabled="disabled"
      />
    </v-col>
  </div>
</template>

<script>
export default {
  name: "FormularioCalidadSuenio",
  props: {
    data: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      rules: {
        required: (v) => !!v || "Campo obligatorio",
        notNull: (v) => v !== null || "Campo obligatorio",
        enteroPositivo: (v) =>
          (Number.isInteger(+v) && +v >= 0) ||
          "Debe ser un valor entero mayor o igual que cero y menor o igual a 10.",
        menorIgual10: (v) =>
          +v <= 10 || "Debe ser un valor entero menor o igual a 10.",
      },
    };
  },
  computed: {
    formData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    dataErrors: {
      get() {
        return this.errors || {};
      },
      set(value) {
        this.$emit("update:errors", value);
      },
    },
  },
};
</script>