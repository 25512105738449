<template>
  <v-row class="ma-0 mb-5">
    <v-col cols="12" md="6" lg="4" class="pa-0 pa-md-3">
      <v-text-field
        v-model="formData.telefono"
        label="Telefono*"
        type="number"
        hint="(Cod. area sin el 0) + (número de teléfono sin el 15)"
        persistent-hint
        :rules="[rules.required, rules.telefono]"
        outlined
        dense
        hide-details="auto"
        :error-messages="dataErrors.telefono"
        @input="dataErrors.telefono = null"
      />
    </v-col>
    <v-col cols="12" md="6" lg="4" class="pa-0 pa-md-3">
      <v-text-field
        v-model="formData.dosis"
        label="Dosis en las ultimas 24hs *"
        type="number"
        hint="Dosis de cannabis medicinal en las últimas 24hs (gotas/día)"
        persistent-hint
        :rules="[rules.required, rules.enteroPositivo]"
        outlined
        dense
        hide-details="auto"
        :error-messages="dataErrors.dosis"
        @input="dataErrors.dosis = null"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FormularioSeguimiento",
  props: {
    data: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },
  data() {
    return {
      rules: {
        required: (v) => !!v || "Campo obligatorio",
        telefono: (v) =>
          /^[1-9]{2,4}[2-9]\d{6}$/.test(v) ||
          "No es un numero de telefono válido.",
        enteroPositivo: (v) =>
          (Number.isInteger(+v) && +v >= 0) ||
          "Debe ser un valor entero mayor o igual que cero.",
      },
    };
  },
  computed: {
    formData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    dataErrors: {
      get() {
        return this.errors || {};
      },
      set(value) {
        this.$emit("update:errors", value);
      },
    },
  },
};
</script>