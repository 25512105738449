<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-container fluid class="pa-0 mb-2" v-if="pidPaciente">
      <v-btn class="mx-2" dark color="orange darken-4" @click="$router.back()">
        <v-icon dark> mdi-arrow-left </v-icon>
        Volver
      </v-btn>
    </v-container>
    <tabla-component
      :estaCargando="estaCargando"
      :headers="headersEncuestasCorregir"
      :listaItems="listadoCorrecciones"
      tituloTabla="Listado de encuestas a corregir"
      :accionBoton="iniciarEncuesta"
    >
      <!-- Otros slots -->
      <template v-slot:[`item.fecha_apertura`]="{ item }">
        {{ item.fecha_apertura ? fechaFormateada(item.fecha_apertura) : "-" }}
      </template>

      <template v-slot:[`item.paciente`]="{ item }">
        <v-btn
          rounded
          depressed
          color="success"
          class="white--text mx-1"
          @click="mostrarDialogPaciente(item.paciente)"
        >
          {{item.paciente.pid}}
        </v-btn>
      </template>

      <template v-slot:[`item.visita`]="{ item }">
        {{ item.encuesta.visita }}
      </template>

      <template v-slot:[`item.dia_seguimiento`]="{ item }">
        {{ item.encuesta.dia_seguimiento }}
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ fechaFormateada(item.encuesta.created_at) }}
      </template>
      
      <template v-slot:[`item.fecha`]="{ item }">
        {{ item.encuesta.fecha ? fechaFormateada(item.encuesta.fecha) : "-" }}
      </template>

      <template v-slot:[`item.nrs`]="{ item }">
        <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'nrs'?'blue-grey':'red'"
          class="white--text mx-1"
        >
          {{ item.encuesta.nrs || "-" }}
        </v-btn>        
      </template>

      <template v-slot:[`item.dosis`]="{ item }">
      <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'dosis'?'blue-grey':'red'"
          class="white--text mx-1"
        >
          {{ item.encuesta.dosis || "-" }}
        </v-btn>         
      </template>
      <template v-slot:[`item.presenta_ea`]="{ item }">
        {{
          item.encuesta.reacciones_adversas
            ? item.encuesta.reacciones_adversas.existen_sintomas
              ? "SI"
              : "NO"
            : "-"
        }}
      </template>
      <template v-slot:[`item.bpi`]="{ item }">
        <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'bpi'?'blue-grey':'red'"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item.encuesta, 'bpi')"
          :disabled="!item.encuesta.bpi"
        >
          BPI
        </v-btn>
      </template>
      <template v-slot:[`item.hads`]="{ item }">
        <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'hads'?'blue-grey':'red'"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item.encuesta, 'hads')"
          :disabled="!item.encuesta.hads"
        >
          HADS
        </v-btn>
      </template>
      <template v-slot:[`item.eq5d`]="{ item }">
        <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'eq5d'?'blue-grey':'red'"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item.encuesta, 'eq5d')"
          :disabled="!item.encuesta.eq5d"
        >
          EQ5D
        </v-btn>
      </template>
      <template v-slot:[`item.calidad_suenio`]="{ item }">
        <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'calidad_suenio'?'blue-grey':'red'"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item.encuesta, 'calidad_suenio')"
          :disabled="!item.encuesta.calidad_suenio"
        >
          Calidad del sueño
        </v-btn>
      </template>
      <template v-slot:[`item.reacciones_adversas`]="{ item }">
        <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'eventos_adversos'?'blue-grey':'red'"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item.encuesta, 'reacciones_adversas')"
          :disabled="
            !item.encuesta.reacciones_adversas ||
            !item.encuesta.reacciones_adversas.existen_sintomas
          "
        >
          EA
        </v-btn>
      </template>

      <template v-slot:[`item.plan_analgesico`]="{ item }">
        <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'plan_analgesico'?'blue-grey':'red'"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item.encuesta, 'plan_analgesico')"
          :disabled="!item.encuesta.plan_analgesico"
        >
          Plan Analgésico
        </v-btn>
      </template>

      <template v-slot:[`item.intervenciones_medicas`]="{ item }">
        <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'intervenciones_medicas'?'blue-grey':'red'"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item.encuesta, 'intervenciones_medicas')"
          :disabled="!item.encuesta.intervenciones_medicas || item.encuesta.visita == 1"
        >
          Intervenciones Médicas
        </v-btn>
      </template>

      <template v-slot:[`item.dn4`]="{ item }">
        <v-btn
          rounded
          depressed
          :color="item.subcuestionario !== 'dn4'?'blue-grey':'red'"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item.encuesta, 'dn4')"
          :disabled="!item.encuesta.dn4"
        >
          DN4
        </v-btn>
      </template>
      
      <template v-slot:[`item.acciones`]="{ item }">
        <v-btn
          rounded
          depressed
          color="orange darken-4"
          class="white--text mx-1"
          v-if="rol === 'CORR'"
          @click="mostrarCorrecciones(item)"
        >
          <v-icon left dark> mdi-pen </v-icon>
          Correcciones
        </v-btn>
      </template>
    </tabla-component>

    
    <!-- Tabla encuestas atrasadas -->
    <tabla-component
      :estaCargando="estaCargandoAtrasadas"
      :headers="headersEncuestasAtrasadas"
      :listaItems="listadoEncuestasAtrasadas"
      tituloTabla="Listado de encuestas atrasadas"
    >
      <!-- Otros slots -->
      <template v-slot:[`item.pid`]="{ item }">
        {{ item.paciente.pid }}
      </template>

      <template v-slot:[`item.nombre`]="{ item }">
        {{ item.paciente.nombres }} {{ item.paciente.apellidos }}
      </template>

      <template v-slot:[`item.visita`]="{ item }">
        {{ item.ultima_visita.visita }} 
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ fechaFormateada(item.ultima_visita.created_at) }}
      </template>

      

      <template v-slot:[`item.previos`]="{ item }">

        <v-btn
          rounded
          depressed
          :color="item.reenvios.length>0 ?'red':'green'"
          class="white--text mx-1"
          @click="mostrarDialogReenviosPrevios(item)"    
        >
          {{ item.reenvios.length }}
        </v-btn>
        <!-- <template v-if="item.reenvios_previos.length>0">
          <span class="red--text"> {{ item.reenvios_previos.length }} </span>
        </template>
        <template v-else>
          <span class="green--text"> 0 </span>
        </template> -->
      </template>
       
      <template v-slot:[`item.acciones`]="{ item }">
        <v-btn
          rounded
          depressed
          color="orange darken-4"
          class="white--text mx-1"
          v-if="rol === 'CORR'"
          @click="reenviarCuestionario(item)"
        >
          <v-icon left dark > mdi-send</v-icon>
          Reenviar
        </v-btn>

        <v-btn
          rounded
          depressed
          color="green darken-4"
          class="white--text mx-1"
          v-if="rol === 'CORR'"
          @click="cargarCuestionario(item)"
        >
          <v-icon left dark > mdi-send</v-icon>
          Carga manual
        </v-btn>
      </template>
    </tabla-component>


    <!-- Dialog cuestionarios -->
    <v-dialog v-model="dialogCuestionario" persistent scrollable>
      <v-card>
        <v-toolbar dark color="blue-grey">
          <v-toolbar-title>{{ tituloDialogCuestionario }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cerrarDialogCuestionario">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-1 px-md-6" v-if="encuestaSeleccionada">
          <FormularioBPI
            :data="encuestaSeleccionada.bpi"
            v-if="dialogSeleccionado === 'bpi'"
          />
          <FormularioEQ5D
            :data="encuestaSeleccionada.eq5d"
            v-if="dialogSeleccionado === 'eq5d'"
          />
          <FormularioCalidadSuenio
            :data="encuestaSeleccionada.calidad_suenio"
            v-if="dialogSeleccionado === 'calidad_suenio'"
          />
          <FormularioHADS
            :data="encuestaSeleccionada.hads"
            v-if="dialogSeleccionado === 'hads'"
          />
          <FormularioEventosAdversosReadonly
            :data="encuestaSeleccionada.reacciones_adversas"
            v-if="dialogSeleccionado === 'reacciones_adversas'"
            />
          <FormularioPlanAnalgesico
            :data="encuestaSeleccionada.plan_analgesico"
            v-if="dialogSeleccionado === 'plan_analgesico'"
          />
          <FormularioSeguimientoIntervenciones
            :data="encuestaSeleccionada.intervenciones_medicas"
            v-if="dialogSeleccionado === 'intervenciones_medicas'"
          />
          <FormularioDN4
            :data="encuestaSeleccionada.dn4"
            v-if="dialogSeleccionado === 'dn4'"
          />
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- Dialogo datos del paciente -->
    <v-dialog
      v-model="dialogPaciente"
      persistent
      scrollable
      max-width="400"
      v-if="pacienteSeleccionado"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>Datos del Paciente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cierrePaciente">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="px-1 px-md-6 pb-0">
          <div class="text text-left pa-1">
            <div class="font-weight-bold pe-3 d-inline">Nombre:</div>
            <div class="d-inline"> {{ pacienteSeleccionado.nombres }} {{ pacienteSeleccionado.apellidos}} </div>
          </div>

          <div class="text text-left pa-1">
            <div class="font-weight-bold pe-3 d-inline">Teléfono:</div>
            <div class="d-inline"> {{ pacienteSeleccionado.telefono }} </div>
          </div>

          <div class="text text-left pa-1">
            <div class="font-weight-bold pe-3 d-inline">Edad Basal:</div>
            <div class="d-inline"> {{ pacienteSeleccionado.edad_basal }} </div>
          </div>

          <div class="text text-left pa-1">
            <div class="font-weight-bold pe-3 d-inline">Departamento donde vive:</div>
            <div class="d-inline">{{ pacienteSeleccionado.departamento }}</div>
          </div>

          <div class="text text-left pa-1">
            <div class="font-weight-bold pe-3 d-inline">Diagnóstico:</div>
            <div class="d-inline">{{ pacienteSeleccionado.diagnostico }}</div>
          </div>

          <div class="text text-left pa-1">
            <div class="font-weight-bold pe-3 d-inline">Fecha de inicio de tratamiento:</div>
            <div class="d-inline">  {{ fechaFormateada(pacienteSeleccionado.fecha_inicio) }} </div>
          </div>


          <div class="text text-left pa-1">
            <div class="font-weight-bold pe-3 d-inline">Responsable:</div>
            <div class="d-inline"> {{ pacienteSeleccionado.responsable }} </div>
          </div>
        </v-card-text>
        <v-card-actions class="px-1 px-md-6">
          <v-btn dark color="primary" @click="cierrePaciente">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <!-- Dialogo correccion de encuesta -->
    <v-dialog
      v-model="dialogCorrecion"
      persistent
      scrollable
      max-width="700"
      v-if="correccionSeleccionada"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>Corrección de encuesta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cierreCorreccion">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="px-1 px-md-6 pb-0">
          <v-form ref="formReapertura">
            <div class="text text-left pa-2">
              <div class="font-weight-bold pe-3 d-inline">Fecha de apertura:</div>
              <div class="d-inline"> {{ fechaFormateada(correccionSeleccionada.fecha_apertura) }} </div>
            </div>

            <div class="text text-left pa-2">
              <div class="font-weight-bold pe-3 d-inline">Comentario:</div>
              <div class="d-inline"> {{ textoComentario }}</div>
            </div> 

            <hr class="ma-2" />

            <div class="text text-left pa-2">
              <!-- <div class="font-weight-bold pe-3 d-inline">Cuestionario a corregir:</div> -->
              <div class="font-weight-bold pe-3  d-inline"> {{ nombreCuestionario }} </div>
            </div>
            
            <div class="text text-left pa-2">
              <!-- <div class="font-weight-bold pe-3 d-inline">Campo a corregir :</div> -->
              <div class="d-inline"> {{ textoCampo }} </div>
            </div>
            <div class="text text-left pa-2" v-if="correccionSeleccionada.subcampo">
              <div class="font-weight-bold pe-3 d-inline">Subcampo a corregir:</div>
              <div class="d-inline"> {{ textoSubcampo }}</div>
            </div>
              
              

              <template v-if="tipoPregunta.widget === 'text-field'">
                <v-text-field
                  v-model="nuevoValorCorreccion"
                  :type="tipoPregunta.type"
                  label="Nuevo valor"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="tipoPregunta.rules"
                >
                </v-text-field>
              </template>

              <template v-if="tipoPregunta.widget === 'radio-group'">
                <v-radio-group
                  v-model="nuevoValorCorreccion"
                  class="my-0 pb-3 pb-md-3"
                  hide-details="auto"
                  :rules="tipoPregunta.rules"
                >
                  <v-radio v-for="opcion in tipoPregunta.opciones" :key="opcion.value" 
                    :label="opcion.label" 
                    :value="opcion.value" 
                  ></v-radio>
                </v-radio-group>
              </template>

              <template v-if="tipoPregunta.widget === 'slider0a100'">
                <v-slider
                  v-model="nuevoValorCorreccion"
                  :min="0"
                  :max="100"
                  thumb-label="always"
                  thumb-size="25"
                  step="1"
                ></v-slider>  
              </template>

              <template v-if="tipoPregunta.widget === 'slider0a10'">
                <v-slider
                  v-model="nuevoValorCorreccion"
                  :min="0"
                  :max="10"
                  thumb-label="always"
                  thumb-size="25"
                  step="1"
                ></v-slider>
              </template>

              <template v-if="tipoPregunta.widget === 'autocomplete'">
                <v-autocomplete
                  v-model="nuevoValorCorreccion"
                  :items="tipoPregunta.opciones"
                  item-text="label"
                  item-value="value"
                  placeholder="Seleccione uno..."
                  outlined
                  dense
                  hide-details="auto"
                  clearable
                ></v-autocomplete>
              </template>

              <template v-if="tipoPregunta.widget === 'checkbox'">
                <v-checkbox
                  v-model="nuevoValorCorreccion"
                  :label="textoCampo"
                ></v-checkbox>
              </template>

              <template v-if="tipoPregunta.widget === 'textarea'">
                <v-textarea
                  v-model="nuevoValorCorreccion"
                  outlined
                  :label="textoCampo"
                  rows="2"
                  hide-details="auto"
                ></v-textarea>
              </template>
          </v-form>
        </v-card-text>

        <v-card-actions class="px-1 px-md-6">
        <v-spacer></v-spacer>
          <v-btn dark color="red" @click="cierreCorreccion">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="submitCorreccion">
            Guardar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- Dialogo reapertura de encuesta -->
    <v-dialog
      v-model="dialogReapertura"
      persistent
      scrollable
      max-width="700"
      v-if="encuestaSeleccionada"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>Reapertura de encuesta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cierreReapertura">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-1 px-md-6 pb-0">
          <v-form ref="formReapertura">
            <v-select 
              label="Cuestionario a corregir"
              v-model="correccion.subcuestionario"
              :items="opcionesReapertura"
              :item-text="item => item.subcuestionario"
              :item-value="item => item.model"
              :rules="rules.required"
            ></v-select>

            <v-select 
              label="Campo a corregir"
              v-model="correccion.campo"
              :items="camposCuestionario"
              :item-text="item => item.pregunta"
              :item-value="item => item.model"
              :rules="rules.required"
            ></v-select>

            <v-select 
              v-if="subcamposPregunta.length > 0"
              label="Subcampo a corregir"
              v-model="correccion.subcampo"
              :items="subcamposPregunta"
              :item-text="item => item.label"
              :item-value="item => item.model"
              :rules="rules.required"
            ></v-select>

            <v-textarea
              outlined
              name="comentario"
              label="Comentario *"
              v-model="correccion.comentario"
              :rules="rules.required.concat(rules.noBlanco)"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-1 px-md-6">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="submitReapertura">
            Guardar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo Carga manual de encuesta atrasada -->
    <v-dialog v-model="dialogCargaManual"
      persistent
      scrollable
      fullscreen>
      <v-card>
        <div class="loading-iframe" v-if="estaCargandoIframe"> 
          <v-card color="secondary" dark width="90%">
            <v-card-text>
              <div class="my-2">ABRIENDO EL CUESTIONARIO </div>
              <div class="my-2">Por favor espere...</div>
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </div>

        <iframe id="iframeCargaManual" width="100%" height="800" :src="urlCargaManual" @load="loadIframe"></iframe>
        <v-card-actions class="px-1 px-md-6">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="cierreCargaManual">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>      
    </v-dialog>

    <!-- Dialogo fechas de reenvios previos -->
    <v-dialog v-model="dialogReenviosPrevios"
      persistent
      scrollable
      max-width="400"
      v-if="encuestaAtrasadaSeleccionada">
      <v-card>      
        
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>Reenvíos de encuestas </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cierreReenviosPrevios">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="px-1 px-md-6 pb-0 pt-2">
          <template v-if="encuestaAtrasadaSeleccionada.reenvios.length === 0">
            <div class="text text-left pa-1">
              <div class="font-weight-bold pe-3 d-inline">No se ha reenviado esta encuesta previamente</div>
            </div>
          </template>
          <template v-else>
            <div class="text text-left pa-1"
              v-for="(reenvio, index) in encuestaAtrasadaSeleccionada.reenvios"
              :key="index"
            >
              <div class="font-weight-bold pe-3 d-inline">Fecha:</div>
              <div class="d-inline"> {{ separarDiaHora(reenvio.created_at) }} </div>
            </div>          
          </template>     
        </v-card-text>

        <v-card-actions class="px-1 px-md-6">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="cierreReenviosPrevios">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      
    </v-dialog>
  </v-container>
</template>

<script>
import TablaComponent from "@/components/Tabla.vue";
import FormularioBPI from "@/components/FormularioBPI.vue";
import FormularioHADS from "@/components/FormularioHADS.vue";
import FormularioEQ5D from "@/components/FormularioEQ5D.vue";
import FormularioCalidadSuenio from "@/components/FormularioCalidadSuenio.vue";
//import SubcuestionarioReacciones from "@/components/SubcuestionarioReacciones.vue";
import FormularioEventosAdversosReadonly from "@/components/FormularioEventosAdversosReadonly.vue";
import FormularioPlanAnalgesico from "@/components/FormularioPlanAnalgesico.vue";
import FormularioSeguimientoIntervenciones from "@/components/FormularioSeguimientoIntervenciones.vue";
import FormularioDN4 from "@/components/FormularioDN4.vue";

//import headers from "@/utils/headers";
import { headersEncuestasAtrasadas } from "@/utils/headers";
import { headersEncuestasCorregir } from "@/utils/headers";
import {opcionesReapertura} from "@/components/modelsCorrecciones";
import { formatearFecha } from "@/utils/formats";
import {
  getEncuestasAtrasadas,
  getEncuestasPaciente,
} from "@/services/EncuestasService";
import {
  crearCorreccion,
  cerrarCorreccion,
  getEncuestasCorrecciones,
} from "@/services/CorreccionesService";

import {
  getUrlUltimoCuestionario,
  getReenvioCuestionario
} from "@/services/PacientesService";
export default {
  name: "CorrectorView",
  components: {
    TablaComponent,
    FormularioBPI,
    FormularioHADS,
    FormularioEQ5D,
    FormularioCalidadSuenio,
    //SubcuestionarioReacciones,
    FormularioEventosAdversosReadonly,
    FormularioPlanAnalgesico,
    FormularioSeguimientoIntervenciones,
    FormularioDN4
  },
  props: {
    usuario: {
      type: Object,
      required: true,
    },
    setSnackbar: {
      type: Function,
      required: true,
    },
    setLoaderLineal: {
      type: Function,
      required: true,
    },
    manejarErrorAxios: {
      type: Function,
      required: true,
    },
    pidPaciente: String,
    setItemSeleccionado: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      
      listadoEncuestas: [],
      listadoCorrecciones: [],
      listadoEncuestasAtrasadas: [],
      estaCargando: true,
      estaCargandoAtrasadas: true,
      paciente: null,
      encuestaSeleccionada: null,
      dialogSeleccionado: null,
      dialogCuestionario: false,
      dialogReapertura: false,
      datosReapertura: {},
      rules: {
        required: [(v) => !!v || "Campo obligatorio"],
        noBlanco: [
          (v) => (!!v && v.trim() !== "") || "No puede estar en blanco.",
        ],
      },
      correccion:{
        cuestionario: null,
        subcuestionario: null,
        campo: null,
        subcampo: null,
        comentario: null
      },
      dialogCorrecion: false,
      textoCampo: null,
      textoSubcampo: null,
      textoComentario: null,
      nombreCuestionario: null,
      /* tipo: null, */
      nuevoValorCorreccion: null,
      dialogPaciente: false,
      pacienteSeleccionado: null,
      tipoPregunta: null  ,
      correccionSeleccionada: null,
      dialogCargaManual: false,
      urlCargaManual: null,
      dialogReenviosPrevios: false,
      encuestaAtrasadaSeleccionada: null,
      estaCargandoIframe: false
    };
  },
  watch:{
    "correccion.subcuestionario": function () {
      this.correccion.campo = null;
      this.correccion.subcampo = null;
      this.correccion.comentario = null;
    },
    "correccion.campo": function () {
      this.correccion.subcampo = null;
      this.correccion.comentario = null;
    }
  },
  computed: {
    opcionesReapertura: function () {
      return opcionesReapertura;
    },
    camposCuestionario: function () {
      if (this.correccion.subcuestionario) {
        return opcionesReapertura.filter( item => item.model === this.correccion.subcuestionario)[0].opciones;
      }
      return [];
    },
    subcamposPregunta: function () {
      if (this.correccion.campo) {
        const campoCompleto = this.camposCuestionario.filter( item => item.model === this.correccion.campo)[0];
        // check if options key is present in the object
        if ("options" in campoCompleto) {
          return campoCompleto.options;
        }
        return [];
      }
      return [];
      
    },
    /* headers: function () {
      if (this.usuario && ["EST", "CORR"].includes(this.usuario["rol"])) {
        let headersDef = [];
        switch (this.usuario["rol"]) {
          case "EST":
            headersDef = headers.encuestas;
            break;
          case "CORR":
            // headersDef = headers.encuestasCorregir; 
            headersDef = headers.encuestas;
            break;
        }
        return [
          ...headersDef,
          {
            value: "acciones",
            align: "center",
            text: "",
            sortable: false,
          },
        ];
      }
      return headers.encuestas;
    }, */
    headersEncuestasAtrasadas: function(){
      return headersEncuestasAtrasadas
    },
    headersEncuestasCorregir: function(){
      return headersEncuestasCorregir
    },
    rol: function () {
      if (this.usuario) return this.usuario["rol"];
      return "";
    },
    tituloDialogCuestionario: function () {
      switch (this.dialogSeleccionado) {
        case "bpi":
          return "Cuestionario BPI";
        case "hads":
          return "Cuestionario HADS";
        case "eq5d":
          return "Cuestionario EQ5D";
        case "calidad_suenio":
          return "Cuestionario de calidad del sueño";
        case "reacciones_adversas":
          return "Cuestionario de eventos adversos";
        case "plan_analgesico":
          return "Plan analgésico";
        case "intervenciones_medicas":
          return "Interveciones médicas";
        case "dn4":
          return "DN4";
        default:
          break;
      }
      return "";
    },
  },
  methods:{     
    separarDiaHora(fecha){
      let fechaHora = fecha.toString().split("T");
      return formatearFecha(fechaHora[0]) + " a las " + fechaHora[1].split(".")[0] + "hs";
    },    
    reenviarCuestionario(item){
      console.log("Reenviando cuestionario a:", item);
      this.setLoaderLineal(true);
      getReenvioCuestionario(item.paciente.pid)
        .then(() => {
          this.setSnackbar({
            activo: true,
            color: "success",
            texto: "Se reenvió correctamente el cuestionario.",
          });
          this.listadoEncuestasAtrasadas = [];
          this.obtenerEncuestasAtrasadas();
        })
        .catch((e) => {
          this.manejarErrorAxios(e);
          console.log(e);
        })
        .finally(() => this.setLoaderLineal(false));        
    },
    cargarCuestionario(item){
      this.estaCargandoIframe = true;
      console.log("Cargando cuestionario a:", item);
      this.setLoaderLineal(true);
      getUrlUltimoCuestionario(item.paciente.pid)
        .then((response) => {
          console.log(response);
          //window.open(response.url, "_blank");
          //remove /login from url
          let url = response.url.replace("/login", "");
          this.urlCargaManual = url;
          this.dialogCargaManual = true;
          console.log("URL:", this.urlCargaManual);
          /* this.setItemSeleccionado(response);
          this.$router.push({ name: "CuestionarioView" }); */
        })
        .catch((e) => {
          this.manejarErrorAxios(e);
          console.log(e);
        })
        /* .finally(() => this.setLoaderLineal(false)); */
    },
    loadIframe(){
      console.log("Iframe cargado");
      this.estaCargandoIframe = false;
      this.setLoaderLineal(false);
    },
    cierreCargaManual(){
      this.dialogCargaManual = false;
      this.urlCargaManual = null;
      // recarga
      this.listadoEncuestasAtrasadas = [];
      this.obtenerEncuestasAtrasadas();
    },
    mostrarCorrecciones(encuesta) {
      this.correccionSeleccionada = encuesta;
      const subcuestionario = this.opcionesReapertura.filter( item => item.model === encuesta.subcuestionario)[0];
      this.nombreCuestionario = subcuestionario.subcuestionario.toUpperCase();
      this.textoCampo = subcuestionario.opciones.filter( item => item.model === encuesta.campo)[0].pregunta;
      this.tipoPregunta = subcuestionario.opciones.filter( item => item.model === encuesta.campo)[0].tipo;
      
      if (encuesta.subcampo) {
        this.textoSubcampo = subcuestionario.opciones.filter( item => item.model === encuesta.campo)[0].options.filter( item => item.model === encuesta.subcampo)[0].label;
        this.tipoPregunta = subcuestionario.opciones.filter( item => item.model === encuesta.campo)[0].options.filter( item => item.model === encuesta.subcampo)[0].tipo;
      }
      this.textoComentario = encuesta.comentario;
      this.dialogCorrecion = true;
    },
    submitCorreccion(){
      if (this.$refs["formReapertura"].validate()) {
        this.setLoaderLineal(true);
        let body = {
          new_value : this.nuevoValorCorreccion,
        };
        cerrarCorreccion(body, this.correccionSeleccionada.id)
          .then(() => {
            this.setSnackbar({
              activo: true,
              color: "success",
              texto: "Se registró correctamente la correción.",
            });

            // recargar
            this.listadoCorrecciones = [];
            this.obtenerEncuestasCorrecciones();
            this.cierreCorreccion();
          })
          .catch((e) => {
            this.manejarErrorAxios(e);
            console.log(e);

          })
          .finally(() => this.setLoaderLineal(false));
      }
    },
    cierreCorreccion() {
      this.dialogCorrecion = false;
      this.correccionSeleccionada = null;
      this.nombreCuestionario = null
      this.textoCampo = null;
      this.tipoPregunta = null;
      this.nuevoValorCorreccion = null;
      this.textoSubcampo = null;
    },
    mostrarDialogPaciente(paciente) {
      this.pacienteSeleccionado = paciente;
      this.dialogPaciente = true;
    },
    cierrePaciente() {
      this.dialogPaciente = false;
      this.pacienteSeleccionado = null;
    },
    mostrarDialogReenviosPrevios(reenvios){
      this.encuestaAtrasadaSeleccionada = reenvios;
      this.dialogReenviosPrevios = true;
    },
    cierreReenviosPrevios(){
      this.dialogReenviosPrevios = false;
      this.encuestaAtrasadaSeleccionada = null;
    },
    fechaFormateada(fecha) {
      return formatearFecha(fecha);
    },
    manejarRequestEncuestasAtrasadas(request, funcionLlamada) {
      this.estaCargandoAtrasadas = true;
      request
        .then((datosEncuestas) => {
          this.listadoEncuestasAtrasadas = this.listadoEncuestasAtrasadas.concat(
            //datosEncuestas.results   // version para consulta paginada
            datosEncuestas 
          );
          if (datosEncuestas.next) {
            const nextUrl = new URL(datosEncuestas.next);
            const params = nextUrl.searchParams;
            const limit = params.get("limit");
            const offset = params.get("offset");
            funcionLlamada(limit, offset);
          } else {
            this.estaCargandoAtrasadas = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.manejarErrorAxios(e);
        });
    },
    manejarRequestEncuestasCorrecion(request, funcionLlamada) {
      this.estaCargando = true;
      request
        .then((datosEncuestas) => {
          this.listadoCorrecciones = this.listadoCorrecciones.concat(
            datosEncuestas.results
          );
          if (datosEncuestas.next) {
            const nextUrl = new URL(datosEncuestas.next);
            const params = nextUrl.searchParams;
            const limit = params.get("limit");
            const offset = params.get("offset");
            funcionLlamada(limit, offset);
          } else {
            this.estaCargando = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.manejarErrorAxios(e);
        });
    },
    obtenerEncuestasAtrasadas(limit = 15, offset = 0) {
      this.manejarRequestEncuestasAtrasadas(
        getEncuestasAtrasadas({ limit, offset }),
        this.obtenerEncuestasAtrasadas
      );
    },
    obtenerEncuestasCorrecciones(limit = 15, offset = 0) {
      this.manejarRequestEncuestasCorrecion(
        getEncuestasCorrecciones({ limit, offset }),
        this.obtenerEncuestasCorrecciones
      );
    },
    solicitarEncuestasPaciente() {
      this.estaCargando = true;
      getEncuestasPaciente(this.pidPaciente)
        .then((response) => {
          console.log(response);
          this.listadoEncuestas = response;
          this.estaCargando = false;
        })
        .catch((e) => this.manejarErrorAxios(e));
    },
    cerrarDialogCuestionario() {
      this.encuestaSeleccionada = null;
      this.dialogCuestionario = false;
      this.dialogSeleccionado = null;
    },
    mostrarDialogCuestionario(encuesta, nombreDialog) {
      this.dialogSeleccionado = nombreDialog;
      this.encuestaSeleccionada = encuesta;
      this.dialogCuestionario = true;
    },
    iniciarEncuesta() {
      this.$router.push({ name: "inicioEncuesta" });
    },
    mostrarReapertura(encuesta) {
      this.encuestaSeleccionada = encuesta;
      this.dialogReapertura = true;
    },
    verCorrecciones(encuesta) {
      this.$router.push({
        name: "correccionesEncuesta",
        params: { idEncuesta: encuesta.id.toString() },
      });
    },
    submitReapertura() {
      if (this.$refs["formReapertura"].validate()) {
        this.setLoaderLineal(true);
        this.correccion.cuestionario = this.encuestaSeleccionada.id;
        console.log("Body correccion =", this.correccion)
        crearCorreccion(this.correccion, this.encuestaSeleccionada.id)
          .then(() => {
            this.setSnackbar({
              activo: true,
              color: "success",
              texto: "Se registró la solicitud de correción.",
            });
            this.cierreReapertura();
          })
          .catch((e) => this.manejarErrorAxios(e))
          .finally(() => this.setLoaderLineal(false));
      }
    },
    cierreReapertura() {
      this.dialogReapertura = false;
      this.encuestaSeleccionada = null;
      this.correccion = {
        cuestionario: null,
        subcuestionario: null,
        campo: null,
        subcampo: null,
        comentario: null
      };
    },
  },
  created() {
    if (this.usuario["rol"] === "CORR") {
      console.log("Created::obtenerEncuestasCorrecciones")
      this.obtenerEncuestasAtrasadas();
      this.obtenerEncuestasCorrecciones();
      
      //this.setItemSeleccionado("encuestas");

      /* this.listadoCorrecciones = [
        {
          id: 1,
          subcuestionario: "calidad_suenio",
          campo: "calidad_suenio",
          subcampo: null,
          comentario: "valor previo 5",
          abierta: true,
          fecha_apertura: "2024-07-15",
          fecha_cierre: null,
          cuestionario: 1,
          paciente:{
            pid: "A1001",
            datos_cuestionarios: {
              excluido: false,
              fecha_intervension_medica: "2024-07-15",
              causa_exclusion: null,
              causa_muerte: null,
              fecha_muerte: null,
              ultimo_cuestionario_completo: true,
              estudio_finalizado: false,
              ultima_visita: 2,
              ultimo_dia_seguimiento: 7
            },
            edad_basal: 33,
            responsable: "MEDICO, WITTEN",
            estudio_piloto: 1,
            nombres: "P1",
            apellidos: "AP1",
            dni: 12345678,
            fecha_nacimiento: "1991-01-01",
            genero: "F",
            departamento: "RINCONADA",
            telefono: 100000001,
            diagnostico: "G610",
            incluido: true,
            fecha_inicio: "2024-07-15",
            activo: true,
            created_at: "2024-07-15T13:33:21.749838-03:00",
            updated_at: "2024-07-15T13:36:04.530800-03:00"     
          },
          encuesta:{
              "id": 1,
              "paciente": "A1001",
              "visita": 1,
              "dia_seguimiento": 0,
              "fecha": "2024-07-15",
              "nrs": 8,
              "dosis": null,
              "completa": true,
              "bpi": {
                "id": 1,
                "peor_dolor": 0,
                "dolor_leve": 0,
                "dolor_promedio": 0,
                "dolor_actual": 0,
                "actividad": 0,
                "animo": 0,
                "capacidad_andar": 0,
                "trabajo": 0,
                "relacion_social": 0,
                "suenio": 0,
                "disfrute_vida": 0
              },
              "hads": {
                "id": 1,
                "tenso_nervioso": 0,
                "disfrute": 0,
                "miedo": 0,
                "risas": 0,
                "preocupaciones": 0,
                "alegria": 3,
                "relajacion": 0,
                "lentitud": 3,
                "sensacion_mariposas": 0,
                "perdida_interes": 3,
                "inquieto": 3,
                "optimismo": 0,
                "panico": 3,
                "diversion": 0
              },
              "eq5d": {
                "id": 1,
                "movilidad": 1,
                "cuidado_personal": 1,
                "actividades_cotid": 1,
                "dolor_malestar": 1,
                "ansiedad_depresion": 1,
                "estado_salud": 50
              },
              "calidad_suenio": {
                "id": 1,
                "horas_suenio": 5,
                "somnolencia": true,
                "calidad_suenio": 5
              },
              "reacciones_adversas": null,
              "plan_analgesico": {
                "id": 1,
                "opioides_fuertes": false,
                "opioides_debiles": false,
                "antiinflamatorios": true,
                "paracetamol": false,
                "anticonvulsivantes": false,
                "antiarritmicos": false,
                "antagonistas_nmetildaspartato": false,
                "corticoides": false,
                "otros_medicamentos": "",
                "otras_intervenciones": "",
                "reduccion_dosis": null
              },
              "intervenciones_medicas": {
                "id": 1,
                "receta": true,
                "eventos_laboratorio": null,
                "eventos_graves": null,
                "causa_exclusion": null,
                "causa_muerte": null,
                "fecha_muerte": null,
                "created_at": "2024-07-15T13:33:21.880822-03:00"
              },
              "dn4": {
                "id": 1,
                "caracteristica_dolor": {
                  "id": 1,
                  "quemazon": true,
                  "frio_doloroso": true,
                  "descargas_electricas": true
                },
                "sintoma_zona_dolor": {
                  "id": 1,
                  "hormigueo": true,
                  "pinchazos": true,
                  "entumecimiento": true,
                  "escozor": true
                },
                "signo_zona_dolor": {
                  "id": 1,
                  "hipotesia_tacto": true,
                  "hipotesia_pinchazo": true
                },
                "motivo_dolor": {
                  "id": 1,
                  "roce": true
                }
              }
            }          
    },  
        {
          id: 2,
          subcuestionario: "calidad_suenio",
          campo: "somnolencia",
          subcampo: null,
          comentario: "valor previo si",
          abierta: true,
          fecha_apertura: "2024-07-15",
          fecha_cierre: null,
          cuestionario: 1,
          paciente:{
            pid: "A1001",
            datos_cuestionarios: {
              excluido: false,
              fecha_intervension_medica: "2024-07-15",
              causa_exclusion: null,
              causa_muerte: null,
              fecha_muerte: null,
              ultimo_cuestionario_completo: true,
              estudio_finalizado: false,
              ultima_visita: 2,
              ultimo_dia_seguimiento: 7
            },
            edad_basal: 33,
            responsable: "MEDICO, WITTEN",
            estudio_piloto: 1,
            nombres: "P1",
            apellidos: "AP1",
            dni: 12345678,
            fecha_nacimiento: "1991-01-01",
            genero: "F",
            departamento: "RINCONADA",
            telefono: 100000001,
            diagnostico: "G610",
            incluido: true,
            fecha_inicio: "2024-07-15",
            activo: true,
            created_at: "2024-07-15T13:33:21.749838-03:00",
            updated_at: "2024-07-15T13:36:04.530800-03:00"     
          },
          encuesta:{
              "id": 1,
              "paciente": "A1001",
              "visita": 1,
              "dia_seguimiento": 0,
              "fecha": "2024-07-15",
              "nrs": 8,
              "dosis": null,
              "completa": true,
              "bpi": {
                "id": 1,
                "peor_dolor": 0,
                "dolor_leve": 0,
                "dolor_promedio": 0,
                "dolor_actual": 0,
                "actividad": 0,
                "animo": 0,
                "capacidad_andar": 0,
                "trabajo": 0,
                "relacion_social": 0,
                "suenio": 0,
                "disfrute_vida": 0
              },
              "hads": {
                "id": 1,
                "tenso_nervioso": 0,
                "disfrute": 0,
                "miedo": 0,
                "risas": 0,
                "preocupaciones": 0,
                "alegria": 3,
                "relajacion": 0,
                "lentitud": 3,
                "sensacion_mariposas": 0,
                "perdida_interes": 3,
                "inquieto": 3,
                "optimismo": 0,
                "panico": 3,
                "diversion": 0
              },
              "eq5d": {
                "id": 1,
                "movilidad": 1,
                "cuidado_personal": 1,
                "actividades_cotid": 1,
                "dolor_malestar": 1,
                "ansiedad_depresion": 1,
                "estado_salud": 50
              },
              "calidad_suenio": {
                "id": 1,
                "horas_suenio": 5,
                "somnolencia": true,
                "calidad_suenio": 5
              },
              "reacciones_adversas": null,
              "plan_analgesico": {
                "id": 1,
                "opioides_fuertes": false,
                "opioides_debiles": false,
                "antiinflamatorios": true,
                "paracetamol": false,
                "anticonvulsivantes": false,
                "antiarritmicos": false,
                "antagonistas_nmetildaspartato": false,
                "corticoides": false,
                "otros_medicamentos": "",
                "otras_intervenciones": "",
                "reduccion_dosis": null
              },
              "intervenciones_medicas": {
                "id": 1,
                "receta": true,
                "eventos_laboratorio": null,
                "eventos_graves": null,
                "causa_exclusion": null,
                "causa_muerte": null,
                "fecha_muerte": null,
                "created_at": "2024-07-15T13:33:21.880822-03:00"
              },
              "dn4": {
                "id": 1,
                "caracteristica_dolor": {
                  "id": 1,
                  "quemazon": true,
                  "frio_doloroso": true,
                  "descargas_electricas": true
                },
                "sintoma_zona_dolor": {
                  "id": 1,
                  "hormigueo": true,
                  "pinchazos": true,
                  "entumecimiento": true,
                  "escozor": true
                },
                "signo_zona_dolor": {
                  "id": 1,
                  "hipotesia_tacto": true,
                  "hipotesia_pinchazo": true
                },
                "motivo_dolor": {
                  "id": 1,
                  "roce": true
                }
              }
            }          
    }
      ]; */

      /* this.listadoEncuestasAtrasadas =[
        {
          "paciente": {
            "pid": "A1001",
            "estudio_piloto": 1,
            "nombres": "P1",
            "apellidos": "AP1",
            "dni": 12345678,
            "fecha_nacimiento": "1991-01-01",
            "genero": "F",
            "departamento": "RINCONADA",
            "telefono": 100000001,
            "diagnostico": "G610",
            "incluido": true,
            "fecha_inicio": "2024-07-15",
            "activo": true,
            "created_at": "2024-07-15T13:33:21.749838-03:00",
            "updated_at": "2024-07-18T15:32:55.356034-03:00",
            "responsable": 2
          },
          "ultima_visita": {
            "id": 3,
            "fecha": null,
            "visita": 3,
            "dia_seguimiento": 14,
            "nrs": 5,
            "dosis": 5,
            "completa": false,
            "created_at": "2024-07-18",
            "paciente": "A1001"
          },
          "reenvios": []
        },
        {
          "paciente": {
            "pid": "A1001",
            "estudio_piloto": 1,
            "nombres": "P1",
            "apellidos": "AP1",
            "dni": 12345678,
            "fecha_nacimiento": "1991-01-01",
            "genero": "F",
            "departamento": "RINCONADA",
            "telefono": 100000001,
            "diagnostico": "G610",
            "incluido": true,
            "fecha_inicio": "2024-07-15",
            "activo": true,
            "created_at": "2024-07-15T13:33:21.749838-03:00",
            "updated_at": "2024-07-18T15:32:55.356034-03:00",
            "responsable": 2
          },
          "ultima_visita": {
            "id": 3,
            "fecha": null,
            "visita": 4,
            "dia_seguimiento": 21,
            "nrs": 5,
            "dosis": 5,
            "completa": false,
            "created_at": "2024-07-15",
            "paciente": "A1001"
          },
          "reenvios": [
            {
              "id": 0,
              "created_at": "2024-07-17T14:15:22Z",
              "cuestionario": 0
            },
            {
              "id": 1,
              "created_at": "2024-07-29T14:15:22Z",
              "cuestionario": 0
            }
          ]
        }
      ]; */
      /* this.estaCargandoAtrasadas = false;
      this.estaCargando = false; */
    } else if (this.pidPaciente) {
      this.solicitarEncuestasPaciente();
      this.setItemSeleccionado("pacientes");
    } else {
      console.log('obtenerListadoEncuestas');
      this.obtenerListadoEncuestas();
      this.setItemSeleccionado("encuestas");
    }
  },
};
</script>

<style scoped>
.option-selected {
  border: 2px solid green;
}

.card-table {
  margin-bottom: 20px;
}

.loading-iframe{
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}
</style>
