<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-container fluid class="pa-0 mb-2">
      <v-btn class="mx-2" dark color="orange darken-4" @click="$router.back()">
        <v-icon dark> mdi-arrow-left </v-icon>
        Volver
      </v-btn>
    </v-container>
    <v-overlay absolute :value="inicializando" class="text-center">
      <v-progress-circular
        :size="50"
        color=""
        indeterminate
      ></v-progress-circular>
      <div class="mt-4 text-h6">Extrayendo datos del paciente...</div>
    </v-overlay>
    <v-card class="card ma-0 mb-3" v-if="paciente">
      <v-container fluid>
        <div class="d-flex flex-column flex-md-row align-start align-md-center">
          <v-chip class="ma-2" color="primary font-weight-bold">
            DOLOCANN {{ paciente.estudio_piloto }}
          </v-chip>
          <v-card-title primary-title class="py-0">
            Datos del paciente
          </v-card-title>
        </div>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0 d-flex"
              v-for="dato in datosPaciente"
              :key="dato.label"
            >
              <div class="pe-3 font-weight-bold">{{ dato.label }}:</div>
              <div class="text--secondary font-weight-medium">
                {{ dato.value }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
    <div v-if="paciente">
      <v-container
        v-if="
          !paciente.incluido ||
          paciente.datos_cuestionarios.excluido ||
          paciente.datos_cuestionarios.estudio_finalizado ||
          !paciente.datos_cuestionarios.ultimo_cuestionario_completo
        "
        class="d-flex justify-center"
      >
        <v-alert type="error"> No es posible registrar esta visita </v-alert>
      </v-container>
      <v-stepper v-model="step" vertical v-else>
        <v-stepper-step :complete="step > 1" step="1">
          Datos de seguimiento
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-form v-if="paciente.datos_cuestionarios.ultima_visita < 5" ref="formIntervenciones">
            <formulario-seguimiento-intervenciones
              :data.sync="data.intervenciones_medicas"
              :errors.sync="errors.intervenciones_medicas"
              :disabled="false"
              :cuestionarioFinal="false"
            />
          </v-form> 
          <v-form v-if="paciente.datos_cuestionarios.ultima_visita == 5" ref="formIntervenciones">
            <formulario-seguimiento-intervenciones-ultima
              :data.sync="data.intervenciones_medicas"
              :errors.sync="errors.intervenciones_medicas"
              :disabled="false"
              :cuestionarioFinal="true"
            />
          </v-form>         
          <v-btn v-if="data.intervenciones_medicas.causa_exclusion==null" color="primary" @click="nextStep('formIntervenciones')">
            Continuar
          </v-btn>
          <v-btn v-if="data.intervenciones_medicas.causa_exclusion!=null" color="primary" @click="goToSubmit('formIntervenciones')">
            Guardar datos
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="step > 2" step="2">
          Escala de dolor (NRS)
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-form ref="formNRS">
            <FormularioNRS
              :data.sync="data.nrs"
              :error.sync="errors.nrs"
              :esSeguimiento="true"
            />
          </v-form>
          <v-btn color="primary" @click="nextStep('formNRS')">
            Continuar
          </v-btn>
          <v-btn text @click="step = step - 1"> Atras </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="step > 3" step="3">
          Plan analgésico, en la última semana
          <small
            >marcar con X la medicación y/o intervención que correspondan (puede
            marcar más de 1)</small
          >
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-form ref="formPlanAnalgesico">
            <formulario-plan-analgesico
              :data.sync="data.plan_analgesico"
              :esSeguimiento="true"
              :errors.sync="errors.plan_analgesico"
              :disabled="false"
              :excludeBy.sync="excludeBy"
            />
          </v-form>
          <div
            v-if="excludeBy === 'planAnalgesico'"
            class="my-0 red--text d-flex align-center mb-4"
          >
            <v-icon left dark color="red"> mdi-alert-circle </v-icon>
            Debe indicar al menos una medicación o intervención
          </div>

          <v-btn color="primary" @click="goToIntervenciones()">
            Continuar
          </v-btn>
          <v-btn text @click="step = step - 1"> Atras </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="step > 4" step="4">
          Intervenciones médicas del plan de trabajo
        </v-stepper-step>

        <v-stepper-content step="4">
        <v-form ref="formSeguimiento">
            <formulario-seguimiento 
              :data.sync="data" 
              :errors.sync="errors"              
               />
          </v-form>
          <!-- <v-form ref="formIntervenciones">
            <formulario-seguimiento-intervenciones
              :data.sync="data.intervenciones_medicas"
              :cuestionarioFinal="
                paciente.datos_cuestionarios.ultima_visita == 5
              "
              :errors.sync="errors.intervenciones_medicas"
              :disabled="false"
            />
          </v-form> -->
          <v-btn color="primary" @click="goToSubmit('formSeguimiento')">
            Guardar datos
          </v-btn>
          <v-btn text @click="step = step - 1"> Atras </v-btn>
        </v-stepper-content>
      </v-stepper>
    </div>

    <v-container
      v-if="!inicializando && !paciente"
      class="d-flex justify-center"
    >
      <v-alert type="error">
        El paciente que esta buscando no existe en el sistema.
      </v-alert>
    </v-container>

    <v-dialog v-model="finalDialog" persistent max-width="500">
      <v-card>
        <div class="text-h5 font-weight-medium py-4 px-6">
          ¿Registrar datos de la visita?
        </div>
        <v-card-text>
          <v-alert border="top" colored-border type="error" elevation="2">
            Estos datos no podrán modificarse
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="finalDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="submit"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog loading -->
    <v-dialog v-model="cargando" persistent width="300">
      <v-card color="secondary" dark>
        <v-card-text>
          <div class="my-2">Por favor espere...</div>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { registrarVisita } from "@/services/visitaServices";
import FormularioSeguimiento from "@/components/FormularioSeguimiento.vue";
import FormularioNRS from "@/components/FormularioNRS.vue";
import FormularioPlanAnalgesico from "@/components/FormularioPlanAnalgesico.vue";
import FormularioSeguimientoIntervenciones from "@/components/FormularioSeguimientoIntervenciones.vue";
import FormularioSeguimientoIntervencionesUltima from "@/components/FormularioSeguimientoIntervencionesUltima.vue";
import { getDetallePaciente } from "@/services/PacientesService";
import moment from "moment";

const initialData = {
  paciente: null,
  telefono: null,
  dosis: null,
  nrs: null,
  plan_analgesico: {
    opioides_fuertes: false,
    opioides_debiles: false,
    antiinflamatorios: false,
    paracetamol: false,
    anticonvulsivantes: false,
    antiarritmicos: false,
    antagonistas_nmetildaspartato: false,
    corticoides: false,
    otros_medicamentos: "",
    otras_intervenciones: "",
    reduccion_dosis: false,
  },
  intervenciones_medicas: {
    eventos_laboratorio: null,
    eventos_graves: null,
    causa_exclusion: null,
    causa_muerte: null,
    fecha_muerte: null,
    receta: null,
  },
};

export default {
  name: "RegistroSeguimientoView",
  components: {
    FormularioSeguimiento,
    FormularioNRS,
    FormularioPlanAnalgesico,
    FormularioSeguimientoIntervenciones,
    FormularioSeguimientoIntervencionesUltima
  },
  props: {
    setSnackbar: {
      type: Function,
      required: true,
    },
    manejarErrorAxios: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      paciente: null,
      pacienteId: null,
      data: JSON.parse(JSON.stringify(initialData)),
      errors: {},
      finalDialog: false,
      step: 1,
      inicializando: true,
      cargando: false,
      excludeBy: null,
    };
  },
  computed: {
    fechaNacimiento: function () {
      return moment(this.paciente["fecha_nacimiento"]).format("DD/MM/YYYY");
    },
    fechaInicio: function () {
      return moment(this.paciente["fecha_inicio"]).format("DD/MM/YYYY");
    },
    datosPaciente: function () {
      return [
        { label: "Apellidos", value: this.paciente?.apellidos },
        { label: "Nombres", value: this.paciente?.nombres },
        { label: "D.N.I.", value: this.paciente?.dni },
        {
          label: "Género",
          value: { M: "Masculino", F: "Femenino" }[this.paciente?.genero],
        },
        {
          label: "Fecha de nacimiento",
          value: moment(this.paciente["fecha_nacimiento"]).format("DD/MM/YYYY"),
        },
        {
          label: "Fecha de inicio",
          value: moment(this.paciente["fecha_inicio"]).format("DD/MM/YYYY"),
        },
        { label: "Diagnostico", value: this.paciente?.diagnostico },
        {
          label: "Última visita registrada",
          value: this.paciente?.datos_cuestionarios?.ultima_visita || "-",
        },
      ];
    },
  },
  methods: {
    nextStep(formRef) {
      if (this.$refs[formRef].validate()) {
        this.step += 1;
      }
    },
    goToIntervenciones() {
      //check if any item is not null
      let countFalse = 0;
      let countString = 0;
      const {otras_intervenciones, otros_medicamentos, ...booleanos} = this.data.plan_analgesico;
      for (const key in booleanos) {
        if ((key!= 'reduccion_dosis') && (booleanos[key] === false))  { countFalse += 1; }
      }
      if (otras_intervenciones === '') { countString += 1; }
      if (otros_medicamentos === '') { countString += 1; }
      console.log("data: ", this.data.plan_analgesico)
      console.log("countFalse: ", countFalse, "countString: ", countString)
      if (countFalse == 8 && countString == 2) {
        this.excludeBy = 'planAnalgesico'
      }
      else{
        this.step += 1;
        return;
      }
    },
    goToSubmit(formRef) {
      if (this.$refs[formRef].validate()) {
        this.finalDialog = true;
      }
    },
    submit() {
      this.cargando = true;
      let body = null;
      if (this.data.intervenciones_medicas.causa_exclusion != null) {
        body = { 
          intervenciones_medicas: {
            causa_exclusion: this.data.intervenciones_medicas.causa_exclusion, 
            causa_muerte: this.data.intervenciones_medicas.causa_muerte,
            eventos_graves: this.data.intervenciones_medicas.eventos_graves, 
            eventos_laboratorio: this.data.intervenciones_medicas.eventos_laboratorio,
            fecha_muerte: this.data.intervenciones_medicas.fecha_muerte,
            receta: this.data.intervenciones_medicas.receta 
          },
          paciente: this.data.paciente
          } 
      }
      else{
        body = this.data
      }   
      if (this.paciente.datos_cuestionarios.ultima_visita == 5){
        body.intervenciones_medicas.receta = false;
      }
      console.log(body);
      registrarVisita(body)
        .then(() => {
          this.$router.replace({ name: "seguimientoPaciente" });
          this.setSnackbar({
            activo: true,
            color: "success",
            texto: "La visita se registró correctamente",
          });
          this.data = JSON.parse(JSON.stringify(initialData));
        })
        .catch((e) => {
          if (e.response && e.response.status === 400)
            this.errors = { ...e.response.data };
          else this.manejarErrorAxios(e);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    getDatosPaciente() {
      getDetallePaciente(this.pacienteId)
        .then((response) => {
          this.paciente = response;
          this.data = JSON.parse(JSON.stringify(initialData));
          this.data["paciente"] = response.pid;
          this.data["telefono"] = response.telefono;
        })
        .catch((e) => {
          if (e.response && e.response.status === 404)
            this.error = "Este paciente no esta registrado.";
          else this.manejarErrorAxios(e);
        })
        .finally(() => {
          this.inicializando = false;
        });
    },
  },
  created() {
    this.pacienteId = this.$route.query?.paciente || -1;
    this.getDatosPaciente();
  },
};
</script>