import { render, staticRenderFns } from "./FormularioCalidadSuenio.vue?vue&type=template&id=3d1c2cd5&"
import script from "./FormularioCalidadSuenio.vue?vue&type=script&lang=js&"
export * from "./FormularioCalidadSuenio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VRadio,VRadioGroup,VTextField})
