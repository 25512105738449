<template>
  <div class="mx-3 mt-1 mb-7 mb-md-0">
    <v-col cols="12" lg="10" xl="6" class="pa-0 pa-md-3">
      <div>
        Seleccione la respuesta de cada apartado que mejor
        <b>describa su estado de salud en el dia de HOY.</b>
      </div>
      <div
        v-for="pregunta in preguntas"
        :key="pregunta.model"
        class="my-2 mb-md-6"
      >
        <p class="my-0 subtitle-2">
          {{ pregunta.pregunta }}
        </p>
        <v-radio-group
          v-model="formData[pregunta.model]"
          class="my-0"
          hide-details="auto"
          :rules="[rules.required]"
          :error-messages="dataErrors[pregunta.model]"
          @input="dataErrors[pregunta.model] = null"
        >
          <v-radio
            v-for="opcion in pregunta.opciones"
            :key="opcion.label"
            :label="opcion.label"
            :value="opcion.value"
            :disabled="disabled"
          ></v-radio>
        </v-radio-group>
      </div>
    </v-col>
    <v-container fluid>
      <v-row>
        <v-card-title class="primary--text">
          Escala visual de salud
        </v-card-title>
        <v-card-subtitle>
          Indicar en esta escala en su opinión, lo bueno o lo malo que es su
          estado de salud en el día de HOY. Por favor, desplace la burbuja verde
          hasta el punto del termómetro que en su opinión indique lo bueno o lo
          malo que es su estado de salud en el día de HOY.
        </v-card-subtitle>
      </v-row>
      <v-row class="mx-0">
        <v-subheader class="text-start pl-0">
          0: Peor estado de salud imaginable <br />
          100: Mejor estado de salud imaginable
        </v-subheader>
      </v-row>
      <v-row class="mx-0 pt-5">
        <v-col sm="1" class="px-0 text-end d-none d-sm-block">0</v-col>
        <v-col cols="12" sm="10" class="px-0">
          <v-slider
            v-model="formData.estado_salud"
            min="0"
            max="100"
            thumb-label="always"
            thumb-size="25"
            tick-size="4"
            :readonly="disabled"
          >
          </v-slider>
        </v-col>
        <v-col sm="1" class="px-0 text-start d-none d-sm-block">100</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FormularioEQ5D",
  props: {
    data: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    errors: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      rules: {
        required: (v) => v !== null || "Campo obligatorio",
      },
      preguntas: [
        {
          pregunta: "Movilidad",
          model: "movilidad",
          opciones: [
            { value: 1, label: "No tengo problemas para caminar" },
            { value: 2, label: "Tengo algunos problemas para caminar" },
            { value: 3, label: "Tengo que estar en la cama" },
          ],
        },
        {
          pregunta: "Cuidado personal",
          model: "cuidado_personal",
          opciones: [
            { value: 1, label: "No tengo problemas con el cuidado personal" },
            {
              value: 2,
              label: "Tengo algunos problemas para lavarme o vestirme solo",
            },
            { value: 3, label: "Soy incapaz de lavarme o vestirme solo" },
          ],
        },
        {
          pregunta:
            "Actividades cotidianas (por ejemplo: trabajar, estudiar, hacer las tareas domésticas, actividades familiares o actividades durante el tiempo libre)",
          model: "actividades_cotid",
          opciones: [
            {
              value: 1,
              label:
                "No tengo problemas para realizar mis actividades cotidianas",
            },
            {
              value: 2,
              label:
                "Tengo algunos problemas para realizar mis actividades cotidianas",
            },
            {
              value: 3,
              label: "Soy incapaz de realizar mis actividades cotidianas",
            },
          ],
        },
        {
          pregunta: "Dolor/malestar",
          model: "dolor_malestar",
          opciones: [
            { value: 1, label: "No tengo dolor ni malestar" },
            { value: 2, label: "Tengo moderado dolor o malestar" },
            { value: 3, label: "Tengo mucho dolor o malestar" },
          ],
        },
        {
          pregunta: "Ansiedad/depresión",
          model: "ansiedad_depresion",
          opciones: [
            { value: 1, label: "No estoy ansioso/a ni deprimido/a" },
            { value: 2, label: "Estoy moderadamente ansioso/a o deprimido/a" },
            { value: 3, label: "Estoy muy ansioso/a o deprimido/a" },
          ],
        },
      ],
    };
  },
  computed: {
    formData: {
      get() {
        return this.data || {};
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    dataErrors: {
      get() {
        return this.errors || {};
      },
      set(value) {
        this.$emit("update:errors", value);
      },
    },
  },
};
</script>