<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-container fluid class="pa-0 mb-2">
      <v-btn class="mx-2" dark color="orange darken-4" @click="$router.back()">
        <v-icon dark> mdi-arrow-left </v-icon>
        Volver
      </v-btn>
    </v-container>
    <tabla-component
      tituloTabla="Listado de correcciones"
      :headers="headers"
      :listaItems="listadoCorrecciones"
      :estaCargando="estaCargando"
    >
      <!-- Slots tabla -->
      <template v-slot:[`item.fecha_apertura`]="{ item }">
        {{ fechaFormateada(item.fecha_apertura) }}
      </template>

      <template v-slot:[`item.subcuestionario`]="{ item }">
        <v-chip color="orange darken-3" text-color="white">
          {{ subcuestionarios[item.subcuestionario] }}
        </v-chip>
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-btn
          rounded
          depressed
          color="info"
          class="white--text mx-1"
          @click="verCorreccion(item)"
        >
          Ver / corregir
        </v-btn>
      </template>
    </tabla-component>
    <v-dialog
      v-model="dialog"
      width="1000"
      persistent
      v-if="correccion"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Subcuestionario {{ subcuestionarios[correccion.subcuestionario] }}
        </v-card-title>

        <v-card-text class="pb-0">
          <v-form ref="form">
            <subcuestionario-general
              :fechaEncuesta.sync="encuesta.fecha"
              :medicacionConcomitEncuesta.sync="
                encuesta.medicacion_concomitante
              "
              v-if="correccion.subcuestionario === 'general'"
            />
            <subcuestionario-salud
              :datosSalud.sync="encuesta.salud"
              v-if="correccion.subcuestionario === 'salud'"
            />
            <subcuestionario-qolie
              :datosQolie.sync="encuesta.calidad_vida_adulto"
              v-if="correccion.subcuestionario === 'calidad_vida_adulto'"
            />
            <subcuestionario-cave
              :datosCave.sync="encuesta.calidad_vida_pediatria"
              v-if="correccion.subcuestionario === 'calidad_vida_pediatria'"
            />
            <subcuestionario-reacciones
              :datosReacciones.sync="encuesta.reaccion_adversa"
              v-if="correccion.subcuestionario === 'reaccion_adversa'"
            />
          </v-form>

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="secondary" text @click="cerrarCorreccion"
              >Cerrar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="guardarCorreccion"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TablaComponent from "@/components/Tabla.vue";
import SubcuestionarioSalud from "@/components/SubcuestionarioSalud.vue";
import SubcuestionarioQolie from "@/components/SubcuestionarioQolie.vue";
import SubcuestionarioCave from "@/components/SubcuestionarioCave.vue";
import SubcuestionarioGeneral from "@/components/SubcuestionarioGeneral.vue";
import SubcuestionarioReacciones from "@/components/SubcuestionarioReacciones.vue";

import headers from "@/utils/headers";
import {
  getCorreccionesEncuesta,
  getEncuestaCorreccion,
  cerrarCorreccion,
} from "@/services/CorreccionesService";
import { formatearFecha } from "@/utils/formats";

export default {
  name: "CorreccionesView",
  components: {
    TablaComponent,
    SubcuestionarioGeneral,
    SubcuestionarioSalud,
    SubcuestionarioQolie,
    SubcuestionarioCave,
    SubcuestionarioReacciones,
  },
  props: {
    manejarErrorAxios: {
      type: Function,
      required: true,
    },
    setSnackbar: {
      type: Function,
      required: true,
    },
    setLoaderLineal: {
      type: Function,
      required: true,
    },
    idEncuesta: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: headers.correcciones,
      listadoCorrecciones: [],
      encuesta: null,
      estaCargando: true,
      subcuestionarios: {
        salud: "EuroQol EQ-5D",
        calidad_vida_adulto: "QOLIE-10",
        calidad_vida_pediatria: "CAVE",
        reaccion_adversa: "Reacciones adversas",
        general: "Encuesta general",
      },
      dialog: false,
      correccion: null,
    };
  },
  methods: {
    fechaFormateada(fecha) {
      return formatearFecha(fecha);
    },
    solicitarCorrecciones() {
      this.estaCargando = true;
      getCorreccionesEncuesta(this.idEncuesta)
        .then((listado) => {
          if (listado.length === 0) this.$router.replace({ name: "encuestas" });
          this.listadoCorrecciones = listado;
        })
        .catch((e) => this.manejarErrorAxios(e))
        .finally(() => (this.estaCargando = false));
    },
    solicitarDatosEncuesta() {
      getEncuestaCorreccion(this.idEncuesta)
        .then((encuesta) => (this.encuesta = encuesta))
        .catch((e) => this.manejarErrorAxios(e));
    },
    verCorreccion(correccion) {
      this.dialog = true;
      this.correccion = correccion;
    },
    cerrarCorreccion() {
      this.dialog = false;
      this.correccion = null;
    },
    guardarCorreccion() {
      if (this.$refs["form"].validate()) {
        let data = null;
        const subcuestionario = this.correccion.subcuestionario;
        switch (subcuestionario) {
          case "general":
            data = {
              fecha: this.encuesta.fecha,
              medicacion_concomitante: this.encuesta.medicacion_concomitante,
            };
            break;
          case "salud":
            data = this.encuesta.salud;
            break;
          case "calidad_vida_adulto":
            data = this.encuesta.calidad_vida_adulto;
            break;
          case "calidad_vida_pediatria":
            data = this.encuesta.calidad_vida_pediatria;
            break;
          case "reaccion_adversa":
            data = this.encuesta.reaccion_adversa;
            // Borra los campos de reaccion adversa con valor null
            for (const key in data) {
              if ([null, 0, ""].includes(data[key])) {
                delete data[key];
              }
            }
            break;
        }

        this.setLoaderLineal(true);
        cerrarCorreccion({ [subcuestionario]: data }, this.correccion.id)
          .then(() => {
            this.cerrarCorreccion();
            this.solicitarCorrecciones();
            this.setSnackbar({
              activo: true,
              color: "primary",
              texto: "Corrección cerrada exitosamente."
            })
          })
          .catch((e) => this.manejarErrorAxios(e))
          .finally(() => this.setLoaderLineal(false));
      }
    },
  },
  created() {
    this.solicitarCorrecciones();
    this.solicitarDatosEncuesta();
  },
};
</script>