<template>
  <v-row justify="center">
    <v-dialog v-model="visible" persistent max-width="1200" scrollable>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="cerrarFormulario">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="text-h5">Datos del paciente</span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="submit"> Guardar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formPaciente" class="py-5">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.number="data.dni"
                    label="DNI*"
                    type="number"
                    :rules="rules.required.concat(rules.dni)"
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="errors.dni"
                    @input="delete errors.dni"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="data.nombres"
                    label="Nombres*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="data.apellidos"
                    label="Apellidos*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="data.fecha_nacimiento"
                    label="Fecha de nacimiento*"
                    persistent-hint
                    :rules="rules.required.concat(rules.fecha_nacimiento)"
                    type="date"
                    :max="fechaActual"
                    :min="fechaMinima"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="esEdicion && data.nro_cuestionario > 1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.genero"
                    :items="[
                      { key: 'M', label: 'Masculino' },
                      { key: 'F', label: 'Femenino' },
                      { key: 'Trans M', label: 'Trans Masculino' },
                      { key: 'Trans F', label: 'Trans Femenino' },
                    ]"
                    item-text="label"
                    item-value="key"
                    label="Género*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.departamento"
                    :items="departamentos"
                    item-text="label"
                    item-value="key"
                    label="Departamento donde vive*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="data.telefono"
                    label="Telefono*"
                    type="number"
                    hint="(Cod. area sin el 0) + (número de teléfono sin el 15)"
                    persistent-hint
                    :rules="rules.required.concat(rules.telefono)"
                    outlined
                    dense
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="data.email"
                    label="Correo electrónico"
                    :rules="rules.email"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="data.fecha_inicio"
                    label="Fecha de inicio tratamiento con Cannava CBD10*"
                    persistent-hint
                    :rules="rules.required.concat(rules.fecha_inicio)"
                    type="date"
                    :max="fechaActual"
                    :min="fechaMinimaInicio"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="esEdicion && data.nro_cuestionario > 1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.grupo_diagnostico"
                    :items="gruposDiagnostico"
                    label="Diagnóstico*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.diagnostico"
                    :items="codigosCIE"
                    label="Código Diagnóstico (CIE-10)*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.sindrome_epilepsia"
                    :items="sindromesEpilepsia"
                    item-text="label"
                    item-value="key"
                    label="Síndrome de epilepsia*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.number="data.anio_diagnostico"
                    label="Año de diagnostico*"
                    type="number"
                    :rules="rules.required.concat(rules.anio_diagnostico)"
                    outlined
                    dense
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.sintoma_diagnostico"
                    :items="sintomasDiagnostico"
                    item-text="label"
                    item-value="key"
                    label="Sintoma del diagnóstico*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  v-if="this.data.sintoma_diagnostico === 99"
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="data.nombre_otro_sintoma"
                    label="Descripcion de otros sintomas*"
                    hint="Separe los sintomas por comas"
                    persistent-hint
                    maxlength="100"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.otro_tratamiento"
                    :items="otrosTratamientos"
                    item-text="label"
                    item-value="key"
                    label="Otros tratamientos"
                    outlined
                    dense
                    hide-details="auto"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.centro_atencion"
                    :items="centrosAtencion"
                    item-text="label"
                    item-value="key"
                    label="Centro de atención*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-checkbox
                    v-model="data.fallecido"
                    label="¿El paciente falleció?"
                    dense
                    hide-details="auto"
                  ></v-checkbox>
                </v-col>
                <v-col v-if="this.data.fallecido" cols="12" sm="6">
                  <v-text-field
                    v-model="data.fecha_muerte"
                    label="Fecha de muerte*"
                    :rules="rules.required"
                    type="date"
                    :max="fechaActual"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col v-if="this.data.fallecido" cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.causa_muerte"
                    :items="causasMuerte"
                    item-text="label"
                    item-value="key"
                    label="Causa de muerte*"
                    :rules="rules.causa_muerte"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-checkbox
                    v-model="data.salida"
                    label="¿Salió del registro?"
                    dense
                    hide-details="auto"
                  ></v-checkbox>
                </v-col>
                <v-col v-if="this.data.salida" cols="12" sm="6">
                  <v-text-field
                    v-model="data.fecha_salida"
                    label="Fecha de salida*"
                    :rules="rules.required"
                    type="date"
                    :max="fechaActual"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col v-if="this.data.salida" cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.causa_salida"
                    :items="causasSalida"
                    item-text="label"
                    item-value="key"
                    label="Causa de salida*"
                    :rules="rules.required"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <small>*indica los campos requeridos</small>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog loading -->
    <v-dialog v-model="cargando" persistent width="300">
      <v-card color="secondary" dark>
        <v-card-text>
          <div class="my-2">Por favor espere...</div>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import {
  sintomasDiagnostico,
  sindromesEpilepsia,
  otrosTratamientos,
  departamentos,
  centrosAtencion,
  codigosCIE,
  gruposDiagnostico,
  formulasAplicadas,
  causasMuerte,
  causasSalida,
} from "@/utils/optionsCamposPaciente";
import {
  crearPaciente,
  modificarPacienteId,
} from "@/services/PacientesService";

export default {
  name: "FormPaciente",
  props: {
    showFormPaciente: Boolean,
    esEdicion: {
      type: Boolean,
      default: false,
    },
    dataPaciente: Object,
    setSnackbar: {
      type: Function,
      required: true,
    },
    manejarErrorAxios: {
      type: Function,
      required: true,
    },
    actualizarListaPacientes: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      visible: true,
      cargando: false,
      data: this.dataPaciente || {},
      errors: {},
      rules: {
        required: [(v) => !!v || "Campo obligatorio"],
        causa_muerte: [(v) => v !== null || "Campo obligatorio"],
        dni: [(v) => (1000000 <= v && v <= 100000000) || "DNI no válido"],
        telefono: [
          (v) =>
            /^[1-9]{2,4}[2-9]\d{6}$/.test(v) ||
            "No es un numero de telefono válido.",
        ],
        email: [
          (v) =>
            /.+@.+/.test(v) ||
            v === "" ||
            v === undefined ||
            "No es un email válido",
        ],
        fecha_nacimiento: [(v) => this.validarFechaNacimiento(v)],
        fecha_inicio: [(v) => this.validarFechaInicio(v)],
        anio_diagnostico: [(v) => this.validarAnioDiagnostico(v)],
      },
      fechaActual: moment().format("YYYY-MM-DD"),
      fechaMinima: moment().subtract(100, "y").format("YYYY-MM-DD"),
      datePickerNacimiento: false,
      datePickerInicio: false,
      departamentos,
      sintomasDiagnostico,
      sindromesEpilepsia,
      otrosTratamientos,
      centrosAtencion,
      codigosCIE,
      gruposDiagnostico,
      formulasAplicadas,
      causasMuerte,
      causasSalida,
    };
  },
  computed: {
    fechaMinimaInicio: function () {
      return this.data["fecha_nacimiento"] || this.fechaMinima;
    },
  },
  methods: {
    validarFechaNacimiento(v) {
      const fechaNacimiento = moment(v);
      if (fechaNacimiento > moment())
        return "No puede ser superior al dia de hoy.";

      const fechaMinima = moment().startOf("day").subtract(100, "y");
      if (fechaNacimiento < fechaMinima)
        return `No puede ser inferior al ${fechaMinima.format("DD/MM/YYYY")}`;
      return true;
    },
    validarFechaInicio(v) {
      const fechaInicio = moment(v);
      if (fechaInicio > moment()) return "No puede ser superior al dia de hoy.";

      // Validacion de mayor o igual a fecha de nacimiento
      if (this.data.fecha_nacimiento) {
        const fechaNacimiento = moment(this.data.fecha_nacimiento);
        if (fechaInicio < fechaNacimiento)
          return "No puede ser inferior a la fecha de nacimiento.";
      } else {
        const fechaMinima = moment().startOf("day").subtract(100, "y");
        if (fechaInicio < fechaMinima)
          return `No puede ser inferior al ${fechaMinima.format("DD/MM/YYYY")}`;
      }
      return true;
    },
    validarAnioDiagnostico(v) {
      // Valida que el año sea superior al año de nacimiento
      if (this.data.fecha_nacimiento) {
        const fechaNacimiento = moment(this.data.fecha_nacimiento);
        if (v < fechaNacimiento.year())
          return "No puede ser inferior al año de nacimiento";
      }

      // Valida que el año sea inferior o igual al año de inicio de tratamiento
      if (this.data.fecha_inicio) {
        const fechaInicio = moment(this.data.fecha_inicio);
        if (v > fechaInicio.year())
          return "No puede superar el año de inicio de tratamiento";
      }

      // Validacion de rango de años
      const anioActual = moment().year();
      const anioMinimo = moment().subtract(100, "y").year();
      if (v < anioMinimo || v > anioActual)
        return `Año fuera de rango: ${anioMinimo} - ${anioActual}`;

      return true;
    },
    cerrarFormulario() {
      if (this.esEdicion) this.$emit("update:dataPaciente", null);
      this.$emit("update:showFormPaciente", !this.showFormPaciente);
    },
    submit() {
      this.errors = {};

      const sexoGenero = {
        "Trans F": "M",
        "Trans M": "F",
        F: "F",
        M: "M",
      };
      if (this.data["genero"]) {
        this.data["sexo"] = sexoGenero[this.data["genero"]];
      }

      if (this.data["email"]) {
        this.data.email = this.data.email.trim();
      }

      if (this.data["sintoma_diagnostico"] !== 99) {
        this.data["nombre_otro_sintoma"] = "";
      }

      if (this.$refs.formPaciente.validate()) {
        if (this.esEdicion)
          this.manejarResponseRequest(
            modificarPacienteId(this.data, this.data.id),
            "Paciente modificado."
          );
        else
          this.manejarResponseRequest(
            crearPaciente(this.data),
            "Paciente registrado."
          );
      }
    },
    manejarResponseRequest(response, mensaje) {
      this.cargando = true;
      response
        .then(() => {
          this.actualizarListaPacientes();
          if (this.esEdicion) this.$emit("update:dataPaciente", null);
          this.$emit("update:showFormPaciente", false);
          this.setSnackbar({
            activo: true,
            color: "success",
            texto: mensaje,
          });
        })
        .catch((e) => {
          if (e.response && e.response.status === 400)
            this.errors = { ...e.response.data };
          else this.manejarErrorAxios(e);
        })
        .finally(() => (this.cargando = false));
    },
  },
};
</script>

<style scoped>
.contenedor-datepicker {
  position: absolute;
  z-index: 1000;
}
</style>
