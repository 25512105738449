const nCuestionario = [
  "1 - Basal/Inicial",
  "2 - Mes 3",
  "3 - Mes 6",
  "4 - Mes 9",
  "5 - Mes 12",
  "6 - Mes 15",
  "7 - Mes 18",
  "8 - Mes 21",
  "9 - Mes 24",
];

const seguimiento = [
  "0 - Basal/Inicial",
  "3",
  "6",
  "9",
  "12",
  "15",
  "18",
  "21",
  "24",
];

const movilidad = [
  { value: 1, text: " No tengo problemas para caminar" },
  { value: 2, text: "Tengo algunos problemas para caminar" },
  { value: 3, text: "Tengo que estar en cama" },
];

const cuidados = [
  { value: 1, text: "No tengo problemas con el cuidado personal" },
  { value: 2, text: "Tengo algunos problemas para lavarme o vestirme solo" },
  { value: 3, text: "Soy incapaz de lavarme o vestirme solo" },
];

const ansiedad = [
  { value: 1, text: "No estoy ansioso/a ni deprimido/a" },
  { value: 2, text: "Estoy moderadamente ansioso/a o deprimido/a" },
  { value: 3, text: "Estoy muy ansioso/a o deprimido/a" },
];

const dolor = [
  { value: 1, text: "No tengo dolor ni malestar" },
  { value: 2, text: "Tengo moderado dolor o malestar" },
  { value: 3, text: "Tengo mucho dolor o malestar" },
];

const actividades = [
  { value: 1, text: "No tengo problemas para realizar actividades cotidianas" },
  {
    value: 2,
    text: "Tengo algunos problemas para realizar mis actividades cotidianas",
  },
  { value: 3, text: "Soy incapaz de realizar mis actividades cotidianas" },
];

const vitalidad = [
  {
    value: 1,
    text: "Siempre",
  },
  {
    value: 2,
    text: "Casi siempre",
  },
  {
    value: 3,
    text: "Algunas veces",
  },
  {
    value: 4,
    text: "Sólo alguna vez",
  },
  {
    value: 5,
    text: "Nunca",
  },
];

const desanimo = [
  {
    value: 1,
    text: "Nunca",
  },
  {
    value: 2,
    text: "Sólo alguna vez",
  },
  {
    value: 3,
    text: "Algunas veces",
  },
  {
    value: 4,
    text: "Casi siempre",
  },
  {
    value: 5,
    text: "Siempre",
  },
];

const varios = [
  {
    value: 1,
    text: "Ninguno",
  },
  {
    value: 2,
    text: "Pocos",
  },
  {
    value: 3,
    text: "Algunos",
  },
  {
    value: 4,
    text: "Muchos",
  },
  {
    value: 5,
    text: "Muchísimos",
  },
];

const miedo = [
  {
    value: 1,
    text: "Nada de miedo",
  },
  {
    value: 2,
    text: "No mucho miedo",
  },
  {
    value: 3,
    text: "Bastante miedo",
  },
  {
    value: 4,
    text: "Mucho miedo",
  },
  {
    value: 5,
    text: "Muchísimo miedo",
  },
];

const calidad = [
  {
    value: 1,
    text: "Muy bien, difícilmente hubiera podido irme mejor",
  },
  {
    value: 2,
    text: "Bastante bien",
  },
  {
    value: 3,
    text: "Bien y mal a partes iguales",
  },
  {
    value: 4,
    text: "Bastante mal",
  },
  {
    value: 5,
    text: "Muy mal, difícilmente hubiera podido irme peor",
  },
];

const reacciones = [
  {
    value: 1,
    text: "LEVE (No interfieren con mis actividades diarias)",
  },
  {
    value: 2,
    text: "MODERADO (Pueden interferir con las actividades diarias)",
  },
  {
    value: 3,
    text: "SEVERO (Interrumpen las actividades diarias)",
  },
];

const cave = [
  {
    value: 1,
    text: "Muy mala",
  },
  {
    value: 2,
    text: "Mala",
  },
  {
    value: 3,
    text: "Regular",
  },
  {
    value: 4,
    text: "Buena",
  },
  {
    value: 5,
    text: "Muy buena",
  },
];

const departamentos = [
  "BELGRANO",
  "COCHINOCA",
  "EL CARMEN",
  "HUMAHUACA",
  "LEDESMA",
  "PALPALA",
  "RINCONADA",
  "SAN ANTONIO",
  "SAN PEDRO",
  "SANTA BARBARA",
  "SANTA CATALINA",
  "SUSQUES",
  "TILCARA",
  "TUMBAYA",
  "VALLE GRANDE",
  "YAVI",
];

const sindromesEpilepsia = [
  { key: 1, label: "Síndrome de West" },
  { key: 2, label: "Síndrome de Lennox-Gastaut" },
  { key: 3, label: "Ausencias infantiles" },
  { key: 4, label: "Epilepsia Rolándica" },
  { key: 5, label: "Síndrome de Dravet" },
  { key: 6, label: "Epilepsia Mioclónica Juvenil" },
  { key: 7, label: "Síndrome de Jeavons" },
  { key: 8, label: "Síndrome de Doose" },
  { key: 9, label: "Encefalopatía con punta onda durante sueño" },
  { key: 99, label: "Otro" },
];

const sintomasDiagnostico = [
  { key: 1, label: "Convulsiones" },
  { key: 2, label: "Dolor Crónico" },
  { key: 3, label: "Nauseas y/o vómitos por quimioterapia" },
  {
    key: 4,
    label: "Transtornos del hábito evacuatorio (diarrea/constipación)",
  },
  { key: 5, label: "Cólico abdominal" },
  { key: 6, label: "Caquexia" },
  { key: 7, label: "Espasmos musculares" },
  { key: 8, label: "Rigidez muscular" },
  { key: 9, label: "Insomnio" },
  { key: 99, label: "Otros sintomas" },
];

const otrosTratamientos = [
  {key: 1, label: "Dieta Cetogénica"},
  {key: 2, label: "Estimulación Nervio Vago"},
  {key: 3, label: "Cirugía"},
]

const centrosAtencion = [
  { key: "HOSPITAL", label: "Hospital" },
  { key: "ISJ", label: "Instituto de seguros de Jujuy" },
  { key: "OS-PREPAGA", label: "Obra social prepaga" },
  { key: "PARTICULAR", label: "Particular" },
];

const codigosCIE = [
  "G400",
  "G401",
  "G402",
  "G403",
  "G404",
  "G405",
  "G406",
  "G407",
  "G408",
  "G409",
  "G410",
  "G411",
  "G412",
  "G413",
  "G414",
  "G415",
  "G416",
  "G417",
  "G418",
  "G419",
];

const gruposDiagnostico = ["Epilepsia Refractaria"];

const formulasAplicadas = ["CBD10"];

const causasMuerte = [
  { key: 0, label: "Muerte no relacionada" },
  { key: 1, label: "Muerte relacionada al CM" },
];

const causasSalida = [
  { key: 1, label: "Muerte" },
  { key: 2, label: "Abandono (decisión del paciente)" },
  { key: 3, label: "Decisión médica" },
  { key: 4, label: "Evento Adverso" },
  { key: 5, label: "Perdida de seguimiento" },
  { key: 6, label: "Embarazo" },
  { key: 98, label: "Desconocida" },
  { key: 99, label: "Otra" },
];

module.exports = {
  nCuestionario,
  seguimiento,
  movilidad,
  cuidados,
  ansiedad,
  dolor,
  actividades,
  vitalidad,
  desanimo,
  varios,
  miedo,
  calidad,
  reacciones,
  cave,
  departamentos,
  sintomasDiagnostico,
  sindromesEpilepsia,
  otrosTratamientos,
  centrosAtencion,
  codigosCIE,
  gruposDiagnostico,
  formulasAplicadas,
  causasMuerte,
  causasSalida,
};
