<template>
  <v-container fluid class="pa-0">
    <v-card class="card ma-0 mb-3" v-if="hasQueryParam">
      <v-container fluid>
        <v-card-title primary-title>
          Datos personales
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" class="pb-0">
              <div class="d-inline pe-3 font-weight-bold">APELLIDOS:</div>
              <div class="d-inline">{{datosPersonales.apellidos}}</div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="pb-0">
              <div class="d-inline pe-3 font-weight-bold">NOMBRES:</div>
              <div class="d-inline">{{datosPersonales.nombres}}</div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="pb-0">
              <div class="d-inline pe-3 font-weight-bold">DNI:</div>
              <div class="d-inline">{{datosPersonales.dni}}</div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="pb-0">
              <div class="d-inline pe-3 font-weight-bold">Género:</div>
              <div class="d-inline">{{genero}}</div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="pb-0">
              <div class="d-inline pe-3 font-weight-bold">Fecha de nacimiento:</div>
              <div class="d-inline">{{fechaNacimiento}}</div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="pb-0">
              <div class="d-inline pe-3 font-weight-bold">Departamento donde vive:</div>
              <div class="d-inline">{{datosPersonales.departamento}}</div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="pb-0">
              <div class="d-inline pe-3 font-weight-bold">Mes de inicio de tratamiento:</div>
              <div class="d-inline">{{fechaInicio}}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
    <v-card class="card">
      <v-stepper v-model="e6" vertical>
        <v-stepper-step :complete="e6 > 1" step="1" v-if="!hasQueryParam">
          Datos de la encuesta
          <small>(Complete los campos habilitados)</small>
        </v-stepper-step>

        <v-stepper-content step="1" v-if="!hasQueryParam">
          <div class="mb-3">
            <v-form ref="formDatos">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-label>Número de cuestionario</v-label>
                    <v-text-field
                      outlined
                      disabled
                      dense
                      :value="numEncuesta"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-label>Mes de seguimiento</v-label>
                    <v-text-field
                      outlined
                      disabled
                      dense
                      :value="(numEncuesta * 3) - 3"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-label>Fecha en que se completó*</v-label>
                    <v-text-field
                      :value="formData.fecha"
                      v-model="formData.fecha"
                      :rules="rules.required.concat(rules.fecha)"
                      type="date"
                      :max="fechaActual"
                      min="2000-01-01"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
          <v-btn color="primary" @click="nextStep('formDatos')">Continuar</v-btn>
        </v-stepper-content>

        <v-stepper-step
          :complete="hasQueryParam ? e6 > 1 : e6 > 2"
          :step="hasQueryParam ? 1 : 2"
        >
          Cuestionario de Salud
          <small>EuroQol EQ-5D</small>
        </v-stepper-step>

        <v-stepper-content
          :step="hasQueryParam ? 1 : 2"
        >
          <div class="mb-3">
            <div class="py-3">
              <p>
                Seleccione la respuesta de cada apartado que mejor
                <b>describa su estado de salud en el dia de HOY.</b>
              </p>
            </div>
            <v-form ref="formSalud">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-label for="movilidad">Movilidad:</v-label>
                    <v-select
                      :items="movilidad"
                      id="movilidad"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.salud.movilidad"
                      :rules="rules.required"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-label for="cuidado">Cuidado personal:</v-label>
                    <v-select
                      :items="cuidados"
                      id="cuidado"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.salud.cuidado_personal"
                      :rules="rules.required"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-label for="ansiedad">Ansiedad/Depresión:</v-label>
                    <v-select
                      :items="ansiedad"
                      id="ansiedad"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.salud.ansiedad_depresion"
                      :rules="rules.required"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-label for="dolor">Dolor/Malestar:</v-label>
                    <v-select
                      :items="dolor"
                      id="dolor"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.salud.dolor_malestar"
                      :rules="rules.required"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-label for="actividades">Actividades cotidianas:</v-label>
                    <v-select
                      :items="actividades"
                      id="actividades"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.salud.actividades_cotid"
                      :rules="rules.required"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-card-title class="primary--text">
                    Escala visual de salud
                  </v-card-title>
                  <v-card-subtitle>
                    Indicar en esta escala en su opinión, lo bueno o lo malo que
                    es su estado de salud en el día de HOY. Por favor, desplace la burbuja verde
                    hasta el punto del termómetro que en su opinión indique lo
                    bueno o lo malo que es su estado de salud en el día de HOY.
                  </v-card-subtitle>
                </v-row>
                <v-row class="mx-0">
                  <v-subheader class="text-start pl-0">
                    0: Peor estado de salud imaginable <br/>
                    100: Mejor estado de salud imaginable
                  </v-subheader>
                </v-row>
                <v-row class="mx-0 pt-5">
                  <v-col sm="1" class="px-0 text-end d-none d-sm-block">0</v-col>
                  <v-col cols="12" sm="10" class="px-0">
                    <v-slider
                      v-model="formData.salud.estado_salud"
                      min="0"
                      max="100"
                      thumb-label="always"
                      thumb-size="25"
                      tick-size="4"
                    >
                    </v-slider>
                  </v-col>
                  <v-col sm="1" class="px-0 text-start d-none d-sm-block">100</v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
          <v-btn color="primary" @click="nextStep('formSalud')">Continuar</v-btn>
          <v-btn text @click="backStep" v-if="!hasQueryParam"> Anterior </v-btn>
        </v-stepper-content>

        <div v-if="esEncuestaAdulto">
          <v-stepper-step
            :complete="hasQueryParam ? e6 > 2 : e6 > 3"
            :step="hasQueryParam ? 2 : 3"
          >
            Crisis Epilépticas y Cuestionario Calidad de vida en adultos.
            <small>(Mayor o igual de 16 años)</small>
          </v-stepper-step>

          <v-stepper-content
            :step="hasQueryParam ? 2 : 3"
          >
            <div class="mb-3">
              <v-form ref="formCalidadVidaAdulto">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" sm="6" class="pb-0">
                      <div class="font-weigth-medium">1- Número de crisis epilépticas durante las últimas 2 semanas</div>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pt-sm-3 pb-0">
                      <v-text-field
                        placeholder="Ingrese un número"
                        id="cantEpilepcia"
                        outlined
                        clearable
                        dense
                        v-model="formData.calidad_vida_adulto.crisis_epilepticas"
                        type="number"
                        min="0"
                        :rules="rules.required.concat(rules.mayorCero)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <p class="mb-0">
                        2- Cuestionario de calidad de vida en epilepsia (QOLIE-10)
                        de adultos (para pacientes de 16 años o más)
                      </p>
                      <small>(Seleccione una opción para cada valoración)</small>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="pb-0">
                      <p>
                        <b>¿Cuántas veces durante las últimas 4 semanas...</b>
                      </p>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="py-0">
                      ¿Se sintió lleno de vitalidad?
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0">
                      <v-select
                        :items="vitalidad"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.vitalidad"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0">
                      ¿Se sintió deanimado y triste?
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0">
                      <v-select
                        :items="desanimo"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.desanimado"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
                      ¿Le ha causado su epilepsia o su medicación antiepileptica
                      problemas para desplazarse?
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
                      <v-select
                        :items="varios"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.problemas_desplaz"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="pb-0">
                      <p>
                        <b
                          >Durante las últimas 4 semanas, cuantas veces ha tenido
                          problemas con:</b
                        >
                      </p>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="py-0">
                      ¿Dificultades de memória?
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0">
                      <v-select
                        :items="varios"
                        item-text="text"
                        value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.dificultad_memoria"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
                      ¿Limitaciones en el trabajo?
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
                      <v-select
                        :items="varios"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.limitac_trabajo"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
                      ¿Limitaciones en su vida social?
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
                      <v-select
                        :items="varios"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.limitac_vida_social"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
                      ¿Efectos físicos de la medicación antiepiléptica?
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
                      <v-select
                        :items="varios"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.efectos_fisicos"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
                      ¿Efectos mentales de la medicación antiepiléptica?
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
                      <v-select
                        :items="varios"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.efectos_mentales"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
                      ¿Le da miedo sufrir un ataque en las próximas 4 semanas?
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
                      <v-select
                        :items="miedo"
                        item-text="text"
                        value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.miedo_ataque"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>
                  <v-row>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
                        <b>
                          ¿Que tal ha sido su calidad de vida durante las últimas 4
                          semanas? (es decir ¿cómo le han ido las cosas?)
                        </b>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
                      <v-select
                        :items="calidad"
                        item-text="text"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_adulto.calidad_vida"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </div>
            <v-btn color="primary" @click="nextStep('formCalidadVidaAdulto')"> Continuar </v-btn>
            <v-btn text @click="backStep"> Anterior </v-btn>
          </v-stepper-content>
        </div>
        <div v-else>
          <v-stepper-step
            :complete="hasQueryParam ? e6 > 2 : e6 > 3"
            :step="hasQueryParam ? 2 : 3"
          >
            Crisis Epilépticas y Cuestionario Calidad de vida en pediatría
            <small>(Menores de 16 años)</small>
          </v-stepper-step>
          <v-stepper-content
            :step="hasQueryParam ? 2 : 3"
          >
            <div class="mb-3">
              <v-form ref="formCalidadVidaPediatria">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <div class="font-weigth-medium">1- Número de crisis epilépticas durante las últimas 2 semanas</div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="pt-0">
                      <v-text-field
                        id="cantEpilepcia"
                        placeholder="Ingrese un número"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_pediatria.crisis_epilepticas"
                        type="number"
                        min="0"
                        :rules="rules.required.concat(rules.mayorCero)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <p class="mb-0">
                        2- Escala de calidad de vida del niño con epilepsia (CAVE) (Para pacientes menores de 16 años)
                      </p>
                      <small><i>* Primero consulte debajo la definición de códigos CAVE para cada una de las definiciones</i></small><br>
                      <small>(Seleccione una opción para cada valoración) </small>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-label>Conducta*</v-label>
                      <v-select
                        :items="cave"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_pediatria.conducta"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-label>Asistencia escolar*</v-label>
                      <v-select
                        :items="cave"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_pediatria.asistencia_escolar"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12"  sm="6" md="4">
                      <v-label>Aprendizaje*</v-label>
                      <v-select
                        :items="cave"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_pediatria.aprendizaje"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-label>Autonomía*</v-label>
                      <v-select
                        :items="cave"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_pediatria.autonomia"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-label>Relación social*</v-label>
                      <v-select
                        :items="cave"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_pediatria.relacion_social"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-label>Frecuencia de crisis*</v-label>
                      <v-select
                        :items="cave"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_pediatria.frecuencia_crisis"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-label>Intensidad de crisis*</v-label>
                      <v-select
                        :items="cave"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_pediatria.intensidad_crisis"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-label>Opinión de los padres*</v-label>
                      <v-select
                        :items="cave"
                        item-text="text"
                        item-value="value"
                        outlined
                        clearable
                        hide-details="auto"
                        dense
                        v-model="formData.calidad_vida_pediatria.opinion_padres"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <div
                    class="mt-6"
                    height="auto"
                  >
                    <v-card-title class="primary--text">
                      Definición códigos CAVE
                    </v-card-title>
                    <v-expansion-panels
                      focusable
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header><b>Conducta</b></v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <b>1. Muy mala:</b> trastornos graves de la conducta, entendiendo como tales los que repercuten de manera importante en la dinámica familiar, y no pueden modificarse de ningún modo.<br />
                          <b>2. Mala:</b> trastornos importantes del comportamiento que interrumpen la dinámica familiar, pero que se pueden mejorar parcialmente, e incluso anular de forma temporal, con técnicas de modificación de conducta.<br />
                          <b>3. Regular:</b> alteraciones moderadas de la conducta, que responden bien a normas educacionales.<br />
                          <b>4. Buena:</b> sin comentarios.<br />
                          <b>5. Muy buena:</b> corresponde a la del “niño modelo”.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header><b>Asistencia escolar</b></v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <b>1. Muy mala:</b> absentismo prácticamente total, no asiste ningún día o casi ningún día al colegio o a la guardería<br />
                          <b>2. Mala:</b>  no asiste al colegio o a la guardería una semana o más, por trimestre, y llega a estar ausente la tercera parte de los días.<br />
                          <b>3. Regular:</b> no asiste al colegio o a la guardería una semana o más por trimestre, pero sin llegar a estar ausente la tercera parte de los días<br />
                          <b>4. Buena:</b>  absentismo escolar inferior a siete días por trimestre<br />
                          <b>5. Muy buena:</b> : ninguna falta de asistencia durante el periodo de tiempo analizado.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header><b>Aprendizaje</b></v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <b>1. Muy mala:</b>  aprendizaje nulo, incluso con impresión de perdida de lo adquirido, si ello es posible<br />
                          <b>2. Mala:</b> aprendizaje escaso, casi imperceptible, pero sin matices regresivos<br />
                          <b>3. Regular:</b> aprendizaje discreto, pero evidente y mantenido, aunque con lentitud en las adquisiciones<br />
                          <b>4. Buena:</b> sin comentarios.<br />
                          <b>5. Muy buena:</b> aprendizaje excelente, superior a la media de su clase, o al de su grupo de edad cronológica o mental.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header><b>Autonomía</b></v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <b>1. Muy mala:</b> autonomía nula, dependencia total de los adultos para todo.<br />
                          <b>2. Mala:</b> dependencia parcial, o solo para algunas cosas.<br />
                          <b>3. Regular:</b> dependencia escasa, e incluso “ficticia”, no debida a limitaciones reales, sino a sobreprotección familiar.<br />
                          <b>4. Buena:</b> sin comentarios.<br />
                          <b>5. Muy buena:</b>  independencia en las actividades propias de la edad, pero con una habilidad excelente.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header><b>Relación social</b></v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <b>1. Muy mala:</b> nula relación social, aislamiento total.<br />
                          <b>2. Mala:</b> tendencia frecuente al aislamiento, pero con relación ocasional dentro del medio familiar.<br />
                          <b>3. Regular:</b> aislamiento ocasional, tanto dentro como fuera del entorno familiar.<br />
                          <b>4. Buena:</b> sin comentarios.<br />
                          <b>5. Muy buena:</b> excelente relación social e intensa extroversión.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header><b>Frecuencia de la crisis</b></v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <b>1. Muy mala:</b>  más de diez días con crisis durante el periodo de tiempo analizado.<br />
                          <b>2. Mala:</b> de seis a diez días con crisis durante el periodo de tiempo analizado.<br />
                          <b>3. Regular:</b> de dos a cinco crisis durante el periodo de tiempo analizado.<br />
                          <b>4. Buena:</b> un día con crisis durante el periodo de tiempo analizado<br />
                          <b>5. Muy buena:</b> sin crisis durante el periodo de tiempo analizado
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header><b>Intensidad de la crisis</b></v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <b>1. Muy mala:</b> crisis convulsivas de larga duración, frecuentes crisis acinéticas o estatus no convulsivos.<br />
                          <b>2. Mala:</b> crisis convulsivas de corta duración, crisis acinéticas poco frecuentes o crisis parciales complejas de larga duración, con o sin generalización secundaria.<br />
                          <b>3. Regular:</b> crisis parciales complejas de breve duración, crisis elementales o crisis mioclónicas aisladas.<br />
                          <b>4. Buena:</b> crisis únicas o crisis no convulsivas muy poco frecuentes.<br />
                          <b>5. Muy buena:</b> : sin crisis.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <p class="mt-4"><b>Opinión de los padres:</b> : Se pretende recoger la opinión subjetiva de los padres respecto a la calidad de vida de su hijo con epilepsia. Debe contestarse igualmente con cinco respuestas, que se puntúan de 1 (=muy mala) a 5 (=muy buena)</p>
                  </div>
                </v-container>
              </v-form>
            </div>
            <v-btn color="primary" @click="nextStep('formCalidadVidaPediatria')"> Continuar </v-btn>
            <v-btn text @click="backStep"> Anterior </v-btn>
          </v-stepper-content>
        </div>

        <v-stepper-step
          :complete="hasQueryParam ? e6 > 3 : e6 > 4"
          :step="hasQueryParam ? 3 : 4"
          v-if="numEncuesta > 1">
          Reacciones adversas asociadas al cannabis medicinal
        </v-stepper-step>

        <v-stepper-content
          :step="hasQueryParam ? 3 : 4"
          v-if="numEncuesta > 1"
        >
          <div class="mb-3">
            <v-form ref="formReacciones">
              <v-container fluid>
                <div class="font-weight-medium mb-3">1- Notó síntomas NUEVOS en el último trimestre:</div>
                <v-row>
                  <v-col cols="12" sm="6" lg="4">
                    <v-select
                     placeholder="Seleccione una opción*"
                      :items="['SI', 'NO']"
                      item-text="text"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="sintomasNuevos"
                      :rules="rules.required"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
              </v-container>
              <v-container fluid v-if="sintomasNuevos === 'SI'">
                <v-row>
                  <v-col cols="12" class="pb-sm-5">
                    <div class="font-weight-medium">2- Si su respuesta fue SI, complete el siguiente cuestionario (Seleccione solamente el o los síntomas en la situación que corresponde):</div>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Somnolencia (Es una tendencia al sueño)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.somnolencia"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Mareos (Es una sensación de inestabilidad física)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.mareos"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Boca seca (Es una sensación de que no hay suficiente saliva
                    en su boca)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.boca_seca"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Ansiedad (Sensación de temor, amenaza, inquietud y/o tensión
                    constante)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.ansiedad"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Náuseas (Son las ganas de vomitar o hipersalivación o hipo)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.nauseas"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Mayor difucultad para concentrarse, recordar datos y/o hacer
                    tareas mentales
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.dificultad_concentracion"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Disminución del apetito (Es la disminución de las ganas de
                    comer)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.disminucion_apetito"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                      Diarrea (Son evaciaciones líquidas, 3 o más veces por día)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.diarrea"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Mayor cansancio / fatiga
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.mayor_cansancio"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Aumento de los niveles de las transaminasas (En un análisis
                    de sangre)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.aumento_transaminasas"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Euforia (Es una sensación de optimismo exagerado)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.euforia"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Visión borrosa
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.vision_borrosa"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Dolor de cabeza
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.dolor_cabeza"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Mareos o presión arterial baja al incorporarse
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.presion_baja"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Paranoia (Constante sensación de temor o desconfianza hacia
                    los demás o al exterior)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.paranoia"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Depresión (Constante tristesa / desanimo)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.depresion"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Falta de coordinación motora
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.falta_cordinacion_motora"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Palpitaciones
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.palpitaciones"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Vómitos recuerrentes (3 o más veces al día)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.vomitos"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Alucinaciones (Ver, escuchar o sentir cosas que no existen)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.alucinaciones"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Delirio (Pensamientos o habla incoherentes)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.delirio"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Excitación psicomotriz (Gritos, llantos, o el habla
                    descontrolados)
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      :items="reacciones"
                      item-text="text"
                      item-value="value"
                      outlined
                      clearable
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.exitacion_psicomotriz"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    Otros (especifique):
                  </v-col>
                  <v-col cols="12" sm="6" class="pbt-0">
                    <v-text-field
                      placeholder="Ingrese la descripción"
                      clearable
                      outlined
                      hide-details="auto"
                      dense
                      v-model="formData.reaccion_adversa.otro"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
          <v-btn color="primary" @click="nextStep('formReacciones')"> Continuar </v-btn>
          <v-btn text @click="backStep"> Anterior </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :step="hasQueryParam ? (numEncuesta > 1 ? 4 : 3) : (numEncuesta > 1 ? 5 : 4)"
        >
          Registro de medicación concomitante
        </v-stepper-step>
        <v-stepper-content
          :step="hasQueryParam ? (numEncuesta > 1 ? 4 : 3) : (numEncuesta > 1 ? 5 : 4)"
        >
          <div class="mb-3">
            <v-container fluid>
              <v-row>
                <div class="font-weight-bold">
                  Nombre uno por por uno, todos los medicamentos que consume de forma habitual,
                  con o sin prescripción médica, desde la última semana.
                </div>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4" class="px-0 py-2">
                  <v-text-field
                    label="Nombre de medicamento"
                    outlined
                    clearable
                    hide-details="auto"
                    dense
                    v-model="medicamento"
                    @keyup.enter="agregarMedicamento"
                    counter
                    maxlength="20"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="px-0 px-sm-3 py-2 text-end text-sm-start">
                  <v-btn depressed @click="agregarMedicamento"> Agregar </v-btn>
                </v-col>
              </v-row>
              <v-row class="pt-4">
                <v-col cols="12">
                  <div class="body-2 secondary--text">Listado de medicamentos:</div>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-simple-table class="table">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Nombre medicamento</th>
                          <th class="text-left">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in formData.medicacion_concomitante" :key="index">
                          <td>{{ item }}</td>
                          <td class="ps-7">
                            <v-icon
                              small @click="borrarMedicamento(index)" color="red"
                            >
                              mdi-close-thick
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <p>Total de medicamentos: <strong>{{ this.formData.medicacion_concomitante.length }}</strong></p>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <v-btn color="primary" @click="registrarEncuesta"> Finalizar </v-btn>
          <v-btn text @click="backStep"> Anterior </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-card>
    <v-dialog
      v-model="cargando"
      persistent
      width="300"
    >
      <v-card
        color="secondary"
        dark
      >
        <v-card-text>
          <div class="my-2">Por favor espere...</div>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Snackbar para mostrar mensajes de error o éxito -->
    <div class="text-center">
      <v-snackbar
        v-model="snackbar.status" :vertical="true" :color="snackbar.color" timeout="3000" centered
      >
        <span id="texto">{{ snackbar.text }}</span>
      </v-snackbar>
    </div>

    <!-- Modal para mostrar mensaje de éxito -->
    <dialog-exito :mostrarExito="successfulOperation" :mostrarBotones="!hasQueryParam" />

    <!-- dialog para confirmar una operacion -->
    <v-dialog
      v-model="dialogConfirmar"
      persistent
      max-width="500"
    >
      <v-card>
        <div class="text-h5 font-weight-medium py-4 px-6">¿Confirma que no tuvo crisis epilépticas durante las últimas 2 semanas?</div>
        <v-card-text>
          Usted indicó que tuvo 0 (cero) crisis epilépticas en el lapso de tiempo mencionado, confirme si ésto es correcto,
          caso contrario vuelva a completar el campo.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="restablecerCrisis"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmarCrisis"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Options from '@/components/options'
import DialogExito from '@/components/DialogExito.vue'
import { createEncuesta, createEncuestaPublica } from '@/services/EncuestasService'
import { getPacienteToken } from '@/services/PacientesService'
import moment from 'moment';

export default {
  name: "FormEncuesta",
  props: {
    idPaciente: {
      type: Number,
      required: true
    },
    tokenPaciente: {
      type: String,
      required: true
    },
    numEncuesta: {
      type: Number,
      required: true
    },
    hasQueryParam: {
      type: Boolean,
      required: true
    },
    esEncuestaAdulto: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DialogExito
  },
  data () {
    return {
      fechaActual: moment().format('YYYY-MM-DD'),
      formData: {
        fecha: null,
        paciente: this.idPaciente,
        salud: {
          estado_salud: 50
        },
        reaccion_adversa: {},
        calidad_vida_adulto: {},
        calidad_vida_pediatria: {},
        medicacion_concomitante: []
      },
      datosPersonales: {},
      e6: 1,
      movilidad: Options.movilidad,
      cuidados: Options.cuidados,
      ansiedad: Options.ansiedad,
      dolor: Options.dolor,
      actividades: Options.actividades,
      vitalidad: Options.vitalidad,
      desanimo: Options.desanimo,
      varios: Options.varios,
      miedo: Options.miedo,
      calidad: Options.calidad,
      reacciones: Options.reacciones,
      cave: Options.cave,
      sintomasNuevos: '',
      medicamento: '',
      rules: {
        required: [v => !!v || 'Campo requerido'],
        mayorCero: [v => v >= 0 || 'Debe ser mayor o igual a cero'],
        fecha: [v => this.validarFechaEncuesta(v)]
      },
      cargando: false,
      snackbar: {
        status: false,
        text: 'Texto predeterminado',
        color: 'primary'
      },
      successfulOperation: false,
      dialogConfirmar: false,
    }
  },
  computed: {
    genero: function() {
      const generos = {
        M: 'Masculino',
        F: 'Femenino',
        'Trans M': 'Trans Masculino',
        'Trans F': 'Trans Femenino'
      }
      return generos[this.datosPersonales['genero']]
    },
    fechaNacimiento: function() {
      return moment(this.datosPersonales['fecha_nacimiento']).format('DD/MM/YYYY')
    },
    fechaInicio: function() {
      return moment(this.datosPersonales['fecha_inicio']).format('MM/YYYY')
    }
  },
  methods: {
    validarFechaEncuesta(v) {
      const fecha = moment(v)
      if (fecha < moment('2020-01-01')) return 'La fecha no puede ser inferior al 01/01/2020'
      if (fecha > moment()) return 'La fecha no puede ser posterior a la fecha actual'

      return true
    },
    nextStep(formRef) {
      if (this.$refs[formRef].validate()) {
        if ((this.hasQueryParam && this.e6 === 2) || (!this.hasQueryParam && this.e6 === 3)) {
          // Verifica que el numero de crisis epilepticas en la encuesta basal no sea cero
          if (this.numEncuesta === 1) {
            let crisisEpilepticas = 0
            if (this.esEncuestaAdulto) {
              crisisEpilepticas = this.formData.calidad_vida_adulto['crisis_epilepticas']
            } else {
              crisisEpilepticas = this.formData.calidad_vida_pediatria['crisis_epilepticas']
            }

            crisisEpilepticas = parseInt(crisisEpilepticas)
            if (!isNaN(crisisEpilepticas) && crisisEpilepticas === 0) {
              this.dialogConfirmar = true
              return
            }
          }
        }
        if (formRef === 'formReacciones'){
          // Verifica que si se selecciona SI en reacciones adversas se registre al menos una reaccion
          if (this.sintomasNuevos === 'SI') {
            let existen = false
            for (const key in this.formData.reaccion_adversa) {
              if (this.formData.reaccion_adversa[key] !== null) {
                existen = true
                break
              }
            }
            if (!existen) {
              this.snackbar = {
                status: true,
                text: "Debe seleccionar al menos una reaccion adversa.",
                color: "orange darken-3"
              }
              return
            }
          }
        }
        this.e6 += 1
      }
    },
    backStep() {
      this.e6 -= 1
    },
    agregarMedicamento () {
      if (this.medicamento.trim()) {
        this.formData.medicacion_concomitante.push(this.medicamento.trim())
      }
      this.medicamento = ''
    },
    borrarMedicamento (index) {
      this.formData.medicacion_concomitante.splice(index, 1)
    },
    registrarEncuesta() {
      this.cargando = true

      // Borra los campos de reaccion adversa con valor null
      if (this.sintomasNuevos === 'SI') {
        for (const key in this.formData.reaccion_adversa) {
          if (this.formData.reaccion_adversa[key] === null) {
            delete this.formData.reaccion_adversa[key]
          }
        }
      } else {
        this.formData.reaccion_adversa = {}
      }

      if (this.hasQueryParam) {
        // Asigna la estructura data correspondiente
        let keyEncuesta = 'encuesta_adulto'
        if (this.datosPersonales.edad_basal < 16) keyEncuesta = 'encuesta_pediatria'

        let data = {
          token: this.tokenPaciente,
          paciente: this.idPaciente
        }
        data[keyEncuesta] = this.formData
        this.crearEncuesta(createEncuestaPublica, data)

      } else this.crearEncuesta(createEncuesta, this.formData)

    },
    crearEncuesta(funcionCreacion, data) {
      funcionCreacion(data)
        .then(() => {
          this.cargando = false
          this.successfulOperation = true
          window.localStorage.removeItem('paciente')
          window.localStorage.removeItem('numeroEncuesta')
        })
        .catch(e => {
          this.cargando = false
          let textSnackbar = ''
          if (e.response) {
            if ([401, 403].includes(e.response.status)) {
              textSnackbar = 'No esta autorizado para realizar esta operación.'
              window.localStorage.removeItem('token')
              setTimeout(() => this.$router.push('/login'), 2000)
            } else {
              if (e.response.status === 400) {
                textSnackbar = 'Hay errores en los campos.'
              } else {
                textSnackbar = 'Se produjo un error interno en el servidor, intente más tarde.'
              }
            }            
          } else {
            textSnackbar = 'Se produjo un error en el servidor, intente más tarde.'
          }
          this.snackbar = {
            status: true,
            text: textSnackbar,
            color: 'red'
          }
        })
    },
    confirmarCrisis() {
      this.e6 += 1
      this.dialogConfirmar = false
    },
    restablecerCrisis() {
      if (this.esEncuestaAdulto) {
        this.formData.calidad_vida_adulto['crisis_epilepticas'] = null
      } else {
        this.formData.calidad_vida_pediatria['crisis_epilepticas'] = null
      }
      this.dialogConfirmar = false
    },
    getDatosPaciente() {
      getPacienteToken(this.idPaciente, this.tokenPaciente)
        .then(dataPaciente => this.datosPersonales = dataPaciente)
        .catch(e => {
          if (e.response && e.response.status === 0) {
            this.snackbar = {
              status: true,
              text: 'Ocurrió un error inesperado en el servidor, intente mas tarde.',
              color: 'red'
            }
          } else this.successfulOperation = true
        })
    }
  },
  created() {
    if (this.hasQueryParam) this.getDatosPaciente()
  }
};
</script>
