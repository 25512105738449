<template>
  <v-container fluid class="pa-0 pa-md-3 d-flex justify-center align-start">
    <v-card width="800" class="card-status mx-0 mx-md-3 pa-4">
      <div v-if="loading" class="d-flex align-center justify-center">
        <v-progress-circular
          indeterminate
          color="secondary"
        ></v-progress-circular>
        <div class="ml-3">Leyendo datos...</div>
      </div>
      <div v-else class="d-flex flex-column align-center">
        <div v-if="qr">
          <div class="title text-center mb-3">
            Por favor escanee el código QR
          </div>
          <qr-code :text="qr" :size="300" class="white pa-4"></qr-code>
        </div>
        <div v-if="session" class="text-center">
          <div class="title mb-3">La sesión de WhatsApp esta activa</div>
          <div class="font-weight-medium text--secondary mb-3">
            <v-avatar class="profile" color="grey" size="164">
              <v-img :src="session.picUrl"></v-img>
            </v-avatar>
          </div>
          <div class="font-weight-medium text--secondary mb-3">
            Nombre: {{ session.name }}
          </div>
          <div class="font-weight-medium text--secondary mb-3">
            Teléfono: {{ session.phone }}
          </div>
          <v-btn
            :loading="disconnecting"
            :disabled="disconnecting"
            color="error"
            @click="handleDisconnectClick"
          >
            Desconectar
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import io from "socket.io-client";
import VueQRCodeComponent from "vue-qrcode-component";

export default {
  name: "ComunicacionView",
  components: {
    "qr-code": VueQRCodeComponent,
  },
  props: {
    setSnackbar: {
      type: Function,
      required: true,
    },
    setItemSeleccionado: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      qr: null,
      session: null,
      snackbar: {
        show: false,
        color: "primary",
        text: "Proceso finalizado con éxito.",
      },
      socket: null,
      disconnecting: false,
    };
  },
  methods: {
    handleQr(receivedQr) {
      this.qr = receivedQr;
      this.loading = false;
    },
    handleAuthenticated(dataSession) {
      this.qr = null;
      this.session = dataSession;
      this.loading = false;
    },
    handleDisconnected() {
      this.disconnecting = false;
      this.session = null;
      this.qr = null;
      this.loading = true;
    },
    handleDisconnectClick() {
      this.disconnecting = true;
      this.socket.emit('session:disconnect')
    },
  },
  created() {
    this.setItemSeleccionado("comunicacion");
  },
  mounted() {
    this.socket = io(process.env.VUE_APP_WHATSAPP_URL ?? "http://localhost:3002");

    this.socket.emit("qr:load");
    this.socket.on("qr", this.handleQr);
    this.socket.on("session:authenticated", this.handleAuthenticated);
    this.socket.on("session:disconnected", this.handleDisconnected);
  },
  destroyed() {
    this.socket.off("qr", this.handleQr);
    this.socket.off("session:authenticated", this.handleAuthenticated);
    this.socket.off("session:disconnected", this.handleDisconnected);
  },
};
</script>

<style scoped>
.card-status {
  backdrop-filter: blur(16px) saturate(100%);
  -webkit-backdrop-filter: blur(16px) saturate(100%);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}
</style>
