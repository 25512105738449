const rules = {
  required: (v) => !!v || "Campo obligatorio",
  notNull: (v) => v !== null || "Campo obligatorio",
  enteroPositivo: (v) =>
    (Number.isInteger(+v) && +v >= 0) ||
    "Debe ser un valor entero mayor o igual que cero y menor o igual a 10.",
  menorIgual10: (v) =>
    +v <= 10 || "Debe ser un valor entero menor o igual a 10.",
};

const tipoRadioSiNo = {
  widget: "radio-group",
  opciones:[
    { label: "Sí", value: true},
    { label: "No", value: false}
  ],
  rules: [rules.notNull]      
}

const tipoRadio10 =  {
  widget: "radio-group",
  opciones:[
    { label: "0", value: 0},
    { label: "1", value: 1},
    { label: "2", value: 2},
    { label: "3", value: 3},
    { label: "4", value: 4},
    { label: "5", value: 5},
    { label: "6", value: 6},
    { label: "7", value: 7},
    { label: "8", value: 8},
    { label: "9", value: 9},
    { label: "10", value: 10}
  ],
  rules: [rules.required]      
}

const tipoAutoCompletar = {
  widget: "autocomplete",
  opciones: [
    { value: 1, label: "LEVE (No interfieren con mis actividades diarias)" },
    { value: 2, label: "MODERADO (Pueden interferir con las actividades diarias)" },
    { value: 3, label: "SEVERO (Interrumpen las actividades diarias)" },
  ]
}

const formularioNRS = [
  { 
    pregunta: "Dolor promedio de la ultima semana", 
    model: "nrs",
    tipo : {
      widget: "slider0a10"
    } 

  }
]

const formularioSeguimiento = [
/*   { pregunta: "Telefono", model: "telefono" }, */
  { 
    pregunta: "Dosis en las ultimas 24hs", 
    model: "dosis",
    tipo : {
      widget: "text-field",
      type: "number",
      rules: [rules.required, rules.enteroPositivo]
    }
  }
]
const formularioBPI = [
  {
    pregunta:
      "1. Evalúe su dolor marcando la casilla que mejor describa el PEOR dolor que haya sentido en las últimas 24 horas.",
    model: "peor_dolor",
    tipo : tipoRadio10
  },
  {
    pregunta:
      "2. Evalúe su dolor marcando la casilla que mejor describa el dolor MAS LEVE que haya sentido en las últimas 24 horas.",
    model: "dolor_leve",
    tipo : tipoRadio10
  },
  {
    pregunta:
      "3. Evalúe su dolor marcando la casilla que mejor describa el dolor que haya sentido en PROMEDIO en las últimas 24 horas",
    model: "dolor_promedio",
    tipo : tipoRadio10
  },
  {
    pregunta:
      "4. Evalúe su dolor marcando la casilla que indique dolor que siente AHORA MISMO",
    model: "dolor_actual",
    tipo : tipoRadio10
  },
  {
    pregunta: "5. Marque con el número que describa en referencia a las últimas 24 horas, cuanto ha perturbado el dolor su(s):",
    model: "perturbacion_dolor",
    options:[
      { label: "1. Actividad general", model: "actividad" , tipo: tipoRadio10},
      { label: "2. Estado de ánimo", model: "animo" , tipo: tipoRadio10},
      { label: "3. Capacidad de andar", model: "capacidad_andar" , tipo: tipoRadio10},
      {
        label:
          "4. Trabajo normal (incluye tanto el trabajo fuera de casa como el doméstico)",
        model: "trabajo",
        tipo: tipoRadio10
      },
      {
        label: " 5. Relaciones con otras personas",
        model: "relacion_social",
        tipo: tipoRadio10
      },
      { label: "6. Sueño", model: "suenio", tipo: tipoRadio10 },
      { label: "7. Disfrute de la vida", model: "disfrute_vida", tipo: tipoRadio10 },
    ]
  }
];

const formularioHADS = [
  {
    pregunta: "1. Me siento tenso o nervioso",
    model: "tenso_nervioso",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 3, label: "Todos los dias" },
        { value: 2, label: "Muchas veces" },
        { value: 1, label: "A veces" },
        { value: 0, label: "Nunca" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "2. Todavía disfruto con lo que antes me gustaba",
    model: "disfrute",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 0, label: "Como siempre" },
        { value: 1, label: "No lo bastante" },
        { value: 2, label: "Sólo un poco" },
        { value: 3, label: "Nada" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta:
      "3. Tengo una sensación de miedo, como si algo horrible me fuera a suceder",
    model: "miedo",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 3, label: "Definitivamente y es muy fuerte" },
        { value: 2, label: "Si, pero no es muy fuerte" },
        { value: 1, label: "Un poco, pero no me preocupa" },
        { value: 0, label: "Nada" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "4. Puedo reírme y ver el lado divertido a las cosas",
    model: "risas",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 0, label: "Al igual que siempre lo hice" },
        { value: 1, label: "No tanto ahora" },
        { value: 2, label: "Casi nunca" },
        { value: 3, label: "Nunca" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "5. Tengo mi mente llena de preocupaciones",
    model: "preocupaciones",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 3, label: "La mayoría de las veces" },
        { value: 2, label: "Con bastante frecuencia" },
        { value: 1, label: "A Veces, aunque no muy a menudo" },
        { value: 0, label: "Sólo en ocasiones" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "6. Me siento alegre",
    model: "alegria",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 3, label: "Nunca" },
        { value: 2, label: "No muy a menudo" },
        { value: 1, label: "A Veces" },
        { value: 0, label: "Casi siempre" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta:
      "7. Puedo estar sentado confortablemente y sentirme relajado",
    model: "relajacion",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 0, label: "Siempre" },
        { value: 1, label: "Por lo general" },
        { value: 2, label: "No muy a menudo" },
        { value: 3, label: "Nunca" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "8. Me siento como si cada día estuviera más lento",
    model: "lentitud",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 3, label: "Por lo general, en todo momento" },
        { value: 2, label: "Muy a menudo" },
        { value: 1, label: "A veces" },
        { value: 0, label: "Nunca" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta:
      "9. Tengo una sensación extraña, como si tuviera mariposas en el estómago",
    model: "sensacion_mariposas",
    tipo : {
      widget: "radio-group",
      opciones: [
        { value: 0, label: "Nunca" },
        { value: 1, label: "En ciertas ocasiones" },
        { value: 2, label: "Con bastante frecuencia" },
        { value: 3, label: "Muy a menudo" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "10. He perdido interés en mi aspecto personal",
    model: "perdida_interes",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 3, label: "Totalmente" },
        { value: 2, label: "No me preocupo tanto como debiera" },
        { value: 1, label: "Podría tener un poco más de cuidado" },
        { value: 0, label: "Me preocupo al igual que siempre" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta:
      "11. Me siento inquieto, como si no pudiera parar de moverme",
    model: "inquieto",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 3, label: "Mucho" },
        { value: 2, label: "Bastante" },
        { value: 1, label: "No mucho" },
        { value: 0, label: "Nada" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "12. Me siento optimista respecto al futuro",
    model: "optimismo",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 0, label: "Igual que siempre" },
        { value: 1, label: "Menos de lo que acostumbraba" },
        { value: 2, label: "Mucho menos de lo que acostumbraba" },
        { value: 3, label: "Nada" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "13. Me asaltan sentimientos repentinos de pánico",
    model: "panico",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 3, label: "Muy frecuentemente" },
        { value: 2, label: "Bastante a menudo" },
        { value: 1, label: "No muy a menudo" },
        { value: 0, label: "Rara vez" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta:
      "14. Me divierto con un buen libro, la radio, o un programa de televisión",
    model: "diversion",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 0, label: "A menudo" },
        { value: 1, label: "A veces" },
        { value: 2, label: "No muy a menudo" },
        { value: 3, label: "Rara vez" },
      ],
      rules: [rules.required]      
    }
  },
];

const formularioEQ5D = [
  {
    pregunta: "Movilidad",
    model: "movilidad",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 1, label: "No tengo problemas para caminar" },
        { value: 2, label: "Tengo algunos problemas para caminar" },
        { value: 3, label: "Tengo que estar en la cama" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "Cuidado personal",
    model: "cuidado_personal",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 1, label: "No tengo problemas con el cuidado personal" },
        { value: 2, label: "Tengo algunos problemas para lavarme o vestirme solo" },
        { value: 3, label: "Soy incapaz de lavarme o vestirme solo" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta:
      "Actividades cotidianas (por ejemplo: trabajar, estudiar, hacer las tareas domésticas, actividades familiares o actividades durante el tiempo libre)",
    model: "actividades_cotid",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 1, label: "No tengo problemas para realizar mis actividades cotidianas" },
        { value: 2, label: "Tengo algunos problemas para realizar mis actividades cotidianas" },
        { value: 3, label: "Soy incapaz de realizar mis actividades cotidianas" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "Dolor/malestar",
    model: "dolor_malestar",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 1, label: "No tengo dolor ni malestar" },
        { value: 2, label: "Tengo moderado dolor o malestar" },
        { value: 3, label: "Tengo mucho dolor o malestar" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "Ansiedad/depresión",
    model: "ansiedad_depresion",
    tipo : {
      widget: "radio-group",
      opciones:[
        { value: 1, label: "No estoy ansioso/a ni deprimido/a" },
        { value: 2, label: "Estoy moderadamente ansioso/a o deprimido/a" },
        { value: 3, label: "Estoy muy ansioso/a o deprimido/a" },
      ],
      rules: [rules.required]      
    }
  },
  {
    pregunta: "Escala visual de salud",
    model: "estado_salud",
    tipo : {
      widget: "slider0a100"
    }
  }
];

const formularioCalidadSuenio = [
  {
    pregunta: "Cuantas horas estima haber dormido durante la noche, en promedio, en la última semana:",
    model: "horas_suenio",
    tipo : {
      widget: "text-field",
      type: "number",
      rules: [rules.required, rules.enteroPositivo]
    }
  },
  {
    pregunta: "Nota somnolencia durante sus actividades del día:",
    model: "somnolencia",
    tipo : tipoRadioSiNo
  },
  {
    pregunta: "Describa su calidad de sueño en la última semana:",
    model: "calidad_suenio",
    tipo : {
      widget: "text-field",
      type: "number",
      rules: [rules.required, rules.enteroPositivo, rules.menorIgual10]
    }
  }

];

const formularioEventosAdversos =  [
  {
    pregunta: "Somnolencia (es una tendencia al sueño)",
    model: "somnolencia",
    tipo : tipoAutoCompletar
  },
  {
    pregunta:
      "Mareos (es una sensación de inestabilidad física o pérdida del equilibro)",
    model: "mareos",
    tipo : tipoAutoCompletar
  },
  {
    pregunta:
      "Boca seca (es la sensación de que no hay suficiente saliva en su boca) ",
    model: "boca_seca",
    tipo : tipoAutoCompletar
  },
  {
    pregunta:
      "Ansiedad (sensación de temor, amenaza, inquietud, y/o tensión constante)",
    model: "ansiedad",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Náuseas (son las ganas de vomitar o hipersalivación o hipo)",
    model: "nauseas",
    tipo : tipoAutoCompletar
  },
  {
    pregunta:
      "Confusión (mayor dificultad para concentrarse, recordar y/o realizar tareas mentales)",
    model: "confusion",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Aumento del apetito (o de las ganas de comer)",
    model: "aumento_apetito",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Disminución del apetito (o de las ganas de comer)",
    model: "disminucion_apetito",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Diarrea (son evacuaciones líquidas, 3 o más veces por día)",
    model: "diarrea",
    tipo : tipoAutoCompletar
  },
  { 
    pregunta: "Mayor cansancio / fatiga", 
    model: "mayor_cansancio",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Euforia (es una sensación de optimismo exagerado)",
    model: "euforia",
    tipo : tipoAutoCompletar
  },
  { 
    pregunta: "Visión borrosa", 
    model: "vision_borrosa",
    tipo : tipoAutoCompletar
  },
  { 
    pregunta: "Dolor de cabeza", 
    model: "dolor_cabeza",
    tipo : tipoAutoCompletar
  },        
  {
    pregunta: "Paranoia (temor/desconfianza constante de que los demás están en su contra)",
    model: "paranoia",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Depresión (constante tristeza / desánimo)",
    model: "depresion",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Falta de coordinación motora",
    model: "falta_cordinacion_motora",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Palpitaciones o cambios en la frecuencia del pulso o en la tensión arterial",
    model: "palpitaciones",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Vómitos recurrentes (3 o más veces por día)",
    model: "vomitos",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Alucinaciones (ver, escuchar o sentir cosas que no existen)",
    model: "alucinaciones",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Delirio (pensamientos o habla incoherentes)",
    model: "delirio",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Excitación psicomotriz (gritos, llantos, o el habla descontrolados)",
    model: "exitacion_psicomotriz",
    tipo : tipoAutoCompletar
  },
  { 
    pregunta: "Dificultad en el habla", 
    model: "difucultad_habla",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Estreñimiento (no eliminar o evacuar materia fecal durante más de 1 día)",
    model: "estrenimiento",
    tipo : tipoAutoCompletar
  },
  { 
    pregunta: "Pensamientos de suicidio", 
    model: "pensamientos_suicidio",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Desmayos (pérdida transitoria del conocimiento)",
    model: "desmayos",
    tipo : tipoAutoCompletar
  },
  {
    pregunta: "Dolor abdominal (fuera de lo habitual)",
    model: "dolor_abdominal",
    tipo : tipoAutoCompletar
  },
];

const formularioPlanAnalgesico = [
  { pregunta: "Opioides fuertes", model: "opioides_fuertes" , tipo : { widget: 'checkbox'} },
  { pregunta: "Opioides débiles", model: "opioides_debiles" , tipo : { widget: 'checkbox'} },
  {
    pregunta: "Anti inflamatorios no esteroideos",
    model: "antiinflamatorios", tipo : { widget: 'checkbox'} 
  },
  { pregunta: "Paracetamol", model: "paracetamol", tipo : { widget: 'checkbox'}  },
  {
    pregunta: "Anticonvulsivantes (excepto gabapentina)",
    model: "anticonvulsivantes", tipo : { widget: 'checkbox'} 
  },
  { pregunta: "Antiarrítmicos", model: "antiarritmicos" , tipo : { widget: 'checkbox'} },
  {
    pregunta: "Antagonistas del receptor de N-metil-D-aspartato",
    model: "antagonistas_nmetildaspartato", tipo : { widget: 'checkbox'} 
  },
  { pregunta: "Corticoides", model: "corticoides", tipo : { widget: 'checkbox'}  },
  { pregunta: "Otros medicamentos", model: "otros_medicamentos" , tipo : { widget: 'textarea'} },
  { pregunta: "Otras intervenciones", model: "otras_intervenciones" , tipo : { widget: 'textarea'} }
];

const formularioSeguimientoIntervenciones = [
  {
    pregunta: "*Eventos adversos en laboratorio (hepatograma, otro)",
    model: "eventos_laboratorio"
  },
  {
    pregunta: "Eventos adversos graves (internación o riesgo de muerte)",
    model: "eventos_graves",
  },
  {
    pregunta: "Receta de cannabis medicinal",
    model: "receta"
  }
]

const formularioDN4 = [
  {
    pregunta: "Pregunta 1: ¿Tiene su dolor alguna de estas caracteristicas?",
    model: "caracteristica_dolor",
    options: [
      { label: "Quemazón", model: "quemazon" , tipo : tipoRadioSiNo},
      { label: "Sensación de frio doloroso", model: "frio_doloroso"  , tipo : tipoRadioSiNo},
      { label: "Descargas eléctricas", model: "descargas_electricas" , tipo : tipoRadioSiNo },
    ]
  },
  {
    pregunta: "Pregunta 2: ¿Tiene en la zona donde le duele alguno de estos síntomas?",
    model: "sintoma_zona_dolor",
    options:[
      { label: "Hormigueo", model: "hormigueo"  , tipo : tipoRadioSiNo},
      { label: "Pinchazos", model: "pinchazos"  , tipo : tipoRadioSiNo},
      { label: "Entumecimiento", model: "entumecimiento"  , tipo : tipoRadioSiNo},
      { label: "Escozor", model: "escozor" , tipo : tipoRadioSiNo },
    ]
  },
  {
    pregunta: "Pregunta 3: ¿Se evidencia en la exploración alguno de estos signos en la zona dolorosa?",
    model: "signo_zona_dolor",
    options: [
      { label: "Hipoestesia al tacto", model: "hipotesia_tacto"  , tipo : tipoRadioSiNo},
      { label: "Hipoestesia al pinchazo", model: "hipotesia_pinchazo" , tipo : tipoRadioSiNo },
    ]
  },
  {
    pregunta: "Pregunta 4: ¿¿El dolor se provoca o intensifica por?",
    model: "motivo_dolor",
    options: [{ label: "El roce", model: "roce"  , tipo : tipoRadioSiNo}]
  }
];

const opcionesReapertura = [
  { subcuestionario: "Dosis", model: "dosis" , opciones: formularioSeguimiento, visitas: [2,3,4,5,6]},
  { subcuestionario: "NRS", model: "nrs", opciones: formularioNRS, visitas: [1,2,3,4,5,6]},
  { subcuestionario: "Eventos Adversos", model: "eventos_adversos", opciones: formularioEventosAdversos, visitas: [2,3,4,5,6]},
  { subcuestionario: "Plan Analgesico", model: "plan_analgesico", opciones: formularioPlanAnalgesico, visitas: [1, 2,5]},
  { subcuestionario: "Seguimiento Intervenciones", model: "seguimiento_intervenciones", opciones: formularioSeguimientoIntervenciones, visitas: [2,3,4,5,6]},
  { subcuestionario: "DN4", model: "dn4", opciones: formularioDN4, visitas: [1]},
  { subcuestionario: "Calidad de Sueño", model: "calidad_suenio", opciones: formularioCalidadSuenio, visitas: [1,3,5]},
  { subcuestionario: "EQ5D", model: "eq5d", opciones: formularioEQ5D, visitas: [1,3,5]},
  { subcuestionario: "HADS", model: "hads", opciones: formularioHADS, visitas: [1,3,5]},
  { subcuestionario: "BPI", model: "bpi", opciones: formularioBPI, visitas: [1,2,3,4,5,6]}
]


module.exports = {
    opcionesReapertura
};