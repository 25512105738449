import axios from "./axios";

export const getReporte = (partialUrl) => {
  return axios
    .get(`/informes/${partialUrl}/`)
    .then((response) => response.data);
};

export const getEncuestasAtrasadas = () => {
  return axios
    .get("/informes/encuestas_atrasadas/")
    .then((response) => response.data);
};

export const getCorreccionesPendientes = () => {
  return axios.get("/informes/correcciones_pendientes/").then((response) => response.data);
};
