<template>
  <div class="ma-0 mb-5">
    <v-col cols="12" md="8" lg="7" class="pa-0 pa-md-3">
      <div class="text-subtitle-2 mb-2">
        Dolor promedio de la ultima semana (NRS){{
          esSeguimiento ? "" : ": require puntaje de 4 o +"
        }}
      </div>
      <div class="d-flex align-center">
        <div class="me-2 text--secondary">0</div>
        <v-slider
          v-model="formData"
          min="0"
          max="10"
          thumb-label="always"
          thumb-size="20"
          tick-size="4"
          class="mt-5"
          @change="validarNRS"
          :error-messages="dataError"
          @input="dataError = null"
        ></v-slider>
        <div class="ms-2 text--secondary">10</div>
      </div>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "FormularioNRS",
  props: {
    data: {
      type: Number,
      default: null,
      validator: (prop) => typeof prop === "number" || prop === null,
    },
    excludeBy: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    error: {
      type: Array,
    },
    esSeguimiento: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    validarNRS() {
      this.$emit("update:excludeBy", this.data < 4 ? "nrs" : null);
    },
  },
  computed: {
    formData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    dataError: {
      get() {
        return this.error;
      },
      set(value) {
        this.$emit("update:error", value);
      },
    },
  },
};
</script>