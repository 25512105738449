import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import AppView from "../views/AppView.vue";
import PrincipalView from "../views/PrincipalView.vue";
import PacientesView from "../views/PacientesView.vue";
import EncuestasView from "../views/EncuestasView.vue";
import RegistroCuestionarioView from "../views/RegistroCuestionarioView.vue";
import NuevaEncuestaView from "../views/NuevaEncuestaView.vue";
import ComunicacionView from "../views/ComunicacionView.vue";
import ContraseniaView from "../views/ContraseniaView.vue";
import CorreccionesView from "../views/CorreccionesView.vue";
import RegistroPacienteView from "../views/RegistroPacienteView.vue";
import SeguimientoPacienteView from "../views/SeguimientoPacienteView.vue";
import RegistroSeguimientoView from "../views/RegistroSeguimientoView.vue";
import CorrectorView from "../views/CorrectorView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/cambio_contrasenia",
    name: "cambioContrasenia",
    component: ContraseniaView,
  },
  {
    path: "/",
    component: AppView,
    children: [
      { path: "", name: "principal", component: PrincipalView },
      { path: "pacientes", name: "pacientes", component: PacientesView },
      {
        path: "pacientes/registro",
        name: "registroPaciente",
        component: RegistroPacienteView,
      },
      {
        path: "pacientes/seguimiento",
        name: "seguimientoPaciente",
        component: SeguimientoPacienteView,
      },
      {
        path: "pacientes/seguimiento/registro",
        name: "registroSeguimientoPaciente",
        component: RegistroSeguimientoView,
      },
      {
        path: "pacientes/:pidPaciente/encuestas",
        name: "encuestasPaciente",
        component: EncuestasView,
        props: true,
      },
      { path: "encuestas", name: "encuestas", component: EncuestasView },
      { path: "corrector", name: "corrector", component: CorrectorView },
      {
        path: "encuestas/create",
        name: "registrarCuestionario",
        component: RegistroCuestionarioView,
      },
      {
        path: "encuestas/inicio_encuesta/adulto",
        name: "nuevaEncuestaAdulto",
        component: NuevaEncuestaView,
        props: true,
      },
      {
        path: "encuestas/inicio_encuesta/pediatria",
        name: "nuevaEncuestaPediatria",
        component: NuevaEncuestaView,
        props: true,
      },
      {
        path: "encuestas/:idEncuesta/correcciones",
        name: "correccionesEncuesta",
        component: CorreccionesView,
        props: true,
      },
      {
        path: "comunicacion",
        name: "comunicacion",
        component: ComunicacionView,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
