<template>
  <div class="mx-3 mt-1 mb-5">
    <v-checkbox
      v-for="opcion in opciones"
      :key="opcion.model"
      v-model="formData[opcion.model]"
      :label="opcion.label"
      :error-messages="dataErrors[opcion.model]"
     
      @change="validarPlanAnalgesico"
      :disabled="disabled"
    ></v-checkbox>
    <v-checkbox
      v-if="esSeguimiento"
      v-model="formData.reduccion_dosis"
      label="Indica reducción de dosis (de 1 o más medicamentos)"
      color="success"
      class="font-weight-bold"
      :error-messages="dataErrors.reduccion_dosis"
      
      @change="validarPlanAnalgesico"
      :disabled="disabled"
    ></v-checkbox>
    <v-textarea
      v-model="formData.otros_medicamentos"
      label="Otros medicamentos"
      filled
      rows="2"
      hide-details="auto"
      class="mt-2"
      :error-messages="dataErrors.otros_medicamentos"
     
      @input="validarPlanAnalgesico"
      :disabled="disabled"
    ></v-textarea>
    <v-textarea
      v-model="formData.otras_intervenciones"
      label="Otras intervenciones"
      filled
      rows="2"
      hide-details="auto"
      class="mt-2"
      :error-messages="dataErrors.otras_intervenciones"
      
      @input="validarPlanAnalgesico"
      :disabled="disabled"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: "FormularioPlanAnalgesico",
  props: {
    data: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    esSeguimiento: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },   
    excludeBy: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    }, 
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      opciones: [
        { label: "Opioides fuertes", model: "opioides_fuertes" },
        { label: "Opioides débiles", model: "opioides_debiles" },
        {
          label: "Anti inflamatorios no esteroideos",
          model: "antiinflamatorios",
        },
        { label: "Paracetamol", model: "paracetamol" },
        {
          label: "Anticonvulsivantes (excepto gabapentina)",
          model: "anticonvulsivantes",
        },
        { label: "Antiarrítmicos", model: "antiarritmicos" },
        {
          label: "Antagonistas del receptor de N-metil-D-aspartato",
          model: "antagonistas_nmetildaspartato",
        },
        { label: "Corticoides", model: "corticoides" },
      ],
    };
  },
  methods: {
    validarPlanAnalgesico() {
      let isExcluded = false;

      let countFalse = 0;
      let countString = 0;
      const {otras_intervenciones, otros_medicamentos, ...booleanos} = this.data;
      for (const key in booleanos) {
        if ((key!= 'reduccion_dosis') && (booleanos[key] === false)) {countFalse += 1;}
      }
      if (otras_intervenciones === '') { countString += 1; }
      if (otros_medicamentos === '') { countString += 1; }
      console.log("data: ", this.data)
      console.log("countFalse: ", countFalse, "countString: ", countString)
      if (countFalse == 8 && countString == 2) {
        isExcluded = true;
      }
      this.$emit("update:excludeBy", isExcluded ? "planAnalgesico" : null);
    }
  },
  computed: {
    formData: {
      get() {
        return this.data || {};
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    dataErrors: {
      get() {
        return this.errors || {};
      },
      set(value) {
        this.$emit("update:errors", value);
      },
    },
  },
  created() {
    this.validarPlanAnalgesico();
  }
};
</script>