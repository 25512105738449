const pacientesAdmMed = [
  {
    value: "pid",
    text: "Pid",
    sortable: true,
  },
  {
    value: "dni",
    text: "DNI",
    sortable: true,
  },
  {
    value: "nombresyapellidos",
    text: "Paciente",
    sortable: true,
  },
  /* {
    value: "incluido",
    text: "Participa",
    sortable: false,
  }, */
  {
    value: "fecha_inicio",
    text: "Fecha de inicio",
    sortable: true,
    align: "center",
  },
  {
    value: "excluido",
    text: "Excluído durante el estudio",
    sortable: false,
    align: "center",
  },
  {
    value: "telefono",
    text: "Teléfono",
    sortable: false,
  },
  {
    value: "estudio_piloto",
    text: "DOLOCANN",
    sortable: false,
    align: "center",
  },
/*   {
     value: "mas_acciones",
     align: "center",
     text: "Acciones",
     sortable: false,
  }, */
  {
    value: "acciones",
    align: "center",
    text: "",
    sortable: false,
  },
];

const pacientesEst = [
  {
    value: "pid",
    text: "Pid",
    sortable: true,
  },
  {
    value: "fecha_inicio",
    text: "Fecha de inicio",
    sortable: false,
    align: "center",
  },
  {
    value: "edad_basal",
    text: "Edad basal",
    sortable: false,
    align: "center",
  },
  {
    value: "datos_cuestionarios.cantidad_cuestionarios",
    text: "Cuestionarios",
    align: "center",
    sortable: false,
  },
  {
    value: "fecha_ultimo_cuestionario",
    text: "Fecha ultimo cuest.",
    align: "center",
    sortable: false,
  },
  {
    value: "excluido",
    text: "Excluido durante el estudio",
    sortable: false,
    align: "center",
  },
  {
    value: "acciones",
    align: "center",
    text: "",
    sortable: false,
  },
];

const encuestas = [
  {
    value: "paciente",
    text: "Pid Paciente",
    sortable: true,
  },
  {
    value: "visita",
    text: "N° visita",
    align: "center",
    sortable: true,
  },
  {
    value: "dia_seguimiento",
    text: "Día de seguimiento",
    align: "center",
    sortable: true,
  },
  {
    value: "created_at",
    text: "Fecha de visita",
    align: "center",
    sortable: true,
  },
  {
    value: "completa",
    text: "¿Completa?",
    align: "center",
    sortable: true,
  },
  {
    value: "fecha",
    text: "Fecha de registro",
    align: "center",
    sortable: true,
  },
  {
    value: "nrs",
    text: "NRS",
    align: "center",
    sortable: false,
  },
  {
    value: "dosis",
    text: "Dosis (24hs)",
    align: "center",
    sortable: false,
  },
  {
    value: "presenta_ea",
    text: "Presenta EA",
    align: "center",
    sortable: false,
  },
  {
    value: "bpi",
    text: "BPI",
    align: "center",
    sortable: false,
  },
  {
    value: "hads",
    text: "HADS",
    align: "center",
    sortable: false,
  },
  {
    value: "eq5d",
    text: "EQ5D",
    align: "center",
    sortable: false,
  },
  {
    value: "calidad_suenio",
    text: "Calidad del sueño",
    align: "center",
    sortable: false,
  },
  {
    value: "reacciones_adversas",
    text: "Eventos adversos",
    align: "center",
    sortable: false,
  },
  {
    value: "plan_analgesico",
    text: "Plan Analgésico",
    align: "center",
    sortable: false,
  },
  {
    value: "intervenciones_medicas",
    text: "Intervenciones médicas",
    align: "center",
    sortable: false,
  },
  {
    value: "dn4",
    text: "DN4",
    align: "center",
    sortable: false,
  }
];

const encuestasCorregir = [
  {
    value: "paciente",
    text: "Pid Paciente",
    sortable: true,
  },
  {
    value: "nro_cuestionario",
    text: "N° encuesta",
    align: "center",
    sortable: true,
  },
  {
    value: "mes_seguimiento",
    text: "Mes de seguimiento",
    align: "center",
    sortable: true,
  },
  {
    value: "fecha",
    text: "Fecha",
    align: "center",
    sortable: true,
  },
  {
    value: "categoria",
    text: "Categoria",
    align: "center",
  },
  {
    value: "calidad_vida",
    text: "Crisis epilepticas (ult. 2 semanas)",
    align: "center",
    sortable: true,
  },
  {
    value: "reaccion_adversa.existen_sintomas",
    text: "Presenta EA",
    align: "center",
    sortable: false,
  },
  {
    value: "medicacion_concomitante",
    text: "Medic. Concomitante",
    align: "center",
    sortable: false,
  },
];

const correcciones = [
  {
    value: "fecha_apertura",
    text: "Fecha de apertura",
  },
  {
    value: "subcuestionario",
    text: "Subcuestionario",
    align: "center",
  },
  {
    value: "campo",
    text: "Campo a corregir",
  },
  {
    value: "comentario",
    text: "Comentario",
  },
  {
    value: "acciones",
    text: "",
  },
];

const headersEncuestasAtrasadas = [
  {
    value: "pid", 
    text: "Pid Paciente", 
    sortable: true
  },
  {
    value: "nombre", 
    text: "Nombres, Apellidos", 
    sortable: true
  },
  {
    value: "visita",
    text: "N° visita", 
    align: "center", 
    sortable: true},
  {
    value: "created_at",
    text: "Fecha de visita",
    align: "center",
    sortable: true
  },
  {
    value: "previos",
    text: "Reenvíos previos",
    align: "center",
    sortable: true
  },
  {
    value: "acciones",
    text: "",
  }
]

const headersEncuestasCorregir = [
  {
    value: "fecha_apertura",
    text: "Fecha de apertura",
    sortable: true, 
    align: "center"
  },
  {
    value: "paciente",
    text: "Pid Paciente",
    sortable: true,
  },
  {
    value: "visita",
    text: "N° visita",
    align: "center",
    sortable: true,
  },
  {
    value: "dia_seguimiento",
    text: "Día de seguimiento",
    align: "center",
    sortable: true,
  },
  {
    value: "created_at",
    text: "Fecha de visita",
    align: "center",
    sortable: true,
  },
  {
    value: "fecha",
    text: "Fecha de registro",
    align: "center",
    sortable: true,
  },
  {
    value: "nrs",
    text: "NRS",
    align: "center",
    sortable: false,
  },
  {
    value: "dosis",
    text: "Dosis (24hs)",
    align: "center",
    sortable: false,
  },
  {
    value: "presenta_ea",
    text: "Presenta EA",
    align: "center",
    sortable: false,
  },
  {
    value: "bpi",
    text: "BPI",
    align: "center",
    sortable: false,
  },
  {
    value: "hads",
    text: "HADS",
    align: "center",
    sortable: false,
  },
  {
    value: "eq5d",
    text: "EQ5D",
    align: "center",
    sortable: false,
  },
  {
    value: "calidad_suenio",
    text: "Calidad del sueño",
    align: "center",
    sortable: false,
  },
  {
    value: "reacciones_adversas",
    text: "Eventos adversos",
    align: "center",
    sortable: false,
  },
  {
    value: "plan_analgesico",
    text: "Plan Analgésico",
    align: "center",
    sortable: false,
  },
  {
    value: "intervenciones_medicas",
    text: "Intervenciones médicas",
    align: "center",
    sortable: false,
  },
  {
    value: "dn4",
    text: "DN4",
    align: "center",
    sortable: false,
  },
  {
    value: "acciones",
    text: "",
    align: "center",
    sortable: false,
  
  }
];


module.exports = {
  pacientesAdmMed,
  pacientesEst,
  encuestas,
  encuestasCorregir,
  correcciones,
  headersEncuestasAtrasadas,
  headersEncuestasCorregir
};
