import axios from "./axios";

export const getListPacientes = ({ limit = 15, offset = 0 }) => {
  return axios
    .get(`/pacientes/?limit=${limit}&offset=${offset}`)
    .then((response) => response.data);
};

export const getDetallePaciente = (id) => {
  return axios.get(`/pacientes/${id}/`).then((response) => response.data);
};

export const getPacienteDNI = (dni) => {
  return axios.get(`/pacientes/dni/${dni}/`).then((response) => response.data);
};

export const getPacienteToken = (id, token) => {
  return axios
    .get(`/pacientes/${id}/public/?token=${token}`)
    .then((response) => response.data);
};

export const getEstudioAleatorio = () => {
  return axios
    .get(`/pacientes/estudio-piloto-aleatorio/`)
    .then((response) => response.data);
};

export const crearPaciente = (data) => {
  return axios.post("/pacientes/", data).then((response) => response.data);
};

export const modificarPacienteId = (data, id) => {
  return axios.put(`/pacientes/${id}/`, data).then((response) => response.data);
};

export const eliminarPacienteId = (id) => {
  return axios.delete(`/pacientes/${id}/`).then((response) => response.data);
};

export const getUrlUltimoCuestionario= (id) => {
  return axios
    .get(`/pacientes/${id}/url-ultimo-cuestionario/`)
    .then((response) => response.data);
};

export const getReenvioCuestionario= (id) => {
  return axios
    .get(`/pacientes/${id}/reenvio_cuestionario/`)
    .then((response) => response.data);
};