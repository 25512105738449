<template>
  <div class="mx-0 mt-1 mb-7">
    <p class="my-0 subtitle-2">
      1.- Notó síntomas NUEVOS en las últimas 2 semanas:
    </p>
    <v-radio-group
      v-model="existen_sintomas"
      class="my-0 pb-3 pb-md-3"
      hide-details="auto"
      :rules="[rules.notNull]"
      row
      @change="cambioExistencia"
    >
      <v-radio label="Si" :value="true" :disabled="disabled"></v-radio>
      <v-radio label="No" :value="false" :disabled="disabled"></v-radio>
    </v-radio-group>
    <div v-if="existen_sintomas">
      <p class="my-0 subtitle-2">
        2- Si su respuesta fue SI, complete el siguiente cuestionario
        (Seleccione solamente el o los síntomas en la situación que
        corresponde):
      </p>
      <v-divider></v-divider>
      <v-row class="ma-0" v-for="pregunta in preguntas" :key="pregunta.model">
        <v-col cols="12" sm="7" md="7" lg="6" class="py-0">
          <p class="mb-0 mt-2 subtitle-1">
            {{ pregunta.label }}
          </p>
        </v-col>
        <v-col cols="12" sm="5" md="5" lg="3">
          <v-autocomplete
            v-model="formData[pregunta.model]"
            :items="opciones"
            item-text="label"
            item-value="value"
            placeholder="Seleccione uno..."
            outlined
            dense
            hide-details="auto"
            clearable
            @change="verificarRespuestas"
            :disabled="disabled"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <p class="mt-3 red--text subtitle-2" v-if="showError">
        Si seleccionó la opción "Si", debe señalar al menos un evento adverso.
        Caso contrario marque la opcion "No".
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormularioEventosAdversosReadonly",
  props: {
    data: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    existenEventos: {
      type: Boolean || null,
      // default: null,
      // validator: (prop) => typeof prop === "boolean" || prop === null,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      existen_sintomas: null,
      showError: false,
      rules: {
        required: (v) => !!v || "Campo obligatorio",
        notNull: (v) => v !== null || "Campo obligatorio",
        enteroPositivo: (v) =>
          (Number.isInteger(+v) && +v >= 0) ||
          "Debe ser un valor entero mayor o igual que cero.",
      },
      opciones: [
        {
          value: 1,
          label: "LEVE (No interfieren con mis actividades diarias)",
        },
        {
          value: 2,
          label: "MODERADO (Pueden interferir con las actividades diarias)",
        },
        {
          value: 3,
          label: "SEVERO (Interrumpen las actividades diarias)",
        },
      ],
      preguntasBase: [
        {
          label: "Somnolencia (es una tendencia al sueño)",
          model: "somnolencia",
        },
        {
          label:
            "Mareos (es una sensación de inestabilidad física o pérdida del equilibro)",
          model: "mareos",
        },
        {
          label:
            "Boca seca (es la sensación de que no hay suficiente saliva en su boca) ",
          model: "boca_seca",
        },
        {
          label:
            "Ansiedad (sensación de temor, amenaza, inquietud, y/o tensión constante)",
          model: "ansiedad",
        },
        {
          label: "Náuseas (son las ganas de vomitar o hipersalivación o hipo)",
          model: "nauseas",
        },
        {
          label:
            "Confusión (mayor dificultad para concentrarse, recordar y/o realizar tareas mentales)",
          model: "confusion",
        },
        {
          label: "Aumento del apetito (o de las ganas de comer)",
          model: "aumento_apetito",
        },
        {
          label: "Disminución del apetito (o de las ganas de comer)",
          model: "disminucion_apetito",
        },
        {
          label: "Diarrea (son evacuaciones líquidas, 3 o más veces por día)",
          model: "diarrea",
        },
        { label: "Mayor cansancio / fatiga", model: "mayor_cansancio" },
        {
          label: "Euforia (es una sensación de optimismo exagerado)",
          model: "euforia",
        },
        { label: "Visión borrosa", model: "vision_borrosa" },
        { label: "Dolor de cabeza", model: "dolor_cabeza" },
        
        {
          label:
            "Paranoia (temor/desconfianza constante de que los demás están en su contra)",
          model: "paranoia",
        },
        {
          label: "Depresión (constante tristeza / desánimo)",
          model: "depresion",
        },
        {
          label: "Falta de coordinación motora",
          model: "falta_cordinacion_motora",
        },
        {
          label:
            "Palpitaciones o cambios en la frecuencia del pulso o en la tensión arterial",
          model: "palpitaciones",
        },
        {
          label: "Vómitos recurrentes (3 o más veces por día)",
          model: "vomitos",
        },
        {
          label: "Alucinaciones (ver, escuchar o sentir cosas que no existen)",
          model: "alucinaciones",
        },
        {
          label: "Delirio (pensamientos o habla incoherentes)",
          model: "delirio",
        },
        {
          label:
            "Excitación psicomotriz (gritos, llantos, o el habla descontrolados)",
          model: "exitacion_psicomotriz",
        },
        { label: "Dificultad en el habla", model: "difucultad_habla" },
        {
          label:
            "Estreñimiento (no eliminar o evacuar materia fecal durante más de 1 día)",
          model: "estrenimiento",
        },
        { label: "Pensamientos de suicidio", model: "pensamientos_suicidio" },
        {
          label: "Desmayos (pérdida transitoria del conocimiento)",
          model: "desmayos",
        },
        {
          label: "Dolor abdominal (fuera de lo habitual)",
          model: "dolor_abdominal",
        },
      ],
    };
  },
  methods: {
    cambioExistencia() {
      this.formData = {};
      if (this.existen_sintomas) {
        this.eventosAdversos = true;
        this.showError = true;
      } else {
        this.eventosAdversos = false;
        this.showError = false;
      }
    },
    verificarRespuestas() {
      Object.keys(this.formData).forEach((key) => {
        if (this.formData[key] === null) delete this.formData[key];
      });
      if (Object.keys(this.formData).length > 0) this.showError = false;
      else this.showError = true;
    },
  },
  created() {
    this.existen_sintomas = this.formData.existen_sintomas;
    this.preguntas = this.preguntasBase.filter((pregunta) =>
      this.formData[pregunta.model] != null
    );
  },
  computed: {
    formData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    dataErrors: {
      get() {
        return this.errors || {};
      },
      set(value) {
        this.$emit("update:errors", value);
      },
    },
    eventosAdversos: {
      get() {
        return this.existenEventos;
      },
      set(value) {
        this.$emit("update:existenEventos", value);
      },
    },
  },
};
</script>