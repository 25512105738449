import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use((config) => {
  const usuario = JSON.parse(window.localStorage.getItem('usuario'))
  if (usuario) {
    config.headers.Authorization = config.headers.Authorization = `Token ${usuario.token}`
  }
  return config
})

export default instance
