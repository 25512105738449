<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-card width="100%">
      <h2 class="primary--text text-center py-6">
        CANNABIS MEDICINAL - EPILEPSIA REFRACTARIA - PROGRAMA TERAPÉUTICO
        ESPECIAL
      </h2>
      <div class="px-4 text-center">
        Esta encuesta forma parte de un estudio de farmacovigilancia coordinado
        por el <b>Ministerio de Salud de la provincia de Jujuy</b> para valorar
        la eficacia y la seguridad de la terapia con Cannava CBD10. Esta
        información será tratada con la máxima confidencialidad, sin revelar sus
        datos identificatorios, y sus resultados serán usados con fines de
        investigación sanitaria.
      </div>
      <v-card-actions
        class="d-flex flex-column flex-sm-row justify-center align-center"
      >
        <div>
          <v-btn class="ma-2" outlined color="primary" @click="irAPacientes">
            <v-icon>mdi-clipboard-account-outline</v-icon>
            Pacientes
          </v-btn>
        </div>
        <div>
          <v-btn class="ma-2" outlined color="primary" @click="irAEncuestas">
            <v-icon>mdi-plus</v-icon>
            Agregar encuesta
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "PrincipalView",
  props: {
    setItemSeleccionado: {
      type: Function,
      required: true,
    },
  },
  methods: {
    irAEncuestas() {
      this.$router.push({ name: "inicioEncuesta" });
    },
    irAPacientes() {
      this.$router.push({ name: "pacientes" });
    },
  },
  created() {
    this.setItemSeleccionado("principal");
  },
};
</script>