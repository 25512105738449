import axios from "./axios";

/* export const getEncuestasCorrecciones = ({ limit = 15, offset = 0 }) => { */
export const getEncuestasCorrecciones = ( ) => {
  return axios
    /* .get(`/encuestas/?limit=${limit}&offset=${offset}&correcciones=true`) */
    .get(`/cuestionarios/correcciones/`)
    .then((response) => response.data);
};

export const getEncuestaCorreccion = (id) => {
  return axios
    /* .get(`/encuestas/${id}/?correcciones=true`) */
    .get(`/cuestionarios/correcciones/${id}`)
    .then((res) => res.data);
};

export const getCorreccionesEncuesta = (id) => {
  return axios
    .get(`/encuestas/${id}/correcciones/`)
    .then((response) => response.data);
};

/* export const crearCorreccion = (data, idEncuesta) => { */
export const crearCorreccion = (data) => {
  return axios
    /* .post(`/encuestas/${idEncuesta}/correcciones/`, data) */
    .post(`/cuestionarios/correcciones/`, data)
    .then((res) => res.data);
};

export const cerrarCorreccion = (data, id) => {
  return axios
    /* .put(`/encuestas/correcciones/${id}/cierre/`, data) */
    .put(`/cuestionarios/correcciones/${id}/`, data)
    .then((res) => res.data);
};
