var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 pa-md-3",attrs:{"fluid":""}},[_c('v-container',{staticClass:"pa-0 mb-2",attrs:{"fluid":""}},[_c('v-btn',{staticClass:"mx-2",attrs:{"dark":"","color":"orange darken-4"},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Volver ")],1)],1),_c('v-card',{staticClass:"card-table me-md-5"},[_c('v-container',{staticClass:"pa-0 mb-2 pa-4",attrs:{"fluid":""}},[_c('p',{staticClass:"font-weight-bold text-h6 text-md-h5 text--secondary text-center"},[_vm._v(" Seguimiento de pacientes del programa DOLOCANN ")]),(_vm.paciente)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-card',{attrs:{"width":"344"}},[_c('v-card-text',[(!_vm.paciente.incluido)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" El paciente no fué incluido en el estudio ")]):_vm._e(),(
                _vm.paciente.datos_cuestionarios &&
                _vm.paciente.datos_cuestionarios.excluido
              )?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" El paciente fué excluido durante el estudio ")]):_vm._e(),(
                _vm.paciente.datos_cuestionarios &&
                !_vm.paciente.datos_cuestionarios.excluido &&
                !_vm.paciente.datos_cuestionarios.ultimo_cuestionario_completo
              )?_c('v-alert',{attrs:{"type":"error","color":"orange","dark":""}},[_vm._v(" El paciente no completó el ultimo cuestionario ")]):_vm._e(),(
                _vm.paciente.datos_cuestionarios &&
                _vm.paciente.datos_cuestionarios.estudio_finalizado
              )?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" El paciente ya finalizó el estudio ")]):_vm._e(),_c('div',{staticClass:"subtitle-2"},[_vm._v("Paciente:")]),_c('div',{staticClass:"text--primary subtitle-2"},[_vm._v(" "+_vm._s(_vm.paciente.apellidos)+" "+_vm._s(_vm.paciente.nombres)+" ")]),_c('div',{staticClass:"subtitle-2 mt-2"},[_vm._v("DNI:")]),_c('div',{staticClass:"text--primary subtitle-2"},[_vm._v(_vm._s(_vm.paciente.dni))]),_c('div',{staticClass:"subtitle-2 mt-2"},[_vm._v("PID:")]),_c('div',{staticClass:"text--primary subtitle-2"},[_vm._v(_vm._s(_vm.paciente.pid))]),_c('div',{staticClass:"subtitle-2 mt-2"},[_vm._v("Género:")]),_c('div',{staticClass:"text--primary subtitle-2"},[_vm._v(" "+_vm._s({ M: "Masculino", F: "Femenino" }[_vm.paciente.genero])+" ")]),_c('div',{staticClass:"subtitle-2 mt-2"},[_vm._v("Estudio:")]),_c('div',{staticClass:"text--primary subtitle-2"},[_vm._v(" DOLOCANN "+_vm._s(_vm.paciente.estudio_piloto)+" ")]),(
                _vm.paciente.datos_cuestionarios &&
                !_vm.paciente.datos_cuestionarios.excluido &&
                !_vm.paciente.datos_cuestionarios.estudio_finalizado
              )?_c('div',{staticClass:"subtitle-2 mt-2"},[_vm._v(" Visitas registradas: ")]):_vm._e(),(
                _vm.paciente.datos_cuestionarios &&
                !_vm.paciente.datos_cuestionarios.excluido &&
                !_vm.paciente.datos_cuestionarios.estudio_finalizado
              )?_c('div',{staticClass:"text--primary subtitle-2"},[_vm._v(" "+_vm._s(_vm.paciente.datos_cuestionarios.ultima_visita)+" ")]):_vm._e(),_c('v-divider',{staticClass:"mt-3"}),(
                _vm.paciente.incluido &&
                !_vm.paciente.datos_cuestionarios.excluido &&
                !_vm.paciente.datos_cuestionarios.estudio_finalizado &&
                _vm.paciente.datos_cuestionarios.ultimo_cuestionario_completo
              )?_c('div',{staticClass:"mt-3 text-center"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.registrarVisita}},[_vm._v("Registrar visita")])],1):_vm._e()],1)],1),_c('div',{staticClass:"text-center mt-5"},[_c('v-btn',{attrs:{"rounded":"","color":"purple","dark":""},on:{"click":_vm.nuevaBusqueda}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-reload ")]),_vm._v(" Nueva búsqueda ")],1)],1)],1):_c('div',{staticClass:"pt-4 d-flex justify-center"},[_c('v-form',{ref:"form",staticClass:"text-center d-flex flex-column flex-md-row align-center align-md-start",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{attrs:{"label":"DNI*","type":"number","rules":[_vm.rules.required],"outlined":"","dense":"","hide-details":"auto","error-messages":_vm.error},on:{"input":_vm.onInputDNI},model:{value:(_vm.dni),callback:function ($$v) {_vm.dni=_vm._n($$v)},expression:"dni"}}),_c('div',{staticClass:"ml-0 ml-md-3 mt-3 mt-md-0 text-center"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.searchDNI}},[_vm._v("Buscar")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }