<template>
  <div class="ma-0 mb-7">
    <p class="subtitle-2 text--secondary">
      Este cuestionario ha sido diseñado para ayudarnos a saber cómo se siente
      usted. Lea cada frase y marque la respuesta que más se ajusta a cómo se
      sintió durante la semana pasada. No piense mucho las respuestas. Lo más
      seguro es que responde deprisa sus respuestas se ajustarán mucho más a
      cómo realmente se sintió.
    </p>
    <v-row>
      <v-col cols="12" md="6">
        <div v-for="pregunta in primerColumna" :key="pregunta.model">
          <div class="text-subtitle-2 mb-2 mt-5">{{ pregunta.pregunta }}</div>
          <v-radio-group
            v-model="formData[pregunta.model]"
            class="my-0"
            hide-details="auto"
            :rules="[rules.required]"
            @change="validarRespuestas"
          >
            <v-radio
              v-for="option in pregunta.options"
              :key="option.label"
              :label="option.label"
              :value="option.value"
              :disabled="disabled"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div v-for="pregunta in segundaColumna" :key="pregunta.model">
          <div class="text-subtitle-2 mb-2 mt-5">{{ pregunta.pregunta }}</div>
          <v-radio-group
            v-model="formData[pregunta.model]"
            class="my-0"
            hide-details="auto"
            :rules="[rules.required]"
            @change="validarRespuestas"
          >
            <v-radio
              v-for="option in pregunta.options"
              :key="option.label"
              :label="option.label"
              :value="option.value"
              :disabled="disabled"
            ></v-radio>
          </v-radio-group></div
      ></v-col>
    </v-row>
    <!-- <v-btn @click="rellenar" color="info"> Rellenar Test </v-btn> -->
  </div>
</template>

<script>
const initialData = {
  tenso_nervioso: null,
  disfrute: null,
  miedo: null,
  risas: null,
  preocupaciones: null,
  alegria: null,
  relajacion: null,
  lentitud: null,
  sensacion_mariposas: null,
  perdida_interes: null,
  inquieto: null,
  optimismo: null,
  panico: null,
  diversion: null,
};

export default {
  name: "FormularioHADS",
  props: {
    data: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    excludeBy: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      rules: {
        required: (v) => v !== null || "Campo obligatorio",
      },
      preguntas: [
        {
          pregunta: "1. Me siento tenso o nervioso",
          model: "tenso_nervioso",
          options: [
            { value: 3, label: "Todos los dias" },
            { value: 2, label: "Muchas veces" },
            { value: 1, label: "A veces" },
            { value: 0, label: "Nunca" },
          ],
        },
        {
          pregunta: "2. Todavía disfruto con lo que antes me gustaba",
          model: "disfrute",
          options: [
            { value: 0, label: "Como siempre" },
            { value: 1, label: "No lo bastante" },
            { value: 2, label: "Sólo un poco" },
            { value: 3, label: "Nada" },
          ],
        },
        {
          pregunta:
            "3. Tengo una sensación de miedo, como si algo horrible me fuera a suceder",
          model: "miedo",
          options: [
            { value: 3, label: "Definitivamente y es muy fuerte" },
            { value: 2, label: "Si, pero no es muy fuerte" },
            { value: 1, label: "Un poco, pero no me preocupa" },
            { value: 0, label: "Nada" },
          ],
        },
        {
          pregunta: "4. Puedo reírme y ver el lado divertido a las cosas",
          model: "risas",
          options: [
            { value: 0, label: "Al igual que siempre lo hice" },
            { value: 1, label: "No tanto ahora" },
            { value: 2, label: "Casi nunca" },
            { value: 3, label: "Nunca" },
          ],
        },
        {
          pregunta: "5. Tengo mi mente llena de preocupaciones",
          model: "preocupaciones",
          options: [
            { value: 3, label: "La mayoría de las veces" },
            { value: 2, label: "Con bastante frecuencia" },
            { value: 1, label: "A Veces, aunque no muy a menudo" },
            { value: 0, label: "Sólo en ocasiones" },
          ],
        },
        {
          pregunta: "6. Me siento alegre",
          model: "alegria",
          options: [
            { value: 3, label: "Nunca" },
            { value: 2, label: "No muy a menudo" },
            { value: 1, label: "A Veces" },
            { value: 0, label: "Casi siempre" },
          ],
        },
        {
          pregunta:
            "7. Puedo estar sentado confortablemente y sentirme relajado",
          model: "relajacion",
          options: [
            { value: 0, label: "Siempre" },
            { value: 1, label: "Por lo general" },
            { value: 2, label: "No muy a menudo" },
            { value: 3, label: "Nunca" },
          ],
        },
        {
          pregunta: "8. Me siento como si cada día estuviera más lento",
          model: "lentitud",
          options: [
            { value: 3, label: "Por lo general, en todo momento" },
            { value: 2, label: "Muy a menudo" },
            { value: 1, label: "A veces" },
            { value: 0, label: "Nunca" },
          ],
        },
        {
          pregunta:
            "9. Tengo una sensación extraña, como si tuviera mariposas en el estómago",
          model: "sensacion_mariposas",
          options: [
            { value: 0, label: "Nunca" },
            { value: 1, label: "En ciertas ocasiones" },
            { value: 2, label: "Con bastante frecuencia" },
            { value: 3, label: "Muy a menudo" },
          ],
        },
        {
          pregunta: "10. He perdido interés en mi aspecto personal",
          model: "perdida_interes",
          options: [
            { value: 3, label: "Totalmente" },
            { value: 2, label: "No me preocupo tanto como debiera" },
            { value: 1, label: "Podría tener un poco más de cuidado" },
            { value: 0, label: "Me preocupo al igual que siempre" },
          ],
        },
        {
          pregunta:
            "11. Me siento inquieto, como si no pudiera parar de moverme",
          model: "inquieto",
          options: [
            { value: 3, label: "Mucho" },
            { value: 2, label: "Bastante" },
            { value: 1, label: "No mucho" },
            { value: 0, label: "Nada" },
          ],
        },
        {
          pregunta: "12. Me siento optimista respecto al futuro",
          model: "optimismo",
          options: [
            { value: 0, label: "Igual que siempre" },
            { value: 1, label: "Menos de lo que acostumbraba" },
            { value: 2, label: "Mucho menos de lo que acostumbraba" },
            { value: 3, label: "Nada" },
          ],
        },
        {
          pregunta: "13. Me asaltan sentimientos repentinos de pánico",
          model: "panico",
          options: [
            { value: 3, label: "Muy frecuentemente" },
            { value: 2, label: "Bastante a menudo" },
            { value: 1, label: "No muy a menudo" },
            { value: 0, label: "Rara vez" },
          ],
        },
        {
          pregunta:
            "14. Me divierto con un buen libro, la radio, o un programa de televisión",
          model: "diversion",
          options: [
            { value: 0, label: "A menudo" },
            { value: 1, label: "A veces" },
            { value: 2, label: "No muy a menudo" },
            { value: 3, label: "Rara vez" },
          ],
        },
      ],
      preguntasCaracteristicasDolor: [
        { label: "Quemazón", model: "quemazon" },
        { label: "Sensación de frio doloroso", model: "frio_doloroso" },
        { label: "Descargas eléctricas", model: "descargas_electricas" },
      ],
      preguntasSintomaZonaDolor: [
        { label: "Hormigueo", model: "hormigueo" },
        { label: "Pinchazos", model: "pinchazos" },
        { label: "Entumecimiento", model: "entumecimiento" },
        { label: "Escozor", model: "escozor" },
      ],
      preguntasSignoZonaDolor: [
        { label: "Hipoestesia al tacto", model: "hipotesia_tacto" },
        { label: "Hipoestesia al pinchazo", model: "hipotesia_pinchazo" },
      ],
      preguntasMotivoDolor: [{ label: "El roce", model: "roce" }],
    };
  },
  methods: {
    rellenar() {
      this.formData = {
      tenso_nervioso: 0,
      disfrute: 0,
      miedo: 0,
      risas: 0,
      preocupaciones: 0,
      alegria: 0,
      relajacion: 0,
      lentitud: 3,
      sensacion_mariposas: 3,
      perdida_interes: 3,
      inquieto: 3,
      optimismo: 3,
      panico: 3,
      diversion: null
      }
    },
    validarRespuestas() {
      let existNull = false;
      for (const key in this.data) {
        if (this.data[key] === null) {
          existNull = true;
          break;
        }
      }
      if (!existNull) {
        let puntos = 0;
        for (const key in this.data) {
          puntos += this.data[key];
        }
        this.$emit("update:excludeBy", puntos >= 20 ? "hads" : null);
      }
    },
  },
  computed: {
    formData: {
      get() {
        return this.data || initialData;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    primerColumna() {
      return this.preguntas.filter((pregunta, i) => i < 7);
    },
    segundaColumna() {
      return this.preguntas.filter((pregunta, i) => i > 6);
    },
  },
};
</script>