<template>
  <v-app>
    <nav-bar
      :abrirDrawer.sync="abrirDrawer"
      :mostrarBotonDrawer="mostrarDrawer"
      :usuario="usuario"
      :ocultarBotonUsuario="ocultarBotonUsuario"
    />
    <v-navigation-drawer
      v-if="mostrarDrawer"
      v-model="abrirDrawer"
      :temporary="isMobile"
      :permanent="!isMobile"
      app
      clipped
      color="blue-grey lighten-5"
    >
      <v-list dense nav>
        <v-list-item-group v-model="itemSeleccionado" color="primary">
          <v-list-item
            link
            @click="navegar('principal')"
            :disabled="itemSeleccionado == 0"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="navegar('pacientes')"
            :disabled="itemSeleccionado == 1"
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pacientes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="navegar('encuestas')"
            :disabled="itemSeleccionado == 2"
          >
            <v-list-item-icon>
              <v-icon>mdi-format-list-checkbox</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Encuestas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="navegar('comunicacion')"
            :disabled="itemSeleccionado == 3"
          >
            <v-list-item-icon>
              <v-icon>mdi-whatsapp</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Comunicacion</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <div class="d-flex pt-2 px-2 px-md-5" style="height: 100%">
        <router-view
          :setSnackbar="setSnackbar"
          :setLoaderCircular="setLoaderCircular"
          :setLoaderLineal="setLoaderLineal"
          :manejarErrorAxios="manejarErrorAxios"
          :usuario="usuario"
          :setItemSeleccionado="setItemSeleccionado"
          :setUrlPublica="setUrlPublica"
        ></router-view>
      </div>
      <v-snackbar
        v-model="snackbar.activo"
        :color="snackbar.color"
        centered
        timeout="3000"
      >
        {{ snackbar.texto }}
      </v-snackbar>

      <!-- Loading circular -->
      <v-overlay :value="loaderCircular">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <!-- Dialog loading lineal -->
      <v-dialog v-model="loaderLineal" persistent width="300">
        <v-card color="secondary" dark>
          <v-card-text>
            <div class="my-2">Por favor espere...</div>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/Navbar.vue";

export default {
  name: "AppView",
  data() {
    return {
      usuario: {},
      ocultarBotonUsuario: false,
      mostrarDrawer: false,
      abrirDrawer: false,
      itemSeleccionado: null,
      snackbar: {
        activo: false,
        color: "success",
        texto: "Operación exitosa.",
      },
      loaderCircular: false,
      loaderLineal: false,
    };
  },
  components: {
    NavBar,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    navegar(nombreRuta, numeroItem) {
      if (this.$router.history.current.name !== nombreRuta)
        this.$router.push({ name: nombreRuta });
      else this.itemSeleccionado = numeroItem;
    },
    setSnackbar(value) {
      this.snackbar = value;
    },
    setLoaderCircular(value) {
      this.loaderCircular = value;
    },
    setLoaderLineal(value) {
      this.loaderLineal = value;
    },
    manejarErrorAxios(e) {
      let textSnackbar = "";
      if (e.response) {
        switch (e.response.status) {
          case 401:
          case 403:
            textSnackbar = "No esta autorizado para realizar esta operación.";
            window.localStorage.removeItem("usuario");
            setTimeout(() => this.$router.replace("/login"), 2000);
            break;
          case 400:
            textSnackbar = "Hay errores en los campos, error desconocido.";
            break;
          case 404:
            textSnackbar = "No se encontró el recurso solicitado.";
            break;
          case 500:
            textSnackbar =
              "Se produjo un error interno en el servidor, intente más tarde.";
            break;
          default:
            textSnackbar =
              "Se produjo un error en la conexión al servidor, intente más tarde.";
        }
      } else {
        textSnackbar =
          "Se produjo un error en la conexión al servidor, intente más tarde.";
      }

      this.snackbar = {
        activo: true,
        color: "red",
        texto: textSnackbar,
      };
    },
    setItemSeleccionado(namePath) {
      switch (namePath) {
        case "principal":
          this.itemSeleccionado = 0;
          break;
        case "pacientes":
          this.itemSeleccionado = 1;
          break;
        case "encuestas":
          this.itemSeleccionado = 2;
          break;
        case "corrector":
          this.itemSeleccionado = 2;
          break;
        case "comunicacion":
          this.itemSeleccionado = 3;
          break;
        case "nuevaEncuesta":
        case "nuevaEncuestaAdulto":
        case "nuevaEncuestaPediatria":
          this.itemSeleccionado = 2;
          break;
      }
    },
    setUrlPublica() {
      this.ocultarBotonUsuario = true;
      this.mostrarDrawer = false;
    },
  },
  created() {
    const usuario = JSON.parse(window.localStorage.getItem("usuario"));
    if (usuario) {
      this.usuario = usuario.user;
      switch (this.usuario.rol) {
        case "ADM":
          this.mostrarDrawer = true;
          break;
        case "EST":
        case "MED":
          this.mostrarDrawer = false;
          if (this.$router.history.current.name === "principal")
            this.$router.replace({ name: "pacientes" });
          break;
        case "CORR":
          this.mostrarDrawer = false;
          if (this.$router.history.current.name === "principal")
            this.$router.replace({ name: "corrector" });
          break;
      }
      this.setItemSeleccionado(this.$router.history.current.name);
    } else {
      if (this.$router.history.current.name !== "registrarCuestionario") {
        this.$router.push({ name: "login" });
      }
    }
  },
};
</script>
<style>
.theme--light.v-radio--is-disabled.v-item--active i[aria-hidden="true"].v-icon {
    color: var(--v-primary-base) !important;
}

.theme--light.v-input--is-disabled i.mdi-checkbox-marked.v-icon {
    color: var(--v-primary-base) !important;
}

.theme--light.v-input--is-disabled :is(textarea, input){
    color: rgb(0,0,0) !important;
}

.v-snackbar {
  font-size: 20px;
  height: 80px;
}

</style>