<template>
  <v-app>
    <v-main class="bg-cannava">
      <v-container fluid fill-height>
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <v-img
              src="../assets/img/Logo_Cannava_Solo_800px_White.png"
              contain
            ></v-img>
            <br />
            <v-card :loading="overlayLoading">
              <template slot="progress">
                <v-progress-linear
                  color="purple"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-card-title> CAMBIO DE CONTRASEÑA </v-card-title>
              <v-card-text>
                A continuación ingrese su nueva contraseña, asegurese de
                recordarla.
              </v-card-text>
              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    name="actual_password"
                    label="Contraseña actual"
                    type="password"
                    color="primary"
                    v-model="actual_password"
                    :rules="[rules.required]"
                    :error-messages="errores.actual_password"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    name="new_password"
                    label="Nueva contraseña"
                    type="password"
                    color="primary"
                    v-model="new_password"
                    :rules="[rules.required, rules.minimo8]"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    name="repeat_password"
                    label="Repetir nueva contraseña"
                    type="password"
                    color="primary"
                    v-model="repeat_password"
                    :rules="[
                      rules.required,
                      rules.minimo8,
                      rules.igualNewPassword,
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  rounded
                  text
                  color="primary"
                  @click="solicitarCambio"
                >
                  Cambiar contraseña
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="snackbar.activo"
        :color="snackbar.color"
        centered
        timeout="3000"
      >
        {{ snackbar.texto }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { cambioContrasenia } from "@/services/UsuarioService";

export default {
  name: "ContraseniaView",
  data() {
    return {
      usuario: null,
      overlayLoading: false,
      rules: {
        required: (v) => !!v || "Campo obligatorio",
        minimo8: (v) =>
          (!!v && v.trim().length >= 8) ||
          "Debe tener 8 caracteres como minimo.",
        igualNewPassword: (v) =>
          !this.new_password ||
          v === this.new_password ||
          "Las contraseñas no coinciden.",
      },
      actual_password: "",
      new_password: "",
      repeat_password: "",
      error_message: false,
      snackbar: {
        activo: false,
        color: "primary",
        texto: "Operación exitosa.",
      },
      errores: {},
    };
  },
  methods: {
    solicitarCambio() {
      if (this.$refs["form"].validate()) {
        this.overlayLoading = true;
        cambioContrasenia(this.usuario.id, {
          current_password: this.actual_password,
          new_password: this.new_password,
        })
          .then(() => {
            window.localStorage.removeItem("usuario");
            setTimeout(() => {
              this.$router.replace({ name: "login" });
            }, 2000);
            this.snackbar = {
              activo: true,
              color: "primary",
              texto: "Contraseña actualizada correctamente. Redirigiendo..."
            }
          })
          .catch((e) => {
            let textSnackbar = "";
            if (e.response && e.response.status === 400) {
              this.errores = { ...e.response.data };
            } else {
              if (e.response) {
                switch (e.response.status) {
                  case 401:
                  case 403:
                    textSnackbar =
                      "No esta autorizado para realizar esta operación.";
                    window.localStorage.removeItem("usuario");
                    setTimeout(() => this.$router.replace("/login"), 2000);
                    break;
                  case 500:
                    textSnackbar =
                      "Se produjo un error interno en el servidor, intente más tarde.";
                    break;
                  default:
                    textSnackbar =
                      "Se produjo un error en la conexión al servidor, intente más tarde.";
                }
              } else {
                textSnackbar =
                  "Se produjo un error en la conexión al servidor, intente más tarde.";
              }

              this.snackbar = {
                activo: true,
                color: "red",
                texto: textSnackbar,
              };
            }
          })
          .finally(() => (this.overlayLoading = false));
      }
    },
  },
  created() {
    const usuario = JSON.parse(window.localStorage.getItem("usuario"));
    if (usuario) this.usuario = usuario;
    else this.$router.replace({ name: "login" });
  },
};
</script>