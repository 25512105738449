<template>
  <v-app-bar app dark class="primary" clipped-left>
    <v-app-bar-nav-icon
      v-if="isMobile && mostrarBotonDrawer"
      @click="clickIconoNavbar"
    ></v-app-bar-nav-icon>
    <img
      src="@/assets/img/Logo_Cannava_Solo_800px_White.png"
      contain
      :height="25"
      class="logo mr-4"
      @click="$router.push('/')"
    />
    <div class="separador-vertical" v-if="!isXSDisplay"></div>
    <v-toolbar-title
      v-if="!isXSDisplay"
      class="titulo"
      @click="$router.push('/')"
      >DOLOCANN</v-toolbar-title
    >
    <v-spacer></v-spacer>
    <v-menu
      v-if="!ocultarBotonUsuario"
      :close-on-content-click="false"
      offset-y
      right
      rounded="0"
      max-width="100%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          style="border: 2px solid #fff"
          size="36"
          v-bind="attrs"
          v-on="on"
          >{{ iniciales }}</v-avatar
        >
      </template>
      <v-card tile min-width="300px">
        <div class="d-flex justify-end">
          <div
            height="100%"
            class="opcion-sesion d-inline py-1 px-2"
            :class="isXSDisplay ? 'body-1' : 'body-2'"
            @click="makeLogout"
          >
            Cerrar sesión
          </div>
        </div>
        <v-card-text>
          <div class="mx-1 d-flex">
            <v-avatar color="grey lighten-2" size="56" class="mr-5">{{
              iniciales
            }}</v-avatar>
            <div>
              <h2 class="text-start black--text mb-1">
                {{ usuario.nombres }} {{ usuario.apellidos }}
              </h2>
              <div class="text-start body-1 text-no-wrap my-1">
                {{ usuario.username }}
              </div>
              <div class="text-start body-1 text-no-wrap my-1">
                {{ rol }}
              </div>
              <div
                class="opcion-usuario text-start text-decoration-underline blue--text"
                @click.stop="$router.push({ name: 'cambioContrasenia' })"
              >
                Cambiar contraseña
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    abrirDrawer: {
      type: Boolean,
      required: true,
    },
    mostrarBotonDrawer: {
      type: Boolean,
      required: true,
    },
    usuario: {
      type: Object,
      required: true,
    },
    ocultarBotonUsuario: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tarjetaUsuario: false,
    };
  },
  computed: {
    isXSDisplay() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    iniciales() {
      return Object.keys(this.usuario).length
        ? this.usuario.nombres.trim().charAt(0) +
            this.usuario.apellidos.trim().charAt(0)
        : "";
    },
    rol() {
      const roles = {
        ADM: "ADMINISTRADOR",
        EST: "ESTADISTICO",
        MED: "NEUROLOGO",
        CORR: "CORRECTOR",
      };
      return roles[this.usuario["rol"]];
    },
  },
  methods: {
    makeLogout() {
      window.localStorage.removeItem("usuario");
      this.$router.push("/login");
    },
    clickIconoNavbar() {
      this.$emit("update:abrirDrawer", !this.abrirDrawer);
    },
  },
};
</script>

<style scoped>
.separador-vertical {
  margin-right: 5px;
  height: 50%;
  border: 1px solid #fff;
}

.logo,
.titulo {
  cursor: pointer;
}

.btn-usuario {
  border: 2px solid rgb(223, 223, 223);
  color: rgb(223, 223, 223);
  border-radius: 100%;
  padding: 5px;
  user-select: none;
}

.btn-usuario:hover {
  border-color: #fff;
  color: #fff;
  cursor: pointer;
}

.card-usuario {
  position: absolute;
  right: 0;
  top: 100%;
}

.opcion-sesion {
  color: rgba(0, 0, 0, 0.6);
  user-select: none;
}

.opcion-sesion:hover {
  cursor: pointer;
  background-color: rgb(199, 199, 199);
}

.opcion-usuario {
  user-select: none;
}

.opcion-usuario:hover {
  cursor: pointer;
}
</style>