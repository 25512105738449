<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-container fluid class="pa-0 mb-2" v-if="!hasQueryParam">
      <v-btn class="mx-2" dark color="orange darken-4" @click="$router.back()">
        <v-icon dark> mdi-arrow-left </v-icon>
        Volver
      </v-btn>
    </v-container>
    <form-encuesta
      :idPaciente="parseInt(idPaciente)"
      :numEncuesta="parseInt(numEncuesta)"
      :tokenPaciente="tokenPaciente"
      :hasQueryParam="hasQueryParam"
      :esEncuestaAdulto="esEncuestaAdulto"
    />
  </v-container>
</template>

<script>
import FormEncuesta from "@/components/FormEncuesta.vue";

export default {
  name: 'NuevaEncuestaView',
  props: {
    id: Number,
    numero: Number,
    setUrlPublica: {
      type: Function,
      required: true,
    },
    setItemSeleccionado: {
      type: Function,
      required: true,
    }
  },
  components: {
    FormEncuesta
  },
  data() {
    return {
      idPaciente: null,
      numEncuesta: null,
      tokenPaciente: '',
      hasQueryParam: false,
      esEncuestaAdulto: true
    }
  },
  methods: {
    setPaciente() {
      if (this.id && this.numero) {
        this.idPaciente = this.id
        this.numEncuesta = this.numero
        window.localStorage.setItem('paciente', this.id)
        window.localStorage.setItem('numeroEncuesta', this.numero)
      }
      else if (
        !isNaN(parseInt(this.$route.query['id']))
        && !isNaN(parseInt(this.$route.query['numero']))
        && this.$route.query['token']
      ) {
        this.setUrlPublica()
        this.hasQueryParam = true
        this.idPaciente = parseInt(this.$route.query['id'])
        this.tokenPaciente = this.$route.query['token']
        this.numEncuesta = parseInt(this.$route.query['numero'])
        window.localStorage.removeItem('paciente')
        window.localStorage.removeItem('numeroEncuesta')
      }
      else {
        const paciente = window.localStorage.getItem('paciente')
        const numeroEncuesta = window.localStorage.getItem('numeroEncuesta')
        if (!isNaN(parseInt(paciente)) && !isNaN(parseInt(numeroEncuesta))) {
          this.idPaciente = parseInt(paciente)
          this.numEncuesta = parseInt(numeroEncuesta)
        } else {
          this.$router.back()
        }
      }
    }
  },
  created() {
    if (this.$router.currentRoute.name === 'nuevaEncuestaPediatria') this.esEncuestaAdulto = false
    this.setPaciente()
    this.setItemSeleccionado("encuestas");
  },
  beforeDestroy() {
    // Elimina el id del paciente y el numero de encuesta almacenado en localStorage al desmontar el componente
    window.localStorage.removeItem('paciente')
    window.localStorage.removeItem('numeroEncuesta')
  }
}
</script>