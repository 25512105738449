<template>
  <v-dialog v-model="mostrarDialog" persistent max-width="500">
    <v-card class="py-6">
      <div class="d-flex justify-center">
        <v-img
          contain
          max-height="200"
          max-width="232"
          src="@/assets/img/pngegg.png"
        ></v-img>
      </div>
      <div class="text-center display-1 font-weight-bold">¡Operación exitosa!</div>
      <div v-if="mostrarBotones">
        <v-card-text class="text-center">
          Gracias por completar esta encuesta.
        </v-card-text>
        <div class="text-center mt-6 mb-3">
          <v-btn color="success" @click="irNuevaEncuesta">Nueva encuesta</v-btn>
        </div>
        <div class="text-center">
          <v-btn color="green darken-1" @click="irInicio" text>Ir a inicio</v-btn>
        </div>
      </div>
      <div class="text-center" v-else>
        <v-card-text>
          Gracias por completar esta encuesta, ésto nos ayuda a ayudar.
        </v-card-text>
        <div class="mt-6">
          <v-btn color="green darken-1" disabled text>fin de encuesta</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogExito",
  props: {
    mostrarExito: {
      type: Boolean,
      required: true
    },
    mostrarBotones: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    mostrarDialog() {
      return this.mostrarExito
    }
  },
  methods: {
    irNuevaEncuesta() {
      this.$router.back()
    },
    irInicio() {
      this.$router.replace('/')
    }
  }
};
</script>