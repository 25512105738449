<template>
  <div class="ma-0 mb-5">
    <v-col cols="12" md="8" lg="6" class="pa-0 pa-md-3">
      <div class="text-subtitle-2 mb-2">
        Pregunta 1: ¿Tiene su dolor alguna de estas caracteristicas?
      </div>
      <div
        v-for="pregunta in preguntasCaracteristicasDolor"
        :key="pregunta.model"
        class="d-flex align-center justify-space-between"
      >
        <p class="my-0">{{ pregunta.label }}</p>
        <v-radio-group
          v-model="formData.caracteristica_dolor[pregunta.model]"
          row
          class="my-0"
          hide-details="auto"
          :rules="[rules.required]"
          @change="validarRespuestas"
        >
          <v-radio label="Si" :value="true" :disabled="disabled"></v-radio>
          <v-radio label="No" :value="false" :disabled="disabled"></v-radio>
        </v-radio-group>
      </div>
      <v-divider class="mt-2"></v-divider>
      <div class="text-subtitle-2 mt-5 mb-2">
        Pregunta 2: ¿Tiene en la zona donde le duele alguno de estos síntomas?
      </div>
      <div
        v-for="pregunta in preguntasSintomaZonaDolor"
        :key="pregunta.model"
        class="d-flex align-center justify-space-between"
      >
        <p class="my-0">{{ pregunta.label }}</p>
        <v-radio-group
          v-model="formData.sintoma_zona_dolor[pregunta.model]"
          row
          class="my-0"
          hide-details="auto"
          :rules="[rules.required]"
          @change="validarRespuestas"
        >
          <v-radio label="Si" :value="true" :disabled="disabled"></v-radio>
          <v-radio label="No" :value="false" :disabled="disabled"></v-radio>
        </v-radio-group>
      </div>
      <v-divider class="mt-2"></v-divider>
      <div class="text-subtitle-2 mt-5 mb-2">
        Pregunta 3: ¿Se evidencia en la exploración alguno de estos signos en la
        zona dolorosa?
      </div>
      <div
        v-for="pregunta in preguntasSignoZonaDolor"
        :key="pregunta.model"
        class="d-flex align-center justify-space-between"
      >
        <p class="my-0">{{ pregunta.label }}</p>
        <v-radio-group
          v-model="formData.signo_zona_dolor[pregunta.model]"
          row
          class="my-0"
          hide-details="auto"
          :rules="[rules.required]"
          @change="validarRespuestas"
        >
          <v-radio label="Si" :value="true" :disabled="disabled"></v-radio>
          <v-radio label="No" :value="false" :disabled="disabled"></v-radio>
        </v-radio-group>
      </div>
      <v-divider class="mt-2"></v-divider>
      <div class="text-subtitle-2 mt-5 mb-2">
        Pregunta 4: ¿¿El dolor se provoca o intensifica por?
      </div>
      <div
        v-for="pregunta in preguntasMotivoDolor"
        :key="pregunta.model"
        class="d-flex align-center justify-space-between"
      >
        <p class="my-0">{{ pregunta.label }}</p>
        <v-radio-group
          v-model="formData.motivo_dolor[pregunta.model]"
          row
          class="my-0"
          hide-details="auto"
          :rules="[rules.required]"
          @change="validarRespuestas"
        >
          <v-radio label="Si" :value="true" :disabled="disabled"></v-radio>
          <v-radio label="No" :value="false" :disabled="disabled"></v-radio>
        </v-radio-group>
      </div>
      <v-divider class="mt-2"></v-divider>
    </v-col>
    <!-- <v-btn @click="rellenar" color="info"> Rellenar Test </v-btn> -->
  </div>
</template>

<script>
const initialData = {
  caracteristica_dolor: {
    quemazon: null,
    frio_doloroso: null,
    descargas_electricas: null,
  },
  sintoma_zona_dolor: {
    hormigueo: null,
    pinchazos: null,
    entumecimiento: null,
    escozor: null,
  },
  signo_zona_dolor: {
    hipotesia_tacto: null,
    hipotesia_pinchazo: null,
  },
  motivo_dolor: {
    roce: null,
  },
};

export default {
  name: "FormularioDN4",
  props: {
    data: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    excludeBy: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      radioGroup: null,
      rules: {
        required: (v) => v !== null || "Campo obligatorio",
      },
      motivosExclusion: {
        edad: "El paciente no cumple con la edad de ingreso al estudio (21 a 70 años)",
      },
      preguntasCaracteristicasDolor: [
        { label: "Quemazón", model: "quemazon" },
        { label: "Sensación de frio doloroso", model: "frio_doloroso" },
        { label: "Descargas eléctricas", model: "descargas_electricas" },
      ],
      preguntasSintomaZonaDolor: [
        { label: "Hormigueo", model: "hormigueo" },
        { label: "Pinchazos", model: "pinchazos" },
        { label: "Entumecimiento", model: "entumecimiento" },
        { label: "Escozor", model: "escozor" },
      ],
      preguntasSignoZonaDolor: [
        { label: "Hipoestesia al tacto", model: "hipotesia_tacto" },
        { label: "Hipoestesia al pinchazo", model: "hipotesia_pinchazo" },
      ],
      preguntasMotivoDolor: [{ label: "El roce", model: "roce" }],
    };
  },
  methods: {
    rellenar(){
      this.formData = {
          caracteristica_dolor: {
          quemazon: false,
          frio_doloroso: false,
          descargas_electricas: false,
        },
        sintoma_zona_dolor: {
          hormigueo: false,
          pinchazos: false,
          entumecimiento: false,
          escozor: true,
        },
        signo_zona_dolor: {
          hipotesia_tacto: true,
          hipotesia_pinchazo: true,
        },
        motivo_dolor: {
          roce: null,
        }
      }
    },
    validarRespuestas() {
      let existNull = false;
      for (const keyCuestionario in this.data) {
        for (const key in this.data[keyCuestionario]) {
          if (this.data[keyCuestionario][key] === null) {
            existNull = true;
            break;
          }
        }
      }
      if (!existNull) {
        let puntos = 0;
        for (const keyCuestionario in this.data) {
          for (const key in this.data[keyCuestionario]) {
            puntos += this.data[keyCuestionario][key];
          }
        }
        this.$emit("update:excludeBy", puntos < 4 ? "dn4" : null);
      }
    },
  },
  computed: {
    formData: {
      get() {
        return this.data || initialData;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
  },
  created() {
    this.validarRespuestas();
    }  
};
</script>