<template>
  <v-row class="ma-0 mb-5">
    <v-col cols="12" sm="6" lg="4">
      <v-text-field
        v-model.number="formData.dni"
        label="DNI*"
        type="number"
        :rules="[rules.required, rules.dni]"
        outlined
        dense
        hide-details="auto"
        :error-messages="errors.dni"
        @input="onInputDNI"
      />
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <v-text-field
        v-model="formData.nombres"
        label="Nombres*"
        :rules="[rules.required]"
        outlined
        dense
        hide-details="auto"
        :error-messages="errors.nombres"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <v-text-field
        v-model="formData.apellidos"
        label="Apellidos*"
        :rules="[rules.required]"
        outlined
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <v-text-field
        v-model="formData.fecha_nacimiento"
        label="Fecha de nacimiento*"
        persistent-hint
        :rules="[rules.required]"
        type="date"
        :max="fechaActual"
        outlined
        dense
        hide-details="auto"
        :error-messages="errors.fecha_nacimiento"
        @blur="validarEdad"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <v-autocomplete
        v-model="formData.genero"
        :items="[
          { key: 'F', label: 'Femenino' },
          { key: 'M', label: 'Masculino' },
        ]"
        item-text="label"
        item-value="key"
        label="Género*"
        :rules="[rules.required]"
        outlined
        dense
        hide-details="auto"
        :error-messages="errors.genero"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <v-autocomplete
        v-model="formData.departamento"
        :items="departamentos"
        item-text="label"
        item-value="key"
        label="Departamento donde vive*"
        :rules="[rules.required]"
        outlined
        dense
        hide-details="auto"
        :error-messages="errors.departamento"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <v-text-field
        v-model="formData.telefono"
        label="Telefono*"
        type="number"
        hint="(Cod. area sin el 0) + (número de teléfono sin el 15)"
        persistent-hint
        :rules="[rules.required, rules.telefono]"
        outlined
        dense
        hide-details="auto"
        :error-messages="errors.telefono"
      />
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <v-text-field
        v-model="formData.fecha_inicio"
        label="Fecha de inicio tratamiento con Cannava CBD10*"
        persistent-hint
        :rules="[rules.required, rules.fecha_inicio]"
        type="date"
        :max="fechaActual"
        outlined
        dense
        hide-details="auto"
        @blur="validarEdad"
        :error-messages="errors.fecha_inicio"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <v-autocomplete
        v-model="formData.diagnostico"
        label="Diagnóstico*"
        :items="codigosDiagnosticoCIE"
        item-text="label"
        item-value="key"
        :rules="[rules.required]"
        outlined
        dense
        hide-details="auto"
        :error-messages="errors.diagnostico"
      ></v-autocomplete>
    </v-col>
    <v-col v-if="data.diagnostico === 'otros'" cols="12" sm="6" lg="4">
      <v-text-field
        v-model="formData.otro_diagnostico"
        label="Descripcion del diagnóstico*"
        persistent-hint
        maxlength="100"
        :rules="[rules.required]"
        outlined
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { departamentos, codigosDiagnosticoCIE } from "@/components/options";
import { getPacienteDNI } from "@/services/PacientesService";
import moment from "moment";

export default {
  name: "FormularioPaciente",
  props: {
    data: {
      type: Object,
      required: true,
    },
    excludeBy: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
  },
  data() {
    return {
      errors: {},
      dialog: false,
      step: 1,
      radioGroup: null,
      rules: {
        required: (v) => !!v || "Campo obligatorio",
        dni: (v) => (10 ** 6 <= v && v <= 2 * 10 ** 8) || "DNI no válido",
        telefono: (v) =>
          /^[1-9]{2,4}[2-9]\d{6}$/.test(v) ||
          "No es un numero de telefono válido.",
        fecha_nacimiento: (v) => this.validarFechaNacimiento(v),
        fecha_inicio: (v) => this.validarFechaInicio(v),
      },
      fechaActual: moment().format("YYYY-MM-DD"),
      departamentos,
      codigosDiagnosticoCIE,
      motivosExclusion: {
        edad: "El paciente no cumple con la edad de ingreso al estudio (21 a 70 años)",
      },
      timeOut: null,
    };
  },
  methods: {
    validarEdad() {
      if (this.data?.fecha_nacimiento && this.data?.fecha_inicio) {
        const fechaNacimiento = moment(this.data.fecha_nacimiento);
        const fechaInicio = moment(this.data.fecha_inicio);
        const edad = fechaInicio.diff(fechaNacimiento, "years");
        this.$emit("update:excludeBy", edad < 21 || edad > 70 ? "edad" : null);
      } else {
        this.$emit("update:excludeBy", null);
      }
    },
    validarFechaInicio(v) {
      const fechaInicio = moment(v);
      if (fechaInicio > moment()) return "No puede ser superior al dia de hoy.";

      // Validacion mayor o igual a fecha de nacimiento
      if (this.data.fecha_nacimiento) {
        const fechaNacimiento = moment(this.data.fecha_nacimiento);
        if (fechaInicio < fechaNacimiento)
          return "No puede ser inferior a la fecha de nacimiento.";
      }
      return true;
    },
    onInputDNI() {
      delete this.errors.dni;
      if (this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        if (this.data.dni >= 10 ** 6 && this.data.dni <= 2 * 10 ** 8) {
          getPacienteDNI(this.data.dni)
            .then(() => {
              this.errors = {
                ...this.errors,
                dni: ["Ya existe un paciente con este DNI."],
              };
            })
            .catch(() => {});
        }
      }, 700);
    },
  },
  computed: {
    formData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
  },
};
</script>