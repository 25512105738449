<template>
  <v-card class="card-table">
    <v-card-title class="mb-2">
      <div class="pt-3 mt-1">{{ tituloTabla }}</div>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="busqueda"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <div class="pt-3 mt-1">
        <slot name="accion" :accionBoton="accionBoton"></slot>
      </div>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="listaItems"
      :search="busqueda"
      :loading="estaCargando||cambiandoPagina"
      :page.sync="pagina"
      @update:page="paginaUpdate"
      loading-text="Cargando... Por favor espere"
      no-data-text="Sin datos disponibles"
      no-results-text="No se encontraron coincidencias"
      :key="tablaKey"
    >
      <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
        <slot :name="name" v-bind="item"></slot>
      </template>      
    </v-data-table>
  </v-card>
</template>
<!-- :page.sync="pagina"
      @update:page="paginaUpdate($event)" -->
<script>
export default {
  name: "TablaPaginaComponent",
  props: {
    tituloTabla: {
      type: String,
      default: ""
    },
    accionBoton: {
      type: Function,
      default: () => {}
    },
    headers: {
      type: Array,
      required: true
    },
    listaItems: {
      type: Array,
      default: () => []
    },
    estaCargando: {
      type: Boolean,
      default: false
    },
    /* pagina: {
      type: Number,
      default: 1
    } */
  },
  data() {
    return {
      busqueda: "",
      pagina: 1,
      tablaKey: 0,
      cambiandoPagina: false
    }
  },
  methods: {
    paginaUpdate(pagina) {
      this.pagina = pagina;
      console.log("Pagina actual: ", pagina);
      localStorage.setItem("paginaEncuestas", parseInt(pagina));
      // sincronizar el cambio hacia el elemento padre
      //this.$emit("update:pagina", this.pagina);
    }       
  },
  created() {
    const paginaEncuestas = localStorage.getItem("paginaEncuestas");
    if (paginaEncuestas) {
      this.pagina = parseInt(paginaEncuestas);
    }
    else{
      this.pagina = 1;
    }
    console.log("Created ... Pagina inicial: ", this.pagina);
    
  },
  
    mounted() {
      this.cambiandoPagina = true;
      const paginaEncuestas = localStorage.getItem("paginaEncuestas");
      if (paginaEncuestas) {
        this.pagina = parseInt(paginaEncuestas);
        console.log("Pagina storage: ", this.pagina);
      }
      console.log("Mounted ... Pagina inicial: ", this.pagina);

      setTimeout(() => {
        this.cambiandoPagina = false;
        this.tablaKey++;
      }, 300);
    }

    
}
</script>

<style scoped>
.card-table {
  backdrop-filter: blur(16px) saturate(100%);
  -webkit-backdrop-filter: blur(16px) saturate(100%);
  background-color: rgba(255, 255, 255, 0.50);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  padding: 0 10px;
  padding-bottom: 10px;
}
</style>
