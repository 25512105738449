<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-container fluid class="pa-0 mb-2">
      <v-btn class="mx-2" dark color="orange darken-4" @click="$router.back()">
        <v-icon dark> mdi-arrow-left </v-icon>
        Volver
      </v-btn>
    </v-container>
    <v-card class="card-table me-md-5">
      <v-container fluid class="pa-0 mb-2 pa-4">
        <p
          class="font-weight-bold text-h6 text-md-h5 text--secondary text-center"
        >
          Seguimiento de pacientes del programa DOLOCANN
        </p>
        <div v-if="paciente" class="d-flex flex-column align-center">
          <v-card width="344">
            <v-card-text>
              <v-alert type="error" v-if="!paciente.incluido">
                El paciente no fué incluido en el estudio
              </v-alert>
              <v-alert
                type="error"
                v-if="
                  paciente.datos_cuestionarios &&
                  paciente.datos_cuestionarios.excluido
                "
              >
                El paciente fué excluido durante el estudio
              </v-alert>
              <v-alert
                type="error"
                color="orange"
                dark
                v-if="
                  paciente.datos_cuestionarios &&
                  !paciente.datos_cuestionarios.excluido &&
                  !paciente.datos_cuestionarios.ultimo_cuestionario_completo
                "
              >
                El paciente no completó el ultimo cuestionario
              </v-alert>
              <v-alert
                type="success"
                v-if="
                  paciente.datos_cuestionarios &&
                  paciente.datos_cuestionarios.estudio_finalizado
                "
              >
                El paciente ya finalizó el estudio
              </v-alert>
              <div class="subtitle-2">Paciente:</div>
              <div class="text--primary subtitle-2">
                {{ paciente.apellidos }} {{ paciente.nombres }}
              </div>
              <div class="subtitle-2 mt-2">DNI:</div>
              <div class="text--primary subtitle-2">{{ paciente.dni }}</div>
              <div class="subtitle-2 mt-2">PID:</div>
              <div class="text--primary subtitle-2">{{ paciente.pid }}</div>
              <div class="subtitle-2 mt-2">Género:</div>
              <div class="text--primary subtitle-2">
                {{ { M: "Masculino", F: "Femenino" }[paciente.genero] }}
              </div>
              <div class="subtitle-2 mt-2">Estudio:</div>
              <div class="text--primary subtitle-2">
                DOLOCANN {{ paciente.estudio_piloto }}
              </div>
              <div
                class="subtitle-2 mt-2"
                v-if="
                  paciente.datos_cuestionarios &&
                  !paciente.datos_cuestionarios.excluido &&
                  !paciente.datos_cuestionarios.estudio_finalizado
                "
              >
                Visitas registradas:
              </div>
              <div
                class="text--primary subtitle-2"
                v-if="
                  paciente.datos_cuestionarios &&
                  !paciente.datos_cuestionarios.excluido &&
                  !paciente.datos_cuestionarios.estudio_finalizado
                "
              >
                {{ paciente.datos_cuestionarios.ultima_visita }}
              </div>
              <v-divider class="mt-3"></v-divider>
              <div
                class="mt-3 text-center"
                v-if="
                  paciente.incluido &&
                  !paciente.datos_cuestionarios.excluido &&
                  !paciente.datos_cuestionarios.estudio_finalizado &&
                  paciente.datos_cuestionarios.ultimo_cuestionario_completo
                "
              >
                <v-btn color="success" @click="registrarVisita"
                  >Registrar visita</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
          <div class="text-center mt-5">
            <v-btn rounded color="purple" dark @click="nuevaBusqueda">
              <v-icon left dark> mdi-reload </v-icon>
              Nueva búsqueda
            </v-btn>
          </div>
        </div>
        <div class="pt-4 d-flex justify-center" v-else>
          <v-form
            ref="form"
            @submit.prevent
            class="text-center d-flex flex-column flex-md-row align-center align-md-start"
          >
            <v-text-field
              v-model.number="dni"
              label="DNI*"
              type="number"
              :rules="[rules.required]"
              outlined
              dense
              hide-details="auto"
              :error-messages="error"
              @input="onInputDNI"
            />
            <div class="ml-0 ml-md-3 mt-3 mt-md-0 text-center">
              <v-btn color="success" @click="searchDNI">Buscar</v-btn>
            </div>
          </v-form>
        </div>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { getPacienteDNI } from "@/services/PacientesService";

export default {
  name: "SeguimientoPacienteView",
  props: {
    setSnackbar: {
      type: Function,
      required: true,
    },
    manejarErrorAxios: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dni: null,
      rules: {
        required: (v) => !!v || "Campo obligatorio",
      },
      paciente: null,
      timeOut: null,
      error: null,
    };
  },
  methods: {
    onInputDNI() {
      this.error = null;
      if (this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.searchDNI();
      }, 1000);
    },
    searchDNI() {
      if (this.$refs["form"].validate() && !this.error) {
        getPacienteDNI(this.dni)
          .then((response) => {
            this.paciente = response;
          })
          .catch((e) => {
            if (e.response && e.response.status === 404)
              this.error = "Este paciente no esta registrado.";
            else this.manejarErrorAxios(e);
          });
      }
    },
    nuevaBusqueda() {
      this.dni = null;
      this.paciente = null;
    },
    registrarVisita() {
      this.$router.push({
        name: "registroSeguimientoPaciente",
        query: { paciente: this.paciente.pid },
      });
    },
  },
};
</script>

<style scoped>
.card-table {
  backdrop-filter: blur(16px) saturate(100%);
  -webkit-backdrop-filter: blur(16px) saturate(100%);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  padding: 0 10px;
  padding-bottom: 10px;
}
</style>
