<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="font-weigth-medium">
          1- Número de crisis epilépticas durante las últimas 2 semanas
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0">
        <v-text-field
          id="cantEpilepcia"
          placeholder="Ingrese un número"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.crisis_epilepticas"
          type="number"
          min="0"
          :rules="rules.required.concat(rules.mayorCero)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col cols="12">
        <p class="mb-0">
          2- Escala de calidad de vida del niño con epilepsia (CAVE) (Para
          pacientes menores de 16 años)
        </p>
        <small
          ><i
            >* Primero consulte debajo la definición de códigos CAVE para cada
            una de las definiciones</i
          ></small
        ><br />
        <small>(Seleccione una opción para cada valoración) </small>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-label>Conducta*</v-label>
        <v-select
          :items="opciones.cave"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.conducta"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-label>Asistencia escolar*</v-label>
        <v-select
          :items="opciones.cave"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.asistencia_escolar"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-label>Aprendizaje*</v-label>
        <v-select
          :items="opciones.cave"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.aprendizaje"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-label>Autonomía*</v-label>
        <v-select
          :items="opciones.cave"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.autonomia"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-label>Relación social*</v-label>
        <v-select
          :items="opciones.cave"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.relacion_social"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-label>Frecuencia de crisis*</v-label>
        <v-select
          :items="opciones.cave"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.frecuencia_crisis"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-label>Intensidad de crisis*</v-label>
        <v-select
          :items="opciones.cave"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.intensidad_crisis"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-label>Opinión de los padres*</v-label>
        <v-select
          :items="opciones.cave"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.opinion_padres"
          :rules="rules.required"
        ></v-select>
      </v-col>
    </v-row>
    <div class="mt-6" height="auto">
      <v-card-title class="primary--text">
        Definición códigos CAVE
      </v-card-title>
      <v-expansion-panels focusable>
        <v-expansion-panel>
          <v-expansion-panel-header><b>Conducta</b></v-expansion-panel-header>
          <v-expansion-panel-content>
            <b>1. Muy mala:</b> trastornos graves de la conducta, entendiendo
            como tales los que repercuten de manera importante en la dinámica
            familiar, y no pueden modificarse de ningún modo.<br />
            <b>2. Mala:</b> trastornos importantes del comportamiento que
            interrumpen la dinámica familiar, pero que se pueden mejorar
            parcialmente, e incluso anular de forma temporal, con técnicas de
            modificación de conducta.<br />
            <b>3. Regular:</b> alteraciones moderadas de la conducta, que
            responden bien a normas educacionales.<br />
            <b>4. Buena:</b> sin comentarios.<br />
            <b>5. Muy buena:</b> corresponde a la del “niño modelo”.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><b>Asistencia escolar</b></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <b>1. Muy mala:</b> absentismo prácticamente total, no asiste ningún
            día o casi ningún día al colegio o a la guardería<br />
            <b>2. Mala:</b> no asiste al colegio o a la guardería una semana o
            más, por trimestre, y llega a estar ausente la tercera parte de los
            días.<br />
            <b>3. Regular:</b> no asiste al colegio o a la guardería una semana
            o más por trimestre, pero sin llegar a estar ausente la tercera
            parte de los días<br />
            <b>4. Buena:</b> absentismo escolar inferior a siete días por
            trimestre<br />
            <b>5. Muy buena:</b> : ninguna falta de asistencia durante el
            periodo de tiempo analizado.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><b>Aprendizaje</b></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <b>1. Muy mala:</b> aprendizaje nulo, incluso con impresión de
            perdida de lo adquirido, si ello es posible<br />
            <b>2. Mala:</b> aprendizaje escaso, casi imperceptible, pero sin
            matices regresivos<br />
            <b>3. Regular:</b> aprendizaje discreto, pero evidente y mantenido,
            aunque con lentitud en las adquisiciones<br />
            <b>4. Buena:</b> sin comentarios.<br />
            <b>5. Muy buena:</b> aprendizaje excelente, superior a la media de
            su clase, o al de su grupo de edad cronológica o mental.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header><b>Autonomía</b></v-expansion-panel-header>
          <v-expansion-panel-content>
            <b>1. Muy mala:</b> autonomía nula, dependencia total de los adultos
            para todo.<br />
            <b>2. Mala:</b> dependencia parcial, o solo para algunas cosas.<br />
            <b>3. Regular:</b> dependencia escasa, e incluso “ficticia”, no
            debida a limitaciones reales, sino a sobreprotección familiar.<br />
            <b>4. Buena:</b> sin comentarios.<br />
            <b>5. Muy buena:</b> independencia en las actividades propias de la
            edad, pero con una habilidad excelente.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><b>Relación social</b></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <b>1. Muy mala:</b> nula relación social, aislamiento total.<br />
            <b>2. Mala:</b> tendencia frecuente al aislamiento, pero con
            relación ocasional dentro del medio familiar.<br />
            <b>3. Regular:</b> aislamiento ocasional, tanto dentro como fuera
            del entorno familiar.<br />
            <b>4. Buena:</b> sin comentarios.<br />
            <b>5. Muy buena:</b> excelente relación social e intensa
            extroversión.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><b>Frecuencia de la crisis</b></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <b>1. Muy mala:</b> más de diez días con crisis durante el periodo
            de tiempo analizado.<br />
            <b>2. Mala:</b> de seis a diez días con crisis durante el periodo de
            tiempo analizado.<br />
            <b>3. Regular:</b> de dos a cinco crisis durante el periodo de
            tiempo analizado.<br />
            <b>4. Buena:</b> un día con crisis durante el periodo de tiempo
            analizado<br />
            <b>5. Muy buena:</b> sin crisis durante el periodo de tiempo
            analizado
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><b>Intensidad de la crisis</b></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <b>1. Muy mala:</b> crisis convulsivas de larga duración, frecuentes
            crisis acinéticas o estatus no convulsivos.<br />
            <b>2. Mala:</b> crisis convulsivas de corta duración, crisis
            acinéticas poco frecuentes o crisis parciales complejas de larga
            duración, con o sin generalización secundaria.<br />
            <b>3. Regular:</b> crisis parciales complejas de breve duración,
            crisis elementales o crisis mioclónicas aisladas.<br />
            <b>4. Buena:</b> crisis únicas o crisis no convulsivas muy poco
            frecuentes.<br />
            <b>5. Muy buena:</b> : sin crisis.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <p class="mt-4">
        <b>Opinión de los padres:</b> : Se pretende recoger la opinión subjetiva
        de los padres respecto a la calidad de vida de su hijo con epilepsia.
        Debe contestarse igualmente con cinco respuestas, que se puntúan de 1
        (=muy mala) a 5 (=muy buena)
      </p>
    </div>
  </v-container>
</template>

<script>
import options from "./options";

export default {
  name: "SubcuestionarioCave",
  props: {
    datosCave: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        mayorCero: [(v) => v >= 0 || "Debe ser mayor o igual a cero"],
      },
      opciones: options,
    };
  },
  computed: {
    datos: {
      get() {
        return this.datosCave;
      },
      set(nuevoValor) {
        this.$emit("update:datosCave", nuevoValor);
      },
    },
  },
};
</script>