<template>
  <div>
    <div class="pt-3">
      Seleccione la respuesta de cada apartado que mejor
      <b>describa su estado de salud en el dia de HOY.</b>
    </div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-label for="movilidad">Movilidad:</v-label>
          <v-select
            :items="opciones.movilidad"
            id="movilidad"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.movilidad"
            :rules="rules.required"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-label for="cuidado">Cuidado personal:</v-label>
          <v-select
            :items="opciones.cuidados"
            id="cuidado"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.cuidado_personal"
            :rules="rules.required"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-label for="ansiedad">Ansiedad/Depresión:</v-label>
          <v-select
            :items="opciones.ansiedad"
            id="ansiedad"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.ansiedad_depresion"
            :rules="rules.required"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-label for="dolor">Dolor/Malestar:</v-label>
          <v-select
            :items="opciones.dolor"
            id="dolor"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.dolor_malestar"
            :rules="rules.required"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-label for="actividades">Actividades cotidianas:</v-label>
          <v-select
            :items="opciones.actividades"
            id="actividades"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.actividades_cotid"
            :rules="rules.required"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-card-title class="primary--text">
          Escala visual de salud
        </v-card-title>
        <v-card-subtitle>
          Indicar en esta escala en su opinión, lo bueno o lo malo que es su
          estado de salud en el día de HOY. Por favor, desplace la burbuja
          verde hasta el punto del termómetro que en su opinión indique lo
          bueno o lo malo que es su estado de salud en el día de HOY.
        </v-card-subtitle>
      </v-row>
      <v-row class="mx-0">
        <v-subheader class="text-start pl-0">
          0: Peor estado de salud imaginable <br />
          100: Mejor estado de salud imaginable
        </v-subheader>
      </v-row>
      <v-row class="mx-0 pt-5">
        <v-col sm="1" class="px-0 text-end d-none d-sm-block">0</v-col>
        <v-col cols="12" sm="10" class="px-0">
          <v-slider
            v-model="datos.estado_salud"
            min="0"
            max="100"
            thumb-label="always"
            thumb-size="25"
            tick-size="4"
          >
          </v-slider>
        </v-col>
        <v-col sm="1" class="px-0 text-start d-none d-sm-block">100</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import options from "./options";

export default {
  name: "SubcuestionarioSalud",
  props: {
    datosSalud: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      rules: {
        required: [(v) => !!v || "Campo requerido"],
      },
      opciones: options,
    };
  },
  computed: {
    datos: {
      get() {
        return this.datosSalud;
      },
      set(nuevoValor) {
        this.$emit("update:datosSalud", nuevoValor);
      },
    },
  }
};
</script>