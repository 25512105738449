<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-overlay absolute :value="inicializando" class="text-center">
      <v-progress-circular
        :size="50"
        color=""
        indeterminate
      ></v-progress-circular>
      <div class="mt-4 text-h6">Extrayendo datos del paciente...</div>
    </v-overlay>
    <v-card class="card ma-0 mb-3" v-if="paciente">
      <v-container fluid>
        <div class="d-flex flex-column flex-md-row align-start align-md-center">
          <v-chip class="ma-2" color="primary font-weight-bold">
            DOLOCANN {{ paciente.estudio_piloto }}
          </v-chip>
          <v-card-title primary-title class="py-0">
            Cuestionario de visita
            {{ paciente.datos_cuestionarios.ultima_visita }}
          </v-card-title>
        </div>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0 d-flex"
              v-for="dato in datosPaciente"
              :key="dato.label"
            >
              <div class="pe-3 font-weight-bold">{{ dato.label }}:</div>
              <div class="text--secondary font-weight-medium">
                {{ dato.value }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
    <div v-if="paciente && !completo">
      <v-stepper v-model="step" vertical>
        <v-stepper-step step="1">
          Inventario Breve Modificado del Dolor
          <small>Formulario abreviado (mBPI-sf). Versión en español</small>
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-form ref="formBPI">
            <formularioBPI :data.sync="bpi" :errors.sync="errors.bpi" :disabled="false"/>
          </v-form>
          <v-btn color="primary" @click="nextStep('formBPI')"> Continuar </v-btn
          ><v-btn text @click="step = step - 1"> Atras </v-btn>
        </v-stepper-content>

        <v-stepper-step step="2" v-if="[3, 5].includes(visita)">
          Escala HADS de Ansiedad y depresión
        </v-stepper-step>
        <v-stepper-content step="2" v-if="[3, 5].includes(visita)">
          <v-form ref="formHADS">
            <FormularioHADS :data.sync="hads" :errors.sync="errors.hads" :disabled="false"/>
          </v-form>
          <v-btn color="primary" @click="nextStep('formHADS')">
            Continuar </v-btn
          ><v-btn text @click="step = step - 1"> Atras </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :step="visita === 1 ? 2 : 3"
          v-if="[1, 3, 5].includes(visita)"
        >
          Cuestionario de calidad de vida EuroQol EQ-5D
        </v-stepper-step>
        <v-stepper-content
          :step="visita === 1 ? 2 : 3"
          v-if="[1, 3, 5].includes(visita)"
        >
          <v-form ref="formEQ5D">
            <FormularioEQ5D :data.sync="eq5d" :errors.sync="errors.eq5d" :disabled="false"/>
          </v-form>
          <v-btn color="primary" @click="nextStep('formEQ5D')">
            Continuar </v-btn
          ><v-btn text @click="step = step - 1"> Atras </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :step="visita === 1 ? 3 : 4"
          v-if="[1, 3, 5].includes(visita)"
        >
          Cuestionario de Calidad de Sueño.
        </v-stepper-step>
        <v-stepper-content
          :step="visita === 1 ? 3 : 4"
          v-if="[1, 3, 5].includes(visita)"
        >
          <v-form ref="formCalidadSuenio">
            <formulario-calidad-suenio
              :data.sync="calidadSuenio"
              :errors.sync="errors.calidad_suenio"
              :disabled="false"
            />
          </v-form>
          <v-btn
            color="primary"
            @click="
              visita === 1
                ? goToSubmit('formCalidadSuenio')
                : nextStep('formCalidadSuenio')
            "
          >
            Continuar </v-btn
          ><v-btn text @click="step = step - 1"> Atras </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :step="[2, 4, 6].includes(visita) ? 2 : 5"
          v-if="visita > 1"
        >
          Reacciones adversas asociadas al cannabis medicinal
        </v-stepper-step>
        <v-stepper-content
          :step="[2, 4, 6].includes(visita) ? 2 : 5"
          v-if="visita > 1"
        >
          <v-form ref="formEventosAdversos">
            <FormularioEventosAdversos
              :data.sync="eventosAdversos"
              :errors.sync="errors.eventos_adversos"
              :existenEventos.sync="existenEventos"
              :disabled="false"
            />
          </v-form>
          <v-btn
            color="primary"
            @click="goFromEventosAdversos('formEventosAdversos')"
          >
            Continuar </v-btn
          ><v-btn text @click="step = step - 1"> Atras </v-btn>
        </v-stepper-content>

        <!-- <v-stepper-step
          :step="visita === 1 ? 4 : [2, 4, 6].includes(visita) ? 3 : 6"
        >
          Medicación concomitante
        </v-stepper-step>
        <v-stepper-content
          :step="visita === 1 ? 4 : [2, 4, 6].includes(visita) ? 3 : 6"
        >
          <v-form ref="formMedicacion">
            <formulario-medicacion
              :data.sync="medicacionConcomitante"
              :errors.sync="errors.medicacion_concomitante"
            />
          </v-form>
          <v-btn color="primary" @click="goToSubmit('formMedicacion')">
            Guardar cuestionario
          </v-btn>
          <v-btn text @click="step = step - 1"> Atras </v-btn>
        </v-stepper-content> -->
      </v-stepper>
    </div>

    <v-container
      v-if="!inicializando && noDisponible"
      class="d-flex justify-center"
    >
      <v-alert type="error">
        Este cuestionario no esta disponible en este momento.
      </v-alert>
    </v-container>

    <v-container v-if="completo" class="d-flex justify-center">
      <v-alert type="success">
        Se completó correctamente el cuestionario!
      </v-alert>
    </v-container>

    <v-dialog v-model="finalDialog" persistent max-width="500">
      <v-card>
        <div class="text-h5 font-weight-medium py-4 px-6">
          ¿Guardar cuestionario?
        </div>
        <v-card-text>
          <v-alert border="top" colored-border type="error" elevation="2">
            Estos datos no podrán modificarse
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="finalDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="submit"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog loading -->
    <v-dialog v-model="cargando" persistent width="300">
      <v-card color="secondary" dark>
        <v-card-text>
          <div class="my-2">Por favor espere...</div>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getPacienteToken } from "@/services/PacientesService";
import { registrarCuestionario } from "@/services/cuestionarioServices";
import moment from "moment";
import FormularioBPI from "@/components/FormularioBPI.vue";
import FormularioEQ5D from "@/components/FormularioEQ5D.vue";
import FormularioHADS from "@/components/FormularioHADS.vue";
import FormularioCalidadSuenio from "@/components/FormularioCalidadSuenio.vue";
import FormularioEventosAdversos from "@/components/FormularioEventosAdversos.vue";
// import FormularioMedicacion from "@/components/FormularioMedicacion.vue";

const initialBPI = {
  peor_dolor: null,
  dolor_leve: null,
  dolor_promedio: null,
  dolor_actual: null,
  actividad: null,
  animo: null,
  capacidad_andar: null,
  trabajo: null,
  relacion_social: null,
  suenio: null,
  disfrute_vida: null,
};

const initialHADS = {
  tenso_nervioso: null,
  disfrute: null,
  miedo: null,
  risas: null,
  preocupaciones: null,
  alegria: null,
  relajacion: null,
  lentitud: null,
  sensacion_mariposas: null,
  perdida_interes: null,
  inquieto: null,
  optimismo: null,
  panico: null,
  diversion: null,
};

const initialEQ5D = {
  movilidad: null,
  cuidado_personal: null,
  actividades_cotid: null,
  dolor_malestar: null,
  ansiedad_depresion: null,
  estado_salud: 50,
};

const initialCalidadSuenio = {
  horas_suenio: null,
  somnolencia: null,
  calidad_suenio: null,
};

export default {
  name: "RegistroCuestionarioView",
  components: {
    FormularioBPI,
    FormularioEQ5D,
    FormularioHADS,
    FormularioCalidadSuenio,
    FormularioEventosAdversos,
    // FormularioMedicacion,
  },
  props: {
    setSnackbar: {
      type: Function,
      FormularioCalidadSueniope: Function,
      required: true,
    },
    manejarErrorAxios: {
      type: Function,
      required: true,
    },
    setUrlPublica: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      paciente: null,
      pacienteId: null,
      token: null,
      visita: null,
      noDisponible: false,
      bpi: initialBPI,
      hads: initialHADS,
      eventosAdversos: {},
      existenEventos: false,
      calidadSuenio: initialCalidadSuenio,
      eq5d: initialEQ5D,
      medicacionConcomitante: [],
      errors: {},
      finalDialog: false,
      step: 1,
      inicializando: true,
      cargando: false,
      completo: false,
    };
  },
  computed: {
    fechaNacimiento: function () {
      return moment(this.paciente["fecha_nacimiento"]).format("DD/MM/YYYY");
    },
    fechaInicio: function () {
      return moment(this.paciente["fecha_inicio"]).format("DD/MM/YYYY");
    },
    datosPaciente: function () {
      return [
        { label: "Apellidos", value: this.paciente?.apellidos },
        { label: "Nombres", value: this.paciente?.nombres },
        { label: "D.N.I.", value: this.paciente?.dni },
        { label: "Pid", value: this.paciente?.pid },
        {
          label: "Género",
          value: { M: "Masculino", F: "Femenino" }[this.paciente?.genero],
        },
        {
          label: "Fecha de nacimiento",
          value: moment(this.paciente["fecha_nacimiento"]).format("DD/MM/YYYY"),
        },
        {
          label: "Fecha de inicio",
          value: moment(this.paciente["fecha_inicio"]).format("DD/MM/YYYY"),
        },
        { label: "Diagnostico", value: this.paciente?.diagnostico },
        {
          label: "Días de seguimiento",
          value: this.paciente?.datos_cuestionarios?.ultimo_dia_seguimiento,
        },
      ];
    },
  },
  methods: {
    nextStep(formRef) {
      if (this.$refs[formRef].validate()) {
        this.step += 1;
      }
    },
    goFromEventosAdversos(formRef) {
      if (this.$refs[formRef].validate()) {
        if (
          !this.existenEventos ||
          Object.keys(this.eventosAdversos).length > 0
        ) {
          this.finalDialog = true;
        }
      }
    },
    goToSubmit(formRef) {
      if (this.$refs[formRef].validate()) {
        this.finalDialog = true;
      }
    },
    submit() {
      const body = {
        paciente: this.paciente.pid,
        token: this.token,
        bpi: this.bpi,
        // medicacion_concomitante: this.medicacionConcomitante,
      };
      if ([1, 3, 5].includes(this.visita)) {
        body["eq5d"] = this.eq5d;
        body["calidad_suenio"] = this.calidadSuenio;
        if (this.visita > 1) {
          body["hads"] = this.hads;
        }
      }
      if (this.visita > 1) {
        body["eventos_adversos"] = this.eventosAdversos;
      }

      this.cargando = true;
      registrarCuestionario(body)
        .then(() => {
          this.completo = true;
          this.finalDialog = false;
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errors = { ...e.response.data };
            console.log(e.response.data);
          } else this.manejarErrorAxios(e);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    getDatosPaciente() {
      getPacienteToken(this.pacienteId, this.token)
        .then((response) => {
          this.paciente = response;
          this.visita = response.datos_cuestionarios.ultima_visita;
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) this.noDisponible = true;
          else this.manejarErrorAxios(e);
        })
        .finally(() => {
          this.inicializando = false;
        });
    },
  },
  created() {
    this.pacienteId = this.$route.query?.id || -1;
    this.token = this.$route.query?.token || undefined;
    this.visita = this.$route.query?.visita || -1;
    this.getDatosPaciente();
    this.setUrlPublica();
  },
};
</script>