<template>
  <div class="mx-3 mt-1 mb-7 mb-md-0">
    <v-col cols="12" lg="10" xl="6" class="pa-0 pa-md-3">
      <div
        v-for="pregunta in preguntasBloque1"
        :key="pregunta.model"
        class="mb-4 mb-md-6"
      >
        <p class="my-0 subtitle-2">
          {{ pregunta.label }}
        </p>
        <v-radio-group
          v-model="formData[pregunta.model]"
          row
          class="my-0"
          hide-details="auto"
          :rules="[rules.required]"
          :error-messages="dataErrors[pregunta.model]"
          @input="dataErrors[pregunta.model] = null"
        >
          <v-radio
            v-for="punto in Array(11).keys()"
            :key="`${pregunta.model}-${punto}`"
            :label="`${punto}`"
            :value="punto"
            :disabled="disabled"
          ></v-radio>
        </v-radio-group>
      </div>
      <v-divider></v-divider>
      <div class="mb-2 mb-md-3">
        <p class="my-0 subtitle-2">
          5. Marque con el número que describa en referencia a las últimas 24
          horas, cuanto ha perturbado el dolor su(s):
        </p>
      </div>
      <div
        v-for="pregunta in preguntasBloque2"
        :key="pregunta.model"
        class="mb-3 mb-md-6 ms-1 ms-md-3"
      >
        <p class="my-0 subtitle-2">
          {{ pregunta.label }}
        </p>
        <v-radio-group
          v-model="formData[pregunta.model]"
          row
          class="my-0"
          hide-details="auto"
          :rules="[rules.required]"
          :error-messages="dataErrors[pregunta.model]"
          @input="dataErrors[pregunta.model] = null"
        >
          <v-radio
            v-for="punto in Array(11).keys()"
            :key="`${pregunta.model}-${punto}`"
            :label="`${punto}`"
            :value="punto"
            :disabled="disabled"
          ></v-radio>
        </v-radio-group>
      </div>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "FormularioBPI",
  props: {
    data: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    errors: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      rules: {
        required: (v) => v !== null || "Campo obligatorio",
      },
      preguntasBloque1: [
        {
          label:
            "1. Evalúe su dolor marcando la casilla que mejor describa el PEOR dolor que haya sentido en las últimas 24 horas.",
          model: "peor_dolor",
        },
        {
          label:
            "2. Evalúe su dolor marcando la casilla que mejor describa el dolor MAS LEVE que haya sentido en las últimas 24 horas.",
          model: "dolor_leve",
        },
        {
          label:
            "3. Evalúe su dolor marcando la casilla que mejor describa el dolor que haya sentido en PROMEDIO en las últimas 24 horas",
          model: "dolor_promedio",
        },
        {
          label:
            "4. Evalúe su dolor marcando la casilla que indique dolor que siente AHORA MISMO",
          model: "dolor_actual",
        },
      ],
      preguntasBloque2: [
        { label: "1. Actividad general", model: "actividad" },
        { label: "2. Estado de ánimo", model: "animo" },
        { label: "3. Capacidad de andar", model: "capacidad_andar" },
        {
          label:
            "4. Trabajo normal (incluye tanto el trabajo fuera de casa como el doméstico)",
          model: "trabajo",
        },
        {
          label: " 5. Relaciones con otras personas",
          model: "relacion_social",
        },
        { label: "6. Sueño", model: "suenio" },
        { label: "7. Disfrute de la vida", model: "disfrute_vida" },
      ],
    };
  },
  computed: {
    formData: {
      get() {
        return this.data || {};
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
    dataErrors: {
      get() {
        return this.errors || {};
      },
      set(value) {
        this.$emit("update:errors", value);
      },
    },
  },
};
</script>