const nCuestionario = [
  "1 - Basal/Inicial",
  "2 - Mes 3",
  "3 - Mes 6",
  "4 - Mes 9",
  "5 - Mes 12",
  "6 - Mes 15",
  "7 - Mes 18",
  "8 - Mes 21",
  "9 - Mes 24",
];

const seguimiento = [
  "0 - Basal/Inicial",
  "3",
  "6",
  "9",
  "12",
  "15",
  "18",
  "21",
  "24",
];

const movilidad = [
  { value: 1, text: " No tengo problemas para caminar" },
  { value: 2, text: "Tengo algunos problemas para caminar" },
  { value: 3, text: "Tengo que estar en cama" },
];

const cuidados = [
  { value: 1, text: "No tengo problemas con el cuidado personal" },
  { value: 2, text: "Tengo algunos problemas para lavarme o vestirme solo" },
  { value: 3, text: "Soy incapaz de lavarme o vestirme solo" },
];

const ansiedad = [
  { value: 1, text: "No estoy ansioso/a ni deprimido/a" },
  { value: 2, text: "Estoy moderadamente ansioso/a o deprimido/a" },
  { value: 3, text: "Estoy muy ansioso/a o deprimido/a" },
];

const dolor = [
  { value: 1, text: "No tengo dolor ni malestar" },
  { value: 2, text: "Tengo moderado dolor o malestar" },
  { value: 3, text: "Tengo mucho dolor o malestar" },
];

const actividades = [
  { value: 1, text: "No tengo problemas para realizar actividades cotidianas" },
  {
    value: 2,
    text: "Tengo algunos problemas para realizar mis actividades cotidianas",
  },
  { value: 3, text: "Soy incapaz de realizar mis actividades cotidianas" },
];

const vitalidad = [
  {
    value: 1,
    text: "Siempre",
  },
  {
    value: 2,
    text: "Casi siempre",
  },
  {
    value: 3,
    text: "Algunas veces",
  },
  {
    value: 4,
    text: "Sólo alguna vez",
  },
  {
    value: 5,
    text: "Nunca",
  },
];

const desanimo = [
  {
    value: 1,
    text: "Nunca",
  },
  {
    value: 2,
    text: "Sólo alguna vez",
  },
  {
    value: 3,
    text: "Algunas veces",
  },
  {
    value: 4,
    text: "Casi siempre",
  },
  {
    value: 5,
    text: "Siempre",
  },
];

const varios = [
  {
    value: 1,
    text: "Ninguno",
  },
  {
    value: 2,
    text: "Pocos",
  },
  {
    value: 3,
    text: "Algunos",
  },
  {
    value: 4,
    text: "Muchos",
  },
  {
    value: 5,
    text: "Muchísimos",
  },
];

const miedo = [
  {
    value: 1,
    text: "Nada de miedo",
  },
  {
    value: 2,
    text: "No mucho miedo",
  },
  {
    value: 3,
    text: "Bastante miedo",
  },
  {
    value: 4,
    text: "Mucho miedo",
  },
  {
    value: 5,
    text: "Muchísimo miedo",
  },
];

const calidad = [
  {
    value: 1,
    text: "Muy bien, difícilmente hubiera podido irme mejor",
  },
  {
    value: 2,
    text: "Bastante bien",
  },
  {
    value: 3,
    text: "Bien y mal a partes iguales",
  },
  {
    value: 4,
    text: "Bastante mal",
  },
  {
    value: 5,
    text: "Muy mal, difícilmente hubiera podido irme peor",
  },
];

const reacciones = [
  {
    value: 1,
    text: "LEVE (No interfieren con mis actividades diarias)",
  },
  {
    value: 2,
    text: "MODERADO (Pueden interferir con las actividades diarias)",
  },
  {
    value: 3,
    text: "SEVERO (Interrumpen las actividades diarias)",
  },
];

const cave = [
  {
    value: 1,
    text: "Muy mala",
  },
  {
    value: 2,
    text: "Mala",
  },
  {
    value: 3,
    text: "Regular",
  },
  {
    value: 4,
    text: "Buena",
  },
  {
    value: 5,
    text: "Muy buena",
  },
];

const departamentos = [
  "BELGRANO",
  "COCHINOCA",
  "EL CARMEN",
  "HUMAHUACA",
  "LEDESMA",
  "PALPALA",
  "RINCONADA",
  "SAN ANTONIO",
  "SAN PEDRO",
  "SANTA BARBARA",
  "SANTA CATALINA",
  "SUSQUES",
  "TILCARA",
  "TUMBAYA",
  "VALLE GRANDE",
  "YAVI",
];

const centroAtencion = ["HOSPITAL", "ISJ", "OS-PREPAGA", "PARTICULAR"];

const codigosDiagnosticoCIE = [
  { key: "G500", label: "G500 (neuralgia del trigémino)" },
  { key: "G530", label: "G530 (neuralgia post herpes zoster)" },
  { key: "G546", label: "G546 (síndrome del miembro fantasma con dolor)" },
  {
    key: "G558",
    label:
      "G558 (compresiones de las raíces y plexos nerviosos en otras enfermedades clasificadas en otra parte)",
  },
  { key: "G608", label: "G608 (otras neuropatías hereditarias e idiopáticas)" },
  { key: "G610", label: "G610 (síndrome de Guillen Barre)" },
  { key: "G621", label: "G621 (polineuropatía alcohólica)" },
  { key: "G622", label: "G622 (polineuropatía debido a otro agente tóxico)" },
  { key: "G629", label: "G629 (polineuropatía no especificada)" },
  {
    key: "G630",
    label:
      "G630 (polineuropatía en enfermedades infecciosas y parasitarias clasificadas en otra parte)",
  },
  { key: "G632", label: "G632 (polineuropatía diabética)" },
  {
    key: "G633",
    label:
      "G633 (polineuropatía en otras enfermedades endócrinas y metabólicas)",
  },
  { key: "G634", label: "G634 (polineuropatía en deficiencia nutricional)" },
  {
    key: "G635",
    label: "G635 (polineuropatía en trastornos del tejido conectivo sistémico)",
  },
  {
    key: "G636",
    label: "G636 (polineuropatía en otros trastornos osteomusculares)",
  },
  {
    key: "G638",
    label:
      "G638 (polineuropatía en otras enfermedades clasificadas en otra parte)",
  },
  {
    key: "T941",
    label:
      "T941 (secuelas de traumatismos de regiones no especificadas el cuerpo)",
  },
  { key: "otros", label: "Otros" },
];

const grupoDiagnostico = ["Epilepsia Refractaria"];

const formulaAplicada = ["CBD10"];

module.exports = {
  nCuestionario,
  seguimiento,
  movilidad,
  cuidados,
  ansiedad,
  dolor,
  actividades,
  vitalidad,
  desanimo,
  varios,
  miedo,
  calidad,
  reacciones,
  cave,
  departamentos,
  centroAtencion,
  codigosDiagnosticoCIE,
  grupoDiagnostico,
  formulaAplicada,
};
