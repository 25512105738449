<template>
  <v-container fluid>
    <v-container fluid>
      <div class="font-weight-medium mb-3">
        1- Notó síntomas NUEVOS en el último trimestre:
      </div>
      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <v-select
            placeholder="Seleccione una opción*"
            :items="['SI', 'NO']"
            item-text="text"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="sintomasNuevos"
            :rules="rules.required"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
    </v-container>
    <v-container fluid v-if="sintomasNuevos === 'SI'">
      <v-row>
        <v-col cols="12" class="pb-sm-5">
          <div class="font-weight-medium">
            2- Si su respuesta fue SI, complete el siguiente cuestionario
            (Seleccione solamente el o los síntomas en la situación que
            corresponde):
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Somnolencia (Es una tendencia al sueño)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.somnolencia"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Mareos (Es una sensación de inestabilidad física)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.mareos"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Boca seca (Es una sensación de que no hay suficiente saliva en su
          boca)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.boca_seca"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Ansiedad (Sensación de temor, amenaza, inquietud y/o tensión
          constante)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.ansiedad"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Náuseas (Son las ganas de vomitar o hipersalivación o hipo)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.nauseas"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Mayor difucultad para concentrarse, recordar datos y/o hacer tareas
          mentales
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.dificultad_concentracion"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Disminución del apetito (Es la disminución de las ganas de comer)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.disminucion_apetito"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Diarrea (Son evaciaciones líquidas, 3 o más veces por día)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.diarrea"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0"> Mayor cansancio / fatiga </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.mayor_cansancio"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Aumento de los niveles de las transaminasas (En un análisis de sangre)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.aumento_transaminasas"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Euforia (Es una sensación de optimismo exagerado)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.euforia"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0"> Visión borrosa </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.vision_borrosa"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0"> Dolor de cabeza </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.dolor_cabeza"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Mareos o presión arterial baja al incorporarse
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.presion_baja"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Paranoia (Constante sensación de temor o desconfianza hacia los demás
          o al exterior)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.paranoia"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Depresión (Constante tristesa / desanimo)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.depresion"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Falta de coordinación motora
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.falta_cordinacion_motora"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0"> Palpitaciones </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.palpitaciones"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Vómitos recuerrentes (3 o más veces al día)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.vomitos"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Alucinaciones (Ver, escuchar o sentir cosas que no existen)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.alucinaciones"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Delirio (Pensamientos o habla incoherentes)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.delirio"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          Excitación psicomotriz (Gritos, llantos, o el habla descontrolados)
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <v-select
            :items="opciones.reacciones"
            item-text="text"
            item-value="value"
            outlined
            clearable
            hide-details="auto"
            dense
            v-model="datos.exitacion_psicomotriz"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0"> Otros (especifique): </v-col>
        <v-col cols="12" sm="6" class="pbt-0">
          <v-text-field
            placeholder="Ingrese la descripción"
            clearable
            outlined
            hide-details="auto"
            dense
            v-model="datos.otro"
            :rules="rules.noBlanco"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import options from "./options";

export default {
  name: "SubcuestionarioReacciones",
  props: {
    datosReacciones: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        noBlanco: [
          (v) =>
            !v ||
            (v.length > 0 && v.trim() !== "") || "No puede estar en blanco.",
        ],
      },
      sintomasNuevos:
        this.datosReacciones.existen_sintomas === undefined
          ? ""
          : this.datosReacciones.existen_sintomas
          ? "SI"
          : "NO",
      opciones: options,
    };
  },
  computed: {
    datos: {
      get() {
        return this.datosReacciones;
      },
      set(nuevoValor) {
        this.$emit("update:datosReacciones", nuevoValor);
      },
    },
  },
};
</script>