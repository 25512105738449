const formularioHADS = {
    tenso_nervioso: null,
    disfrute: null,
    miedo: null,
    risas: null,
    preocupaciones: null,
    alegria: null,
    relajacion: null,
    lentitud: null,
    sensacion_mariposas: null,
    perdida_interes: null,
    inquieto: null,
    optimismo: null,
    panico: null,
    diversion: null
};

const formularioDN4 = {
    caracteristica_dolor: {
      quemazon: null,
      frio_doloroso: null,
      descargas_electricas: null,
    },
    sintoma_zona_dolor: {
      hormigueo: null,
      pinchazos: null,
      entumecimiento: null,
      escozor: null,
    },
    signo_zona_dolor: {
      hipotesia_tacto: null,
      hipotesia_pinchazo: null,
    },
    motivo_dolor: {
      roce: null,
    }
};

const formularioCriteriosInclusion = {
    func_hepat_ren_card: false,
    autodeterminacion: false,
    factibilidad: false,
    pac_ambulatorio: false
  };

const formularioCriteriosExclusion = {
    cardiopatia_descomp: true,
    hipertension_descomp: true,
    antec_psicosis: true,
    insuficiencia_renal: true,
    insuficiencia_hepatica: true,
    en_otra_investigacion: true,
    afeccion_confusa: true,
    embarazo_lactancia: true,
    consumo_72hs: true
};

const formularioPlanAnalgesico = {
    opioides_fuertes: false,
    opioides_debiles: false,
    antiinflamatorios: false,
    paracetamol: false,
    anticonvulsivantes: false,
    antiarritmicos: false,
    antagonistas_nmetildaspartato: false,
    corticoides: false,
    otros_medicamentos: "",
    otras_intervenciones: ""
};

const formularioIntervenciones = {
    firma: false,
    receta: false,
    prescripcion: false
};

module.exports = {
    formularioHADS,
    formularioDN4,
    formularioCriteriosInclusion,
    formularioCriteriosExclusion,
    formularioPlanAnalgesico,
    formularioIntervenciones
};