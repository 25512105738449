<template>
  <div class="ma-0 mb-5">
    <v-col cols="12" md="8" lg="7" class="pa-0 pa-md-3">
      <div v-for="criterio in criteriosInclusion" :key="criterio.model">
        <div class="d-flex align-center justify-space-between">
          <p class="my-0">{{ criterio.label }}</p>
          <v-checkbox
            v-model="formData[criterio.model]"
            label="SI"
            class="mt-0 my-0"
            hide-details="auto"
            @change="validarCriteriosInclusion"
          ></v-checkbox>
        </div>
        <v-divider class="mt-1"></v-divider>
      </div>
    </v-col>
  </div>
</template>

<script>
const initialData = {
  func_hepat_ren_card: false,
  autodeterminacion: false,
  factibilidad: false,
  pac_ambulatorio: false,
};

export default {
  name: "FormularioCriteriosInclusion",
  props: {
    data: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
    excludeBy: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
  },
  data() {
    return {
      criteriosInclusion: [
        {
          label: "Funcionalidad hepática, renal y cardíaca",
          model: "func_hepat_ren_card",
        },
        {
          label: "Autodeterminación para participar",
          model: "autodeterminacion",
        },
        {
          label:
            "Factibilidad para participar (su dispositivo o en consultorio)",
          model: "factibilidad",
        },
        { label: "Paciente ambulatorio", model: "pac_ambulatorio" },
      ],
    };
  },
  methods: {
    validarCriteriosInclusion() {
      let isExcluded = false;
      for (const key in this.data) {
        if (this.data[key] !== true) {
          isExcluded = true;
          break;
        }
      }
      this.$emit("update:excludeBy", isExcluded ? "criteriosInclusion" : null);
    },
  },
  computed: {
    formData: {
      get() {
        return this.data || initialData;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
  },
};
</script>