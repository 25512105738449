<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-container fluid class="white pt-md-2 pa-0 header">
      <v-btn class="mx-2" dark color="orange darken-4" @click="$router.back()">
        <v-icon dark> mdi-arrow-left </v-icon>
        Volver
      </v-btn>
      
      <v-divider></v-divider>
    </v-container>
    <v-stepper v-model="step" vertical class="rounded-0">

      <v-stepper-step :complete="step > 1" step="1">
        Escala HADS de Ansiedad y depresión
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-form ref="formHADS">
          <formularioHADS :data.sync="data.hads" :excludeBy.sync="excludeBy" :disabled="false"/>
        </v-form>
        <div
          v-if="excludeBy === 'hads'"
          class="my-0 red--text d-flex align-center mb-4"
        >
          <v-icon left dark color="red"> mdi-alert-circle </v-icon>
          {{ motivosExclusion.hads }}
        </div>
        <v-btn v-if="excludeBy === 'hads'" color="error" @click="avisoCancelacion = true">
          Cancelar
        </v-btn>
        <v-btn v-if="!(excludeBy === 'hads')" color="primary" @click="goToDN4('formHADS')">
          Continuar
        </v-btn>
        
      </v-stepper-content>      

      <v-stepper-step :complete="step > 2" step="2">
        Diagnóstico de dolor neuropático
        <small>Cuestionario DN4</small>
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-form ref="formDN4">
          <FormularioDN4 :data.sync="data.dn4" :excludeBy.sync="excludeBy" :disabled="false"/>
        </v-form>
        <div
          v-if="excludeBy === 'dn4'"
          class="my-0 red--text d-flex align-center mb-4"
        >
          <v-icon left dark color="red"> mdi-alert-circle </v-icon>
          {{ motivosExclusion.dn4 }}
        </div>
        <v-btn v-if="excludeBy === 'dn4'" color="error" @click="avisoCancelacion = true">
          Cancelar
        </v-btn>
        <v-btn v-if="!(excludeBy === 'dn4')" color="primary" @click="goToNRS('formDN4')">
          Continuar
        </v-btn>
        <v-btn text @click="step = step - 1"> Atras </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" step="3">
        Escala de dolor (NRS)
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-form ref="formNRS">
          <FormularioNRS :data.sync="data.nrs" :excludeBy.sync="excludeBy" />
        </v-form>
        <div
          v-if="excludeBy === 'nrs'"
          class="my-0 red--text d-flex align-center mb-4"
        >
          <v-icon left dark color="red"> mdi-alert-circle </v-icon>
          {{ motivosExclusion.nrs }}
        </div>

        <v-btn v-if="excludeBy === 'nrs'" color="error" @click="avisoCancelacion = true">
          Cancelar
        </v-btn>

        <v-btn v-if="!(excludeBy === 'nrs')"  color="primary" @click="goToCriteriosInclusion">
          Continuar
        </v-btn>

        <v-btn text @click="step = step - 1"> Atras </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 4" step="4">
        Criterios de inclusión
        <small>requiere marcar SI en todos los items</small>
      </v-stepper-step>

      <v-stepper-content step="4">
        <v-form ref="formCriteriosInclusion">
          <formulario-criterios-inclusion
            :data.sync="data.criterios_inclusion"
            :excludeBy.sync="excludeBy"
          />
        </v-form>
        <div
          v-if="excludeBy === 'criteriosInclusion'"
          class="my-0 red--text d-flex align-center mb-4"
        >
          <v-icon left dark color="red"> mdi-alert-circle </v-icon>
          {{ motivosExclusion.criteriosInclusion }}
        </div>

        <v-btn v-if="excludeBy === 'criteriosInclusion'" color="error" @click="avisoCancelacion = true">
          Cancelar
        </v-btn>

        <v-btn v-if="!(excludeBy === 'criteriosInclusion')" color="primary" @click="goToCriteriosExclusion">
          Continuar
        </v-btn>
        <v-btn text @click="step = step - 1"> Atras </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 5" step="5">
        Criterios de exclusión
        <small>requiere marcar NO en todos los items</small>
      </v-stepper-step>

      <v-stepper-content step="5">
        <v-form ref="formCriteriosExclusion">
          <formulario-criterios-exclusion
            :data.sync="data.criterios_exclusion"
            :excludeBy.sync="excludeBy"
          />
        </v-form>
        <div
          v-if="excludeBy === 'criteriosExclusion'"
          class="my-0 red--text d-flex align-center mb-4"
        >
          <v-icon left dark color="red"> mdi-alert-circle </v-icon>
          {{ motivosExclusion.criteriosExclusion }}
        </div>

        <v-btn v-if="excludeBy === 'criteriosExclusion'" color="error" @click="avisoCancelacion = true">
          Cancelar
        </v-btn>

        <v-btn v-if="!(excludeBy === 'criteriosExclusion')" color="primary" @click="goToPlanAnalgesico">
          Continuar
        </v-btn>
        <v-btn text @click="step = step - 1"> Atras </v-btn>
      </v-stepper-content>

      

      <v-stepper-step :complete="step > 6" step="6">
        Plan analgésico, en la última semana
        <small
          >marcar con X la medicación y/o intervención que correspondan (puede
          marcar más de 1)</small
        >
      </v-stepper-step>

      <v-stepper-content step="6">
        <v-form ref="formPlanAnalgesico">
          <formulario-plan-analgesico 
            :data.sync="data.plan_analgesico" 
            :disabled="false"
            :excludeBy.sync="excludeBy"
          />
        </v-form>

        <div
          v-if="excludeBy === 'planAnalgesico'"
          class="my-0 red--text d-flex align-center mb-4"
        >
          <v-icon left dark color="red"> mdi-alert-circle </v-icon>
          Debe indicar al menos una medicación o intervención
        </div>

        <v-btn v-if="excludeBy === 'planAnalgesico'" color="error" @click="avisoCancelacion = true">
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          @click="goToIntervenciones()"
          v-if="!(excludeBy === 'planAnalgesico')"
        >
          Continuar
        </v-btn>
        <v-btn text @click="step = step - 1"> Atras </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 7" step="7">
        Intervenciones médicas del plan de trabajo:
        <small>Debe marcar todos los ítems para avanzar en el registro</small>
      </v-stepper-step>

      <v-stepper-content step="7">
        <v-form ref="formIntervenciones">
          <formulario-intervenciones :data.sync="data.intervenciones_medicas" />
        </v-form>

        <v-btn color="primary" @click="goToDatosPersonales('formIntervenciones')">
          Continuar
        </v-btn>
        <v-btn text @click="step = step - 1"> Atras </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 8" step="8">
        Datos personales
      </v-stepper-step>
      <v-stepper-content step="8">
        <v-form ref="formDatosPersonales">
          <formulario-paciente :data.sync="data" :excludeBy.sync="excludeBy" />
        </v-form>
        <div
          v-if="excludeBy === 'edad'"
          class="my-0 red--text d-flex align-center mb-4"
        >
          <v-icon left dark color="red"> mdi-alert-circle </v-icon>
          {{ motivosExclusion.edad }}
        </div>

        <v-btn v-if="(excludeBy === 'edad')" color="error" @click="avisoCancelacion = true">
          Cancelar
        </v-btn>

        <v-btn v-if="!(excludeBy === 'edad')" color="primary" @click="goToSubmit('formDatosPersonales')">
          Continuar
        </v-btn>
        <v-btn text @click="step = step - 1"> Atras </v-btn>
      </v-stepper-content>

    </v-stepper>
    
    <!-- Dialog confirmar Registro -->
    <v-dialog v-model="finalDialog" persistent max-width="500">
      <v-card>
        <div class="text-h5 font-weight-medium py-4 px-6">
          ¿Está seguro de registrar los datos de este paciente?
        </div>
        <v-card-text>
          <v-alert border="top" colored-border type="error" elevation="2">
            Estos datos no podrán modificarse
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="finalDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="submit"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog confirmar Cancelación -->
    <v-dialog v-model="avisoCancelacion" persistent max-width="450">
      <v-card>
        <div class="text-h5 font-weight-medium py-4 px-6">
          ¿Está seguro de cancelar el registro de este paciente?
        </div>
        <v-card-text>
          <v-alert border="top" colored-border type="error" elevation="2">
            Una vez cerrada esta ventana, los datos ingresados se perderán.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="avisoCancelacion = false">
            Continuar registro
          </v-btn>
          <v-btn color="green darken-1" text @click="cancelRegister"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog loading -->
    <v-dialog v-model="cargando" persistent width="300">
      <v-card color="secondary" dark>
        <v-card-text>
          <div class="my-2">Por favor espere...</div>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  crearPaciente,
} from "@/services/PacientesService";
import FormularioPaciente from "@/components/FormularioPaciente.vue";
import FormularioDN4 from "@/components/FormularioDN4.vue";
import FormularioNRS from "@/components/FormularioNRS.vue";
import FormularioCriteriosInclusion from "@/components/FormularioCriteriosInclusion.vue";
import FormularioCriteriosExclusion from "@/components/FormularioCriteriosExclusion.vue";
import FormularioHADS from "@/components/FormularioHADS.vue";
import FormularioPlanAnalgesico from "@/components/FormularioPlanAnalgesico.vue";
import FormularioIntervenciones from "@/components/FormularioIntervenciones.vue";
import moment from "moment";
import formulariosModels from "@/components/models"

const initialData = {
  dni: null,
  nombres: null,
  apellidos: null,
  fecha_nacimiento: null,
  genero: null,
  departamento: null,
  telefono: null,
  diagnostico: null,
  /* Inicialización de modelos de cuestionarios */
  dn4: formulariosModels.formularioDN4,
  nrs: 0,
  criterios_inclusion: formulariosModels.formularioCriteriosInclusion,
  criterios_exclusion: formulariosModels.formularioCriteriosExclusion,
  plan_analgesico: formulariosModels.formularioPlanAnalgesico,
  intervenciones_medicas: formulariosModels.formularioIntervenciones,
  hads: formulariosModels.formularioHADS,
};

export default {
  name: "RegistroPacientesView",
  components: {
    FormularioPaciente,
    FormularioDN4,
    FormularioNRS,
    FormularioCriteriosInclusion,
    FormularioCriteriosExclusion,
    FormularioHADS,
    FormularioPlanAnalgesico,
    FormularioIntervenciones,
  },
  props: {
    setSnackbar: {
      type: Function,
      required: true,
    },
    manejarErrorAxios: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      data: {} ,//{ ...initialData, fecha_inicio: moment().format("YYYY-MM-DD") },
      errors: {},
      estudio_piloto: null,
      excludeBy: null,
      finalDialog: false,
      step: 1,
      motivosExclusion: {
        edad: "El paciente no cumple con la edad de ingreso al estudio (21 a 70 años)",
        dn4: 'El paciente no cumple con al menos 4 respuestas marcadas "Si" para ingresar al estudio.',
        nrs: "El paciente require un número de dolor promedio de 4 o más para ingresar al estudio.",
        criteriosInclusion:
          "El paciente no cumple los criterios de inclusión para ingresar al estudio.",
        criteriosExclusion:
          "El paciente no debe cumplir ningún criterio de exclusión o de lo contrario no puede ingresar al estudio.",
        hads: "La puntuacion HADS del paciente debe ser menor a 20 o de lo contrario no puede ingresar al estudio.",
      },
      cargando: false,
      avisoCancelacion: false
    };
  },
  methods: {
    goToDN4(formRef) {
      if (this.$refs[formRef].validate()) {
        this.step += 1;
      }
    },
    goToNRS(formRef) {
      if (this.$refs[formRef].validate()) {
        this.step += 1;
      }
    },
    goToCriteriosInclusion() {
      // Validación formNRS
      if (this.data.nrs < 4) {
          this.excludeBy = "nrs";
          return;
      }       
      
      this.step += 1;
    },
    goToCriteriosExclusion() {

      // Validación formCriteriosInclusion
      for (const key in this.data.criterios_inclusion) {
          if (this.data.criterios_inclusion[key] !== true) {
            this.excludeBy = "criteriosInclusion";
            return;
          }
        }              
      this.step += 1;
    },
    goToDatosPersonales(formRef) {
      if (this.$refs[formRef].validate()) {
        this.step += 1;        
      }
    },
    goToPlanAnalgesico() {
      for (const key in this.data.criterios_exclusion) {
            if (this.data.criterios_exclusion[key] !== false) {
              this.excludeBy = "criteriosExclusion";
              return;
            }
      } 
      this.step += 1;     
    },
    goToIntervenciones() {
      //check if any item is not null
      let countFalse = 0;
      let countString = 0;
      const {otras_intervenciones, otros_medicamentos, ...booleanos} = this.data.plan_analgesico;
      for (const key in booleanos) {
        if (booleanos[key] === false) { countFalse += 1; }
      }
      if (otras_intervenciones === '') { countString += 1; }
      if (otros_medicamentos === '') { countString += 1; }
      console.log("data: ", this.data.plan_analgesico)
      console.log("countFalse: ", countFalse, "countString: ", countString)
      if (countFalse == 8 && countString == 2) {
        this.excludeBy = 'planAnalgesico'
      }
      else{
        this.step += 1;
        return;
      }
    },
    cancelRegister() {
      /* this.data = {};
      this.data = JSON.parse(JSON.stringify(initialData));
      console.log("Data reset: " , this.data) */
      this.$router.back();
    },
    goToSubmit(formRef) {
      if (this.$refs[formRef].validate()) {
        this.finalDialog = true;
      }
    },
    submit() {
      this.data.telefono = Number(this.data.telefono);
      const body = { ...this.data };
      if (body.diagnostico === "otros")
        body.diagnostico = body["otro_diagnostico"];
      
      
      this.cargando = true;
      console.log(body)
      crearPaciente(body)
        .then((data) => {
          console.log("Respuesta del servidor: ", data)

          let OPCIONES_ESTUDIO_PILOTO = [
              "DOLOCANN 1",
              "DOLOCANN 2"
          ];
          let estudio = data.estudio_piloto;
          let rama = OPCIONES_ESTUDIO_PILOTO[estudio - 1];
          
          this.$router.replace({ name: "pacientes" });
          this.setSnackbar({
            activo: true,
            color: "success",
            texto: "El paciente fué registrado correctamente y ha sido asignado a la rama "+ rama,
          });
        })
        .catch((e) => {
          if (e.response && e.response.status === 400)
            this.errors = { ...e.response.data };
          else this.manejarErrorAxios(e);
        })
        .finally(() => {
          this.cargando = false;
        });
    }
  },
  created() {  
    this.data = JSON.parse(JSON.stringify(initialData));
    this.data.telefono = Number(this.data.telefono);
    this.data["fecha_inicio"] = moment().format("YYYY-MM-DD")
    console.log("@created:: Data inicial: ", this.data)
  },
};
</script>

<style scoped>
.header {
  background-color: red;
  position: sticky;
  top: 56px;
  z-index: 1;
}
v-btn{
  margin: 0 10px;
}
</style>