import moment from 'moment'

export function formatearFecha(fecha) {
  return moment(fecha).format('DD/MM/YYYY')
}

export function getDateWithFormat (date, format = 'DD/MM/YYYY') {
  return moment(date).add(3, 'hours').format(format)
}

export function getDateForPicker (date, format = 'YYYY-MM-DD') {
  return moment(date).add(3, 'hours').format(format)
}
