<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-row class="ma-0">
      <v-col
        cols="12"
        md="2"
        class="pa-0 mt-8 mb-5 mb-md-0"
        v-if="rol === 'EST'"
      >
        <v-card class="card-table me-md-5">
          <v-container class="ma-0" d-flex flex-column>
            <v-subheader>REPORTES</v-subheader>
            <v-btn
              text
              rounded
              class="my-1"
              v-for="reporte in reportes"
              :key="reporte.name"
              @click.stop="solicitarReporte(reporte)"
            >
              {{ reporte.name }}
            </v-btn>
            <v-subheader>OTROS REPORTES</v-subheader>
            <v-btn
              text
              rounded
              class="my-1 text-wrap"
              @click.stop="solicitarEncuestasAtrasadas"
            >
              Encuestas atrasadas
            </v-btn>
            <v-btn
              text
              rounded
              class="my-1 text-wrap"
              @click.stop="solicitarCorreccionesPendientes"
            >
              Correcciones pendientes
            </v-btn>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="rol === 'MED'">
        <div class="d-none d-sm-block">
          <p class="font-weight-black text-h5 white--text text-center">
            Bienvenido al programa de registro médico DOLOCANN
          </p>
        </div>
        <div class="text-center d-flex d-sm-block flex-column align-center">
          <v-btn
            class="me-sm-3 mb-sm-0 mb-3"
            rounded
            color="purple"
            dark
            @click="registrarPaciente"
          >
            <v-icon left dark> mdi-account-plus </v-icon>
            Registrar paciente
          </v-btn>
          <v-btn rounded color="purple" dark @click="seguimientoPaciente">
            <v-icon left dark> mdi-transit-connection-variant </v-icon>
            Seguimiento
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" :md="rol === 'EST' ? 10 : 12" class="pa-0 mt-8">
        <tabla-pagina-component
          tituloTabla="Listado de pacientes"
          :accionBoton="registrarPaciente"
          :headers="headers"
          :listaItems="listadoPacientes"
          :estaCargando="estaCargando"
          
        >
          <!-- Slots tabla -->
          <template v-slot:[`item.nombresyapellidos`]="{ item }">
            <div>{{ item.apellidos }}, {{ item.nombres }}</div>
          </template>

          <template v-slot:[`item.incluido`]="{ item }">
            <div class="text-center">
              <v-chip v-if="item.incluido" class="success font-weight-bold"
                >SI</v-chip
              >
              <v-chip v-else class="error font-weight-bold">NO</v-chip>
            </div>
          </template>

          <template v-slot:[`item.excluido`]="{ item }">
            <div class="text-center" v-if="item.incluido">
              <v-chip
                v-if="item.datos_cuestionarios&&item.datos_cuestionarios.excluido"
                class="error font-weight-bold"
                @click="mostrarDialogExclusion(item)"
                >SI</v-chip
              >
              <v-chip v-else class="success font-weight-bold">NO</v-chip>
            </div>
            <div class="text-center" v-else>
              <v-chip class="error font-weight-bold">NO</v-chip>
            </div>
          </template>

          <template v-slot:[`item.fecha_inicio`]="{ item }">
            {{ fechaFormateada(item.fecha_inicio) }}
          </template>

          <template v-slot:[`item.fecha_ultimo_cuestionario`]="{ item }">
            {{
              item.datos_cuestionarios.fecha_ultimo_cuestionario
                ? fechaFormateada(
                    item.datos_cuestionarios.fecha_ultimo_cuestionario
                  )
                : "-"
            }}
          </template>

          <template v-slot:[`item.fecha_ultima_encuesta`]="{ item }">
            <div v-if="item.fecha_ultima_encuesta">
              {{ fechaFormateada(item.fecha_ultima_encuesta) }}
            </div>
          </template>

          <template v-slot:[`item.salida`]="{ item }">
            <v-chip v-if="item.salida" class="error font-weight-bold"
              >SI</v-chip
            >
            <div v-else>NO</div>
          </template>

          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn
              class="ma-1"
              rounded
              depressed
              color="primary"
              dark
              @click="mostrarMasDatos(item)"
              v-if="rol === 'EST'"
            >
              Mas datos
            </v-btn>
            <v-btn
              class="ma-1 white--text"
              rounded
              depressed
              color="orange darken-4"
              
              @click="verEncuestas(item)"
            >
              Ver encuestas
            </v-btn>
          </template>

          <template v-slot:[`item.mas_acciones`]="{ item }">
            <v-icon class="mr-2" color="info"> mdi-eye-plus </v-icon>
            <v-icon class="mr-2" @click="editarPaciente(item)" color="warning">
              mdi-pencil
            </v-icon>
            <v-icon
              @click="eliminarPaciente(item)"
              color="red"
              v-if="rol === 'ADM'"
            >
              mdi-delete
            </v-icon>
          </template>
        </tabla-pagina-component>
      </v-col>
    </v-row>

    <!-- Formulario de pacientes -->
    <form-paciente-component
      v-if="mostrarFormPaciente"
      :showFormPaciente.sync="mostrarFormPaciente"
      :esEdicion="!!dataPaciente"
      :setSnackbar="setSnackbar"
      :manejarErrorAxios="manejarErrorAxios"
      :dataPaciente.sync="dataPaciente"
      :actualizarListaPacientes="actualizarListaPacientes"
    />

    <!-- Dialog para eliminar paciente -->
    <v-dialog
      v-model="dialogEliminar"
      v-if="dataPaciente"
      persistent
      max-width="500"
    >
      <v-card>
        <div class="text-h6 text-center pa-5">
          ¿Esta seguro que desea eliminar los datos del paciente
          {{ `${dataPaciente.apellidos} ${dataPaciente.nombres}` }}?
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancelarEliminacion">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmarEliminacion">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para mostrar causas de exclusion -->
    <v-dialog
      v-model="dialogExclusion"
      v-if="dataPaciente"
      persistent
      max-width="500"
    >
      <v-card>
        <v-toolbar dark color="error">
          <v-toolbar-title>Paciente excluído</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cerrarDialogExclusion">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="text text-left pa-4">
          <div class="font-weight-bold pe-3 d-inline">Pid:</div>
          <div class="d-inline">{{ dataPaciente.pid }}</div>
        </div>
        <div class="text text-left pa-4">
          <div class="font-weight-bold pe-3 d-inline">Causa de exclusión:</div>
          <div class="d-inline">{{ causaExclusion }}</div>                    
        </div>
        <div class="text text-left pa-4">
          <div class="font-weight-bold pe-3 d-inline">Fecha de exclusión:</div>
          <div class="d-inline">{{ fechaFormateada(dataPaciente.datos_cuestionarios.fecha_intervension_medica) }}</div>                    
        </div>
        <div class="text text-left pa-4" v-if="dataPaciente.datos_cuestionarios.causa_exclusion == 1">
          <div class="font-weight-bold pe-3 d-inline">Fecha de muerte: </div>
          <div class="d-inline">{{ fechaFormateada(dataPaciente.datos_cuestionarios.fecha_muerte) }}</div>  
        </div>
        <div class="text text-left pa-4" v-if="dataPaciente.datos_cuestionarios.causa_exclusion == 1">
          <div class="font-weight-bold pe-3 d-inline">Causa de muerte: </div>
          <div class="d-inline">{{ causaExclusionMuerte}}</div>            
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog datos de paciente -->
    <v-dialog
      v-model="dialogOtrosDatos"
      v-if="dataPaciente"
      persistent
      scrollable
      max-width="800"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Datos del paciente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cerrarDialogOtrosDatos">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-1 px-md-6">
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">Pid:</div>
                <div class="d-inline">{{ dataPaciente.pid }}</div>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">Género:</div>
                <div class="d-inline">{{ dataPaciente.genero }}</div>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">
                  Fecha de nacimiento:
                </div>
                <div class="d-inline">
                  {{ fechaFormateada(dataPaciente.fecha_nacimiento) }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">
                  Departamento donde vive:
                </div>
                <div class="d-inline">{{ dataPaciente.departamento }}</div>
              </v-col>
              <v-col cols="12" class="pb-0"><v-divider></v-divider></v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">
                  Fecha de inicio de tratamiento:
                </div>
                <div class="d-inline">
                  {{ fechaFormateada(dataPaciente.fecha_inicio) }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">
                  Edad basal (años):
                </div>
                <div class="d-inline">{{ dataPaciente.edad_basal }}</div>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">
                  Rama de tratamiento asignada:
                </div>
                <div class="d-inline">
                  {{ { 1: "A", 2: "B" }[dataPaciente.estudio_piloto] }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">Diagnóstico:</div>
                <div class="d-inline">{{ dataPaciente.diagnostico }}</div>
              </v-col>
              <v-col cols="12" class="pb-0"><v-divider></v-divider></v-col>
              <v-col
                v-if="dataPaciente.fallecido"
                cols="12"
                sm="6"
                class="pb-0"
              >
                <div class="font-weight-bold pe-3 d-inline">
                  Fecha de muerte:
                </div>
                <div class="d-inline">
                  {{ fechaFormateada(dataPaciente.fecha_muerte) }}
                </div>
              </v-col>
              <v-col
                v-if="dataPaciente.fallecido"
                cols="12"
                sm="6"
                class="pb-0"
              >
                <div class="font-weight-bold pe-3 d-inline">
                  Causa de muerte:
                </div>
                <div class="d-inline">{{ causaMuerte }}</div>
              </v-col>
              <v-col v-if="dataPaciente.salida" cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">
                  Fecha de salida del programa:
                </div>
                <div class="d-inline">
                  {{ fechaFormateada(dataPaciente.fecha_salida) }}
                </div>
              </v-col>
              <v-col v-if="dataPaciente.salida" cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold pe-3 d-inline">
                  Causa de salida:
                </div>
                <div class="d-inline">{{ causaSalida }}</div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* import TablaComponent from "@/components/Tabla.vue"; */
import TablaPaginaComponent from "@/components/TablaPagina.vue";
import FormPacienteComponent from "@/components/FormPaciente.vue";

import headers from "@/utils/headers";
import { formatearFecha } from "@/utils/formats";
import {
  getListPacientes,
  eliminarPacienteId,
} from "@/services/PacientesService";
import {
  getEncuestasAtrasadas,
  getCorreccionesPendientes,
  getReporte,
} from "@/services/reportesService";

export default {
  name: "PacientesView",
  props: {
    usuario: {
      type: Object,
      required: true,
    },
    setSnackbar: {
      type: Function,
      required: true,
    },
    manejarErrorAxios: {
      type: Function,
      required: true,
    },
    setItemSeleccionado: {
      type: Function,
      required: true,
    },
  },
  components: {
    /* TablaComponent, */
    TablaPaginaComponent,
    FormPacienteComponent,
  },
  data() {
    return {
      listadoPacientes: [],
      estaCargando: false,
      mostrarFormPaciente: false,
      dataPaciente: null,
      dialogEliminar: false,
      reportes: [
        { name: "DL_MAIN", url: "main" },
        { name: "DL_EQ5D", url: "eq5d" },
        { name: "DL_MEDIC", url: "medic" },
        { name: "DL_MEDICW", url: "medicw" },
        { name: "DL_DOSIS", url: "dosis" },
        { name: "DL_BPI", url: "bpi" },
        { name: "DL_NRSW", url: "nrsw" },
        { name: "DL_HADS", url: "hads" },
        { name: "DL_SLEEP", url: "sleep" },
        { name: "DL_EA", url: "ea" },
      ],
      dialogOtrosDatos: false,
      dialogExclusion: false,
      paginaEncuestas: 1,
    };
  },
  computed: {
    headers: function () {
      if (this.usuario) {
        switch (this.usuario["rol"]) {
          case "ADM":
          case "MED":
            return headers.pacientesAdmMed;
          case "EST":
            return headers.pacientesEst;
          default:
            return [];
        }
      }
      return [];
    },
    rol: function () {
      if (this.usuario) return this.usuario["rol"];
      return "";
    },
    sintomaDiagnostico: function () {
      const sintomas = {
        99: "Otros sintomas",
        1: "Convulsiones",
        2: "Dolor Crónico",
        3: "Nauseas y/o vómitos por quimioterapia",
        4: "Transtornos del hábito evacuatorio (diarrea/constipación)",
        5: "Cólico abdominal",
        6: "Caquexia",
        7: "Espasmos musculares",
        8: "Rigidez muscular",
        9: "Insomnio",
      };
      if (this.dataPaciente) {
        return sintomas[this.dataPaciente["sintoma_diagnostico"]];
      }
      return undefined;
    },
    causaMuerte: function () {
      const causas = {
        0: "Muerte no relacionada al CM",
        1: "Muerte relacionada al CM",
      };
      if (this.dataPaciente && this.dataPaciente.fallecido) {
        return causas[this.dataPaciente["causa_muerte"]];
      }
      return undefined;
    },
    causaSalida: function () {
      const causas = {
        1: "Muerte",
        2: "Abandono (decisión del paciente)",
        3: "Decisión médica",
        4: "Evento Adverso",
        5: "Perdida de seguimiento",
        6: "Embarazo",
        98: "Desconocida",
        99: "Otra",
      };
      if (this.dataPaciente && this.dataPaciente.salida) {
        return causas[this.dataPaciente["causa_salida"]];
      }
      return undefined;
    },
    causaExclusion: function () {
      const causas = {
        1: "Muerte",
        2: "Abandono (decisión del paciente)",
        3: "Decisión médica",
        4: "Evento Adverso",
        5: "Perdida de seguimiento",
        6: "Embarazo",
        98: "Desconocida",
        99: "Otra",
      };
      if (this.dataPaciente && this.dataPaciente.datos_cuestionarios.excluido) {
        return causas[this.dataPaciente.datos_cuestionarios.causa_exclusion];
      }
      return undefined;
    },
    causaExclusionMuerte: function () {
      const causas = {
        0: "Muerte no relacionada al CM",
        1: "Muerte relacionada al CM",
      };
      if (this.dataPaciente && this.dataPaciente.datos_cuestionarios.excluido && this.dataPaciente.datos_cuestionarios.causa_exclusion == 1) {
        return causas[this.dataPaciente.datos_cuestionarios.causa_muerte];
      }
      return undefined;
    },
  },
  methods: {
    /* onPaginaUpdate(pagina) {
      this.paginaEncuestas = pagina;
      console.log("Pagina actual padre: ", this.paginaEncuestas);
    }, */
    // registrarPaciente() {
    //   this.mostrarFormPaciente = true;
    // },
    editarPaciente(paciente) {
      this.dataPaciente = paciente;
      this.mostrarFormPaciente = true;
    },
    eliminarPaciente(paciente) {
      this.dataPaciente = paciente;
      this.dialogEliminar = true;
    },
    cancelarEliminacion() {
      this.dialogEliminar = false;
      this.dataPaciente = null;
    },
    confirmarEliminacion() {
      eliminarPacienteId(this.dataPaciente.id)
        .then(() => {
          this.setSnackbar({
            activo: true,
            color: "success",
            texto: "Paciente eliminado",
          });
          this.actualizarListaPacientes();
          this.dialogEliminar = false;
          this.dataPaciente = null;
        })
        .catch((e) => this.manejarErrorAxios(e));
    },
    fechaFormateada(fecha) {
      return formatearFecha(fecha);
    },
    obtenerListadoPacientes(limit = 15, offset = 0) {
      this.estaCargando = true;
      getListPacientes({ limit, offset })
        .then((dataPacientes) => {
          this.listadoPacientes = this.listadoPacientes.concat(
            dataPacientes.results
          );
          if (dataPacientes.next) {
            const nextUrl = new URL(dataPacientes.next);
            const params = nextUrl.searchParams;
            this.obtenerListadoPacientes(
              (limit = params.get("limit")),
              (offset = params.get("offset"))
            );
          } else {
            this.estaCargando = false;
          }
        })
        .catch((e) => this.manejarErrorAxios(e));
    },
    actualizarListaPacientes() {
      this.listadoPacientes = [];
      this.obtenerListadoPacientes();
    },
    solicitarReporte({ name, url }) {
      getReporte(url)
        .then((dataReporte) => this.crearLinkDescarga(dataReporte, name))
        .catch((e) => {
          this.manejarErrorAxios(e);
        });
    },
    solicitarEncuestasAtrasadas() {
      getEncuestasAtrasadas()
        .then((dataReporte) =>
          this.crearLinkDescarga(dataReporte, "Encuestas_atrasadas")
        )
        .catch((e) => this.manejarErrorAxios(e));
    },
    solicitarCorreccionesPendientes() {
      getCorreccionesPendientes()
        .then((dataReporte) =>
          this.crearLinkDescarga(dataReporte, "correcciones_pendientes")
        )
        .catch((e) => this.manejarErrorAxios(e));
    },
    crearLinkDescarga(data, nombreArchivo) {
      const fileURL = window.URL.createObjectURL(new Blob([data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${nombreArchivo}.csv`);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },
    mostrarMasDatos(paciente) {
      this.dataPaciente = paciente;
      this.dialogOtrosDatos = true;
    },
    cerrarDialogOtrosDatos() {
      this.dialogOtrosDatos = false;
      this.dataPaciente = null;
    },
    verEncuestas(paciente) {
      // guardar pagina actual en localStorage
      /* localStorage.setItem("paginaEncuestas", this.paginaEncuestas);
      console.log("Pagina actual PacientesView: ", this.paginaEncuestas); */
      this.$router.push({
        name: "encuestasPaciente",
        params: { pidPaciente: paciente.pid },
      });
    },
    registrarPaciente() {
      this.$router.push({
        name: "registroPaciente",
      });
    },
    seguimientoPaciente() {
      this.$router.push({
        name: "seguimientoPaciente",
      });
    },
    mostrarDialogExclusion(paciente) {
      this.dataPaciente = paciente;
      this.dialogExclusion = true;
    },
    cerrarDialogExclusion() {
      this.dialogExclusion = false;
    },
  },
  created() {
    this.obtenerListadoPacientes();
    this.setItemSeleccionado("pacientes");

    // obtener pagina actual de localStorage
    const paginaEncuestas = localStorage.getItem("paginaEncuestas");
    if (paginaEncuestas) {
      this.paginaEncuestas = parseInt(paginaEncuestas);
    }

    

  },

};
</script>

<style scoped>
.card-table {
  backdrop-filter: blur(16px) saturate(100%);
  -webkit-backdrop-filter: blur(16px) saturate(100%);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  padding: 0 10px;
  padding-bottom: 10px;
}
</style>
