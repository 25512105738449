import axios from "./axios";

/* const samplePlanAnalgesico = {
  "plan_analgesico": {
    "opioides_fuertes": true,
    "opioides_debiles": false,
    "antiinflamatorios": true,
    "paracetamol": false,
    "anticonvulsivantes": true,
    "antiarritmicos": false,
    "antagonistas_nmetildaspartato": true,
    "corticoides": false,
    "otros_medicamentos": "aspirina",
    "otras_intervenciones": "",
    "reduccion_dosis": false
  }     
} */

/* const sampleIntervencionesMedicas = {
  "intervenciones_medicas":{
    "receta": true,
    "eventos_laboratorio": false,
    "eventos_graves": false,
    "causa_exclusion": 1,
    "causa_muerte": 0,
    "fecha_muerte": "2019-08-24"
  }
} */

/* const sampleIntervencionesMedicas = {
  "intervenciones_medicas":{
    "receta": true,
    "eventos_laboratorio": false,
    "eventos_graves": false,
    "causa_exclusion": null
  }
}
 */
/* const sampleDN4 = {
  "dn4":  { 
    "caracteristica_dolor": { 
      "quemazon": false, 
      "frio_doloroso": false, 
      "descargas_electricas": true 
    }, 
    "sintoma_zona_dolor": { 
      "hormigueo": true, 
      "pinchazos": false, 
      "entumecimiento": true, 
      "escozor": true 
    }, 
    "signo_zona_dolor": { 
      "hipotesia_tacto": true, 
      "hipotesia_pinchazo": true 
    }, 
    "motivo_dolor": { 
      "roce": true 
    } 
  }
}
 */
export const getEncuestasPaciente = (pid) => {
  return axios
    .get(`/pacientes/${pid}/encuestas/`)
    .then((response) => response.data /* { 
      let data = response.data;
      let sampleData = data.map((encuesta) => {
          return {...encuesta, ...samplePlanAnalgesico, ...sampleIntervencionesMedicas, ...sampleDN4}
        })
      return sampleData;
      } */
    );
};

export const getEncuestas = ({ limit = 15, offset = 0 }) => {
  return axios
    .get(`/encuestas/?limit=${limit}&offset=${offset}`)
    .then((response) => response.data);
};

export const createEncuesta = (data) => {
  return axios.post("/encuestas/", data).then((response) => response.data);
};

export const createEncuestaPublica = (data) => {
  return axios
    .post("/encuestas/public/", data)
    .then((response) => response.data);
};

export const getEncuestasAtrasadas = () => {
  return axios
    .get(`/pacientes/encuestas-atrasadas/`)
    .then((response) => response.data);
};