<template>
  <v-container fluid class="pa-0 pa-md-3">
    <v-container fluid class="pa-0 mb-2" v-if="pidPaciente">
      <v-btn class="mx-2" dark color="orange darken-4" @click="$router.back()">
        <v-icon dark> mdi-arrow-left </v-icon>
        Volver
      </v-btn>
    </v-container>
    <tabla-component
      :estaCargando="estaCargando"
      :headers="headers"
      :listaItems="listadoEncuestas"
      tituloTabla="Listado de encuestas"
      :accionBoton="iniciarEncuesta"
    >
      <!-- Slots cabecera -->
      <template v-slot:accion="{ accionBoton }" v-if="rol === 'ADM'">
        <v-btn color="primary" @click="accionBoton">Iniciar encuesta</v-btn>
      </template>

      <!-- Otros slots -->
      <template v-slot:[`item.created_at`]="{ item }">
        {{ fechaFormateada(item.created_at) }}
      </template>
      <template v-slot:[`item.completa`]="{ item }">
        <div class="text-center">
          <v-chip v-if="item.completa" class="success font-weight-bold"
            >SI</v-chip
          >
          <v-chip v-else class="error font-weight-bold">NO</v-chip>
        </div>
      </template>
      <template v-slot:[`item.fecha`]="{ item }">
        {{ item.fecha ? fechaFormateada(item.fecha) : "-" }}
      </template>
      <template v-slot:[`item.dosis`]="{ item }">
        {{ item.dosis || "-" }}
      </template>
      <template v-slot:[`item.presenta_ea`]="{ item }">
        {{
          item.reacciones_adversas
            ? item.reacciones_adversas.existen_sintomas
              ? "SI"
              : "NO"
            : "-"
        }}
      </template>
      <template v-slot:[`item.bpi`]="{ item }">
        <v-btn
          rounded
          depressed
          color="blue-grey"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item, 'bpi')"
          :disabled="!item.bpi"
        >
          BPI
        </v-btn>
      </template>
      <template v-slot:[`item.hads`]="{ item }">
        <v-btn
          rounded
          depressed
          color="blue-grey"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item, 'hads')"
          :disabled="!item.hads"
        >
          HADS
        </v-btn>
      </template>
      <template v-slot:[`item.eq5d`]="{ item }">
        <v-btn
          rounded
          depressed
          color="blue-grey"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item, 'eq5d')"
          :disabled="!item.eq5d"
        >
          EQ5D
        </v-btn>
      </template>
      <template v-slot:[`item.calidad_suenio`]="{ item }">
        <v-btn
          rounded
          depressed
          color="blue-grey"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item, 'calidad_suenio')"
          :disabled="!item.calidad_suenio"
        >
          Calidad del sueño
        </v-btn>
      </template>
      <template v-slot:[`item.reacciones_adversas`]="{ item }">
        <v-btn
          rounded
          depressed
          color="blue-grey"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item, 'reacciones_adversas')"
          :disabled="
            !item.reacciones_adversas ||
            !item.reacciones_adversas.existen_sintomas
          "
        >
          EA
        </v-btn>
      </template>

      <template v-slot:[`item.plan_analgesico`]="{ item }">
        <v-btn
          rounded
          depressed
          color="blue-grey"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item, 'plan_analgesico')"
          :disabled="!item.plan_analgesico"
        >
          Plan Analgésico
        </v-btn>
      </template>

      <template v-slot:[`item.intervenciones_medicas`]="{ item }">
        <v-btn
          rounded
          depressed
          color="blue-grey"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item, 'intervenciones_medicas')"
          :disabled="!item.intervenciones_medicas || item.visita == 1"
        >
          Intervenciones Médicas
        </v-btn>
      </template>

      <template v-slot:[`item.dn4`]="{ item }">
        <v-btn
          rounded
          depressed
          color="blue-grey"
          class="white--text mx-1"
          @click="mostrarDialogCuestionario(item, 'dn4')"
          :disabled="!item.dn4"
        >
          DN4
        </v-btn>
      </template>
      
      <template v-slot:[`item.acciones`]="{ item }">
        <v-btn
          rounded
          depressed
          color="green darken-2"
          class="white--text mx-1"
          @click="mostrarReapertura(item)"
          v-if="rol === 'EST'"
          :disabled="!item.completa"
        >
          Reabrir
        </v-btn>
        <v-btn
          rounded
          depressed
          color="orange darken-4"
          class="white--text mx-1"
          v-if="rol === 'CORR'"
          @click="verCorrecciones(item)"
        >
          <v-icon left dark> mdi-eye-check </v-icon>
          Correcciones
        </v-btn>
      </template>
    </tabla-component>
    <!-- Dialog cuestionarios -->
    <v-dialog v-model="dialogCuestionario" persistent scrollable>
      <v-card>
        <v-toolbar dark color="blue-grey">
          <v-toolbar-title>{{ tituloDialogCuestionario }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cerrarDialogCuestionario">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-1 px-md-6" v-if="encuestaSeleccionada">
          <FormularioBPI
            :data="encuestaSeleccionada.bpi"
            v-if="dialogSeleccionado === 'bpi'"
          />
          <FormularioEQ5D
            :data="encuestaSeleccionada.eq5d"
            v-if="dialogSeleccionado === 'eq5d'"
          />
          <FormularioCalidadSuenio
            :data="encuestaSeleccionada.calidad_suenio"
            v-if="dialogSeleccionado === 'calidad_suenio'"
          />
          <FormularioHADS
            :data="encuestaSeleccionada.hads"
            v-if="dialogSeleccionado === 'hads'"
          />
          <!-- <SubcuestionarioReacciones
            :datosReacciones="encuestaSeleccionada.reacciones_adversas"
            v-if="dialogSeleccionado === 'reacciones_adversas'"
          /> -->
          <FormularioEventosAdversosReadonly
            :data="encuestaSeleccionada.reacciones_adversas"
            v-if="dialogSeleccionado === 'reacciones_adversas'"
            />
          <FormularioPlanAnalgesico
            :data="encuestaSeleccionada.plan_analgesico"
            v-if="dialogSeleccionado === 'plan_analgesico'"
            :esSeguimiento="encuestaSeleccionada.visita > 1"
          />
          <FormularioSeguimientoIntervenciones
            :data="encuestaSeleccionada.intervenciones_medicas"
            v-if="dialogSeleccionado === 'intervenciones_medicas'"
          />
          <FormularioDN4
            :data="encuestaSeleccionada.dn4"
            v-if="dialogSeleccionado === 'dn4'"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo reapertura de encuesta -->
    <v-dialog
      v-model="dialogReapertura"
      persistent
      scrollable
      max-width="700"
      v-if="encuestaSeleccionada"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>Reapertura de encuesta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cierreReapertura">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-1 px-md-6 pb-0">
          <v-form ref="formReapertura">
            <v-select 
              label="Cuestionario a corregir"
              v-model="correccion.subcuestionario"
              :items="opcionesReapertura.filter( item => item.visitas.includes(encuestaSeleccionada.visita))"
              :item-text="item => item.subcuestionario"
              :item-value="item => item.model"
              :rules="rules.required"
            ></v-select>

            <v-select 
              label="Campo a corregir"
              v-model="correccion.campo"
              :items="camposCuestionario"
              :item-text="item => item.pregunta"
              :item-value="item => item.model"
              :rules="rules.required"
            ></v-select>

            <v-select 
              v-if="subcamposPregunta.length > 0"
              label="Subcampo a corregir"
              v-model="correccion.subcampo"
              :items="subcamposPregunta"
              :item-text="item => item.label"
              :item-value="item => item.model"
              :rules="rules.required"
            ></v-select>

            <v-textarea
              outlined
              name="comentario"
              label="Comentario *"
              v-model="correccion.comentario"
              :rules="rules.required.concat(rules.noBlanco)"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-1 px-md-6">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="submitReapertura">
            Guardar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TablaComponent from "@/components/Tabla.vue";
import FormularioBPI from "@/components/FormularioBPI.vue";
import FormularioHADS from "@/components/FormularioHADS.vue";
import FormularioEQ5D from "@/components/FormularioEQ5D.vue";
import FormularioCalidadSuenio from "@/components/FormularioCalidadSuenio.vue";
//import SubcuestionarioReacciones from "@/components/SubcuestionarioReacciones.vue";
import FormularioEventosAdversosReadonly from "@/components/FormularioEventosAdversosReadonly.vue";
import FormularioPlanAnalgesico from "@/components/FormularioPlanAnalgesico.vue";
import FormularioSeguimientoIntervenciones from "@/components/FormularioSeguimientoIntervenciones.vue";
import FormularioDN4 from "@/components/FormularioDN4.vue";

import headers from "@/utils/headers";
import {opcionesReapertura} from "@/components/modelsCorrecciones";
import { formatearFecha } from "@/utils/formats";
import {
  getEncuestas,
  getEncuestasPaciente,
} from "@/services/EncuestasService";
import {
  crearCorreccion,
  getEncuestasCorrecciones,
} from "@/services/CorreccionesService";

export default {
  name: "EncuestasView",
  components: {
    TablaComponent,
    FormularioBPI,
    FormularioHADS,
    FormularioEQ5D,
    FormularioCalidadSuenio,
    //SubcuestionarioReacciones,
    FormularioEventosAdversosReadonly,
    FormularioPlanAnalgesico,
    FormularioSeguimientoIntervenciones,
    FormularioDN4
  },
  props: {
    usuario: {
      type: Object,
      required: true,
    },
    setSnackbar: {
      type: Function,
      required: true,
    },
    setLoaderLineal: {
      type: Function,
      required: true,
    },
    manejarErrorAxios: {
      type: Function,
      required: true,
    },
    pidPaciente: String,
    setItemSeleccionado: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      listadoEncuestas: [],
      listadoCorrecciones: [],
      estaCargando: true,
      paciente: null,
      encuestaSeleccionada: null,
      dialogSeleccionado: null,
      dialogCuestionario: false,
      dialogReapertura: false,
      datosReapertura: {},
      rules: {
        required: [(v) => !!v || "Campo obligatorio"],
        noBlanco: [
          (v) => (!!v && v.trim() !== "") || "No puede estar en blanco.",
        ],
      },
      correccion:{
        cuestionario: null,
        subcuestionario: null,
        campo: null,
        subcampo: null,
        comentario: null
      }
    };
  },
  watch:{
    "correccion.subcuestionario": function () {
      this.correccion.campo = null;
      this.correccion.subcampo = null;
      // no borrar comentario si ya se ha escrito
      //this.correccion.comentario = null;
    },
    "correccion.campo": function () {
      this.correccion.subcampo = null;
      // no borrar comentario si ya se ha escrito
      //this.correccion.comentario = null;
    }
  },
  computed: {
    opcionesReapertura: function () {
      return opcionesReapertura;
    },
    camposCuestionario: function () {
      if (this.correccion.subcuestionario) {
        return opcionesReapertura.filter( item => item.model === this.correccion.subcuestionario)[0].opciones;
      }
      return [];
    },
    subcamposPregunta: function () {
      if (this.correccion.campo) {
        const campoCompleto = this.camposCuestionario.filter( item => item.model === this.correccion.campo)[0];
        // check if options key is present in the object
        if ("options" in campoCompleto) {
          return campoCompleto.options;
        }
        return [];
      }
      return [];
      
    },
    headers: function () {
      if (this.usuario && ["EST", "CORR"].includes(this.usuario["rol"])) {
        let headersDef = [];
        switch (this.usuario["rol"]) {
          case "EST":
            headersDef = headers.encuestas;
            break;
          case "CORR":
            /* headersDef = headers.encuestasCorregir; */
            headersDef = headers.encuestas;
            break;
        }
        return [
          ...headersDef,
          {
            value: "acciones",
            align: "center",
            text: "",
            sortable: false,
          },
        ];
      }
      return headers.encuestas;
    },
    rol: function () {
      if (this.usuario) return this.usuario["rol"];
      return "";
    },
    tituloDialogCuestionario: function () {
      switch (this.dialogSeleccionado) {
        case "bpi":
          return "Cuestionario BPI";
        case "hads":
          return "Cuestionario HADS";
        case "eq5d":
          return "Cuestionario EQ5D";
        case "calidad_suenio":
          return "Cuestionario de calidad del sueño";
        case "reacciones_adversas":
          return "Cuestionario de eventos adversos";
        case "plan_analgesico":
          return "Plan analgésico";
        case "intervenciones_medicas":
          return "Interveciones médicas";
        case "dn4":
          return "DN4";
        default:
          break;
      }
      return "";
    },
  },
  methods: {
    fechaFormateada(fecha) {
      return formatearFecha(fecha);
    },
    manejarRequestEncuestas(request, funcionLlamada) {
      this.estaCargando = true;
      request
        .then((datosEncuestas) => {
          this.listadoEncuestas = this.listadoEncuestas.concat(
            datosEncuestas.results
          );
          if (datosEncuestas.next) {
            const nextUrl = new URL(datosEncuestas.next);
            const params = nextUrl.searchParams;
            const limit = params.get("limit");
            const offset = params.get("offset");
            funcionLlamada(limit, offset);
          } else {
            this.estaCargando = false;
          }
        })
        .catch((e) => this.manejarErrorAxios(e));
    },
    obtenerListadoEncuestas(limit = 15, offset = 0) {
      this.manejarRequestEncuestas(
        getEncuestas({ limit, offset }),
        this.obtenerListadoEncuestas
      );
    },
    obtenerEncuestasCorrecciones(limit = 15, offset = 0) {
      this.manejarRequestEncuestas(
        getEncuestasCorrecciones({ limit, offset }),
        this.obtenerEncuestasCorrecciones
      );
    },
    solicitarEncuestasPaciente() {
      this.estaCargando = true;
      getEncuestasPaciente(this.pidPaciente)
        .then((response) => {
          console.log(response);
          this.listadoEncuestas = response;
          this.estaCargando = false;
        })
        .catch((e) => this.manejarErrorAxios(e));
    },
    cerrarDialogCuestionario() {
      this.encuestaSeleccionada = null;
      this.dialogCuestionario = false;
      this.dialogSeleccionado = null;
    },
    mostrarDialogCuestionario(encuesta, nombreDialog) {
      this.dialogSeleccionado = nombreDialog;
      this.encuestaSeleccionada = encuesta;
      this.dialogCuestionario = true;
    },
    iniciarEncuesta() {
      this.$router.push({ name: "inicioEncuesta" });
    },
    mostrarReapertura(encuesta) {
      this.encuestaSeleccionada = encuesta;
      this.dialogReapertura = true;
    },
    verCorrecciones(encuesta) {
      this.$router.push({
        name: "correccionesEncuesta",
        params: { idEncuesta: encuesta.id.toString() },
      });
    },
    submitReapertura() {
      if (this.$refs["formReapertura"].validate()) {
        this.setLoaderLineal(true);
        this.correccion.cuestionario = this.encuestaSeleccionada.id;
        console.log("Body correccion =", this.correccion)
        /* this.setSnackbar({
              activo: true,
              color: "success",
              texto: "Se registro la solicitud de correción.",
            });
        this.cierreReapertura();
        this.setLoaderLineal(false); */
        crearCorreccion(this.correccion, this.encuestaSeleccionada.id)
          .then(() => {
            this.setSnackbar({
              activo: true,
              color: "success",
              texto: "Se registro la solicitud de correción.",
            });
            this.cierreReapertura();
          })
          .catch((e) => this.manejarErrorAxios(e))
          .finally(() => this.setLoaderLineal(false));
      }
    },
    cierreReapertura() {
      this.dialogReapertura = false;
      this.encuestaSeleccionada = null;
      this.correccion = {
        cuestionario: null,
        subcuestionario: null,
        campo: null,
        subcampo: null,
        comentario: null
      };
    },
  },
  created() {
    if (this.usuario["rol"] === "CORR") {
      this.obtenerEncuestasCorrecciones();
      this.setItemSeleccionado("encuestas");
    } else if (this.pidPaciente) {
      this.solicitarEncuestasPaciente();
      this.setItemSeleccionado("pacientes");
    } else {
      this.obtenerListadoEncuestas();
      this.setItemSeleccionado("encuestas");
    }
  },
};
</script>

<style scoped>
.option-selected {
  border: 2px solid green;
}
</style>
