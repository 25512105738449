<template>
  <v-container fluid>
    <v-text-field
      v-model="fecha"
      :rules="rules.required.concat(rules.fecha)"
      type="date"
      :max="fechaActual"
      min="2000-01-01"
      outlined
      dense
      hide-details="auto"
      label="Fecha en que se completó *"
      autofocus
    ></v-text-field>
    <v-card outlined class="mt-5">
      <v-card-title>Medicación concomitante</v-card-title>
      <v-card-text>
        Nombre uno por por uno, todos los medicamentos que consume de forma
        habitual, con o sin prescripción médica, desde la última semana.

        <div class="d-flex flex-column flex-sm-row align-end align-sm-center mt-3">
          <div style="width: 100%">
            <v-text-field
              label="Nombre de medicamento"
              outlined
              clearable
              hide-details="auto"
              dense
              v-model="medicamento"
              @keyup.enter="agregarMedicamento"
            ></v-text-field>
          </div>
          <v-btn class="ml-0 ml-sm-3 mt-2 mt-sm-0" color="primary" @click="agregarMedicamento"> Agregar </v-btn>
        </div>

        <div class="subtitle-1 secondary--text mt-3">Listado de medicamentos:</div>
        <v-simple-table class="table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nombre medicamento</th>
                <th class="text-left">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in medicacion_concomitante"
                :key="index"
              >
                <td>{{ item }}</td>
                <td class="ps-7">
                  <v-icon small @click="borrarMedicamento(index)" color="red">
                    mdi-close-thick
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="mt-2 subtitle-2">
          Total de medicamentos:
          <strong>{{ medicacion_concomitante.length }}</strong>
        </p>
      </v-card-text>
    </v-card>    
  </v-container>
</template>

<script>
import moment from 'moment';
import options from "./options";

export default {
  name: "SubcuestionarioGeneral",
  props: {
    fechaEncuesta: {
      type: String,
      required: true,
    },
    medicacionConcomitEncuesta: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        fecha: [v => moment(v) <= moment() || "La fecha no puede superar la fecha actual."]
      },
      opciones: options,
      medicamento: "",
      fechaActual: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    fecha: {
      get() {
        return this.fechaEncuesta;
      },
      set(nuevoValor) {
        this.$emit("update:fechaEncuesta", nuevoValor);
      },
    },
    medicacion_concomitante() {
      return this.medicacionConcomitEncuesta;
    }
  },
  methods: {
    agregarMedicamento() {
      if (this.medicamento.trim()) {
        this.$emit("update:medicacionConcomitEncuesta", [
          ...this.medicacionConcomitEncuesta,
          this.medicamento.trim(),
        ]);
      }
      this.medicamento = "";
    },
    borrarMedicamento (index) {
      this.medicacion_concomitante.splice(index, 1);
      this.$emit("update:medicacionConcomitEncuesta", this.medicacion_concomitante);
    },
    validarFechaEncuesta(v) {
      const fecha = moment(v)
      if (fecha < moment('2020-01-01')) return 'La fecha no puede ser inferior al 01/01/2020'
      if (fecha > moment()) return 'La fecha no puede ser posterior a la fecha actual'

      return true
    },
  },
};
</script>