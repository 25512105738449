<template>
  <div class="mx-3 mt-1 mb-5">
    <v-checkbox
      v-for="opcion in opciones"
      :key="opcion.model"
      v-model="formData[opcion.model]"
      :label="opcion.label"
      :rules="[rules.required]"
      class="mt-0"
    ></v-checkbox>
  </div>
</template>

<script>
const initialData = {
  firma: false,
  receta: false,
  prescripcion: false,
};

export default {
  name: "FormularioIntervenciones",
  props: {
    data: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },
  data() {
    return {
      rules: {
        required: (v) => !!v || "Campo obligatorio",
      },
      opciones: [
        { label: "Firma del consentimiento ", model: "firma" },
        { label: "Receta de cannabis medicinal", model: "receta" },
        {
          label: "Prescripción de la titulación de cannabis medicinal",
          model: "prescripcion",
        },
      ],
    };
  },
  computed: {
    formData: {
      get() {
        return this.data || initialData;
      },
      set(value) {
        this.$emit("update:data", value);
      },
    },
  },
};
</script>