<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" class="pb-0">
        <div class="font-weigth-medium">
          1- Número de crisis epilépticas durante las últimas 2 semanas
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0 pt-sm-3 pb-0">
        <v-text-field
          placeholder="Ingrese un número"
          id="cantEpilepcia"
          outlined
          clearable
          dense
          v-model="datos.crisis_epilepticas"
          type="number"
          min="0"
          :rules="rules.required.concat(rules.mayorCero)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <p class="mb-0">
          2- Cuestionario de calidad de vida en epilepsia (QOLIE-10) de adultos
          (para pacientes de 16 años o más)
        </p>
        <small>(Seleccione una opción para cada valoración)</small>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="pb-0">
        <p>
          <b>¿Cuántas veces durante las últimas 4 semanas...</b>
        </p>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="py-0">
        ¿Se sintió lleno de vitalidad?
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0">
        <v-select
          :items="opciones.vitalidad"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.vitalidad"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0">
        ¿Se sintió deanimado y triste?
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0">
        <v-select
          :items="opciones.desanimo"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.desanimado"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
        ¿Le ha causado su epilepsia o su medicación antiepileptica problemas
        para desplazarse?
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
        <v-select
          :items="opciones.varios"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.problemas_desplaz"
          :rules="rules.required"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="pb-0">
        <p>
          <b
            >Durante las últimas 4 semanas, cuantas veces ha tenido problemas
            con:</b
          >
        </p>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="py-0">
        ¿Dificultades de memória?
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0">
        <v-select
          :items="opciones.varios"
          item-text="text"
          value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.dificultad_memoria"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
        ¿Limitaciones en el trabajo?
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
        <v-select
          :items="opciones.varios"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.limitac_trabajo"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
        ¿Limitaciones en su vida social?
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
        <v-select
          :items="opciones.varios"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.limitac_vida_social"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
        ¿Efectos físicos de la medicación antiepiléptica?
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
        <v-select
          :items="opciones.varios"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.efectos_fisicos"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
        ¿Efectos mentales de la medicación antiepiléptica?
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
        <v-select
          :items="opciones.varios"
          item-text="text"
          item-value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.efectos_mentales"
          :rules="rules.required"
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
        ¿Le da miedo sufrir un ataque en las próximas 4 semanas?
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
        <v-select
          :items="opciones.miedo"
          item-text="text"
          value="value"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.miedo_ataque"
          :rules="rules.required"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pb-0 pb-sm-3">
        <b>
          ¿Que tal ha sido su calidad de vida durante las últimas 4 semanas? (es
          decir ¿cómo le han ido las cosas?)
        </b>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="pt-0 pt-sm-3">
        <v-select
          :items="opciones.calidad"
          item-text="text"
          outlined
          clearable
          hide-details="auto"
          dense
          v-model="datos.calidad_vida"
          :rules="rules.required"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import options from "./options";

export default {
  name: "SubcuestionarioQolie",
  props: {
    datosQolie: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        mayorCero: [v => v >= 0 || 'Debe ser mayor o igual a cero']
      },
      opciones: options,
    };
  },
  computed: {
    datos: {
      get() {
        return this.datosQolie;
      },
      set(nuevoValor) {
        this.$emit("update:datosQolie", nuevoValor);
      },
    },
  },
};
</script>